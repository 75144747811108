<section id="claim"
         class="claim-area uk-claim uk-section uk-section-area">
    <div class="uk-container">
        <div class="uk-section-title section-title uk-align-center">
            <span>{{subTitle}}</span>
            <h2>{{title}}</h2>
        </div>
    </div>
</section>

<section id="claim"
         class="claim-area uk-claim uk-section2">
    <div class="uk-container">
        <h4>{{description}}</h4>
        <br>
        <div class="uk-child-width-1-1@s"
             uk-grid>
            <div>
                <div uk-grid>
                    <div class="uk-width-auto@m">
                        <ul class="uk-tab-left"
                            uk-tab="connect: #component-tab-left; animation: uk-animation-fade">

                            <li *ngFor="let item of data; let index = index">
                                <a href="#"
                                   class="uk-button uk-button-default">{{item.name}}
                                    <i class="flaticon-right"></i></a>
                            </li>

                        </ul>
                    </div>
                    <div class="uk-width-expand@m">

                        <ul id="component-tab-left"
                            class="uk-switcher component-tab-left">
                            <li *ngFor="let item of data; let index = index">
                                <h3>{{item.name}}</h3>
                                <div [innerHTML]="item.description">{{item.description}}</div>
                            </li>

                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
