<h4>{{sectionTitle}}</h4>
<div [innerHTML]="sectionDescription">
  {{sectionDescription}}
</div>

<div class="our-work-benefits">
  <ul class="accordion"
      uk-accordion>

    <li class="accordion-item {{item.show}}"
        *ngFor="let item of items; let index = index">
      <a class="accordion-title uk-accordion-title"
         href="javascript:void(0)">
        <i class="flaticon-plus"></i>
        {{item.name}}
      </a>

      <div class="accordion-content uk-accordion-content show"
           [innerHTML]="item.description">
        {{item.description}}

      </div>
    </li>
  </ul>
</div>
