import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { StyleService } from "../../services/style.service";
import { ClrTimelineLayout } from "@clr/angular/timeline";

declare const require: any;

@Component({
  selector: 'app-status-information',
  templateUrl: './status-information.component.html',
  styleUrls: ['./status-information.component.scss'],
})

export class StatusInformationComponent implements OnInit {

  orientation: any;

  items: any = [];

  constructor(
    public dialog: MatDialogRef<StatusInformationComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private styleService: StyleService
  ) { }

  close(): void {
    this.dialog.close(false);
  }

  ngOnInit() {
    this.orientation = "vertical";

    this.items.push({
      active: true,
      state: 'not-started',
      header: '11:59 am',
      title: 'Example 1',
      description: 'Test 1',
      command() {
        console.log('Action 1');
      }
    });

    this.items.push({
      active: true,
      state: 'not-started',
      header: '11:59 am',
      title: 'Example 1',
      description: 'Test 1',
      command() {
        console.log('Action 1');
      }
    });

    this.items.push({
      active: true,
      state: 'not-started',
      header: '11:59 am',
      title: 'Example 1',
      description: 'Test 1',
      command() {
        console.log('Action 1');
      }
    });

    this.styleService.addStyle("status-information", require("./status-information.component.scss"));

  }
  ngOnDestroy() {
    this.styleService.removeStyle("status-information");
  }
}