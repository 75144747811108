import { Component, OnInit } from '@angular/core';
import { StyleService } from '../../../services/style.service';
import { ConfigurationInfoService } from '../../../services/common/configuration.service';

@Component({
  selector: 'app-privacy-policies',
  templateUrl: './privacy-policies.component.html',
  styleUrls: ['./privacy-policies.component.scss']
})
export class PrivacyPoliciesComponent implements OnInit {

  title: string = '';
  subTitle: string = '';
  description: string = '';
  sectionSubTitle: string = '';

  sectionTitle: string = '';
  sectionDescription: string = '';

  constructor(private styleService: StyleService,
    private configurationInfoService: ConfigurationInfoService
  ) { }

  ngOnInit(): void {
    this.getData();
  }

  filterPage(type: number, array: any[]) {
    return array.filter((arr) => arr.id == type);
  }

  getData() {

    this.configurationInfoService.GetPagesContent().subscribe((response: any) => {

      if (response.ok) {
        const data = this.filterPage(
          Number(10),
          response.data.pages
        );

        this.title = data[0].title;

        this.subTitle = data[0].subTitle;

        this.sectionTitle = data[0].sections[0].title;

        this.sectionSubTitle = data[0].sections[0].subTitle;

        this.sectionDescription = data[0].sections[0].description;
      }

    });
  }
}
