<section id="claim"
         class="claim-area uk-claim uk-section uk-section-area">
    <div class="uk-container">
        <div class="uk-section-title section-title uk-align-center">
            <span>{{subTitle}}</span>
            <h2>{{title}}</h2>
        </div>
    </div>
</section>

<section class="services-details-area uk-services-details uk-section">
    <div class="uk-container">


        <article class="uk-article services-details">
            <div class="uk-grid uk-flex">
                <div class="inner uk-width-expand">
                    <div class="services-details-desc">
                        <section-content></section-content>
                    </div>
                </div>
                <aside-left class="uk-sidebar uk-width-1-5 uk-flex-first@l uk-first-column">
                </aside-left>

            </div>
        </article>
    </div>
</section>
