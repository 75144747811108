<div>
  <div class="widget service_list">
    <ul>
      <li *ngFor="let item of menulist; let index = index">
        <a href="/reclamos-frecuentes?categoria={{item.urlFriendly}}"
           class="{{item.active}}">{{item.title}}<i class="flaticon-right"></i></a>
      </li>
    </ul>
  </div>
</div>
