import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { AppRoutingModule } from "./app-routing.module";
import { HttpClientModule } from "@angular/common/http";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
/** FORM */
import { MatButtonModule } from "@angular/material/button";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatSnackBarModule } from "@angular/material/snack-bar";

import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatRadioModule } from "@angular/material/radio";
import { MatSelectModule } from "@angular/material/select";
import { MatTableModule } from "@angular/material/table";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MAT_DATE_FORMATS, MatRippleModule } from "@angular/material/core";
import { MatDividerModule } from "@angular/material/divider";
import { MatMenuModule } from "@angular/material/menu";
import { MatExpansionModule } from "@angular/material/expansion";

import { SharedModule } from "./shared/shared.module";
import { SpinnerModule } from "@uiowa/spinner";
import { NgHeroiconsModule } from "@dimaslz/ng-heroicons";
import { MatStepperModule } from "@angular/material/stepper";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { RxReactiveFormsModule } from "@rxweb/reactive-form-validators";
import { MatDialogModule } from "@angular/material/dialog";
import { ClarityModule } from "@clr/angular";

// import { annotation, annotationSolid, HeroIconsModule } from 'ng-heroicons';

import { AppComponent } from "./app.component";
import { HeaderComponent } from "./components/layout/header/header.component";
import { FooterComponent } from "./components/layout/footer/footer.component";
import { HomeComponent } from "./components/pages/home/home.component";
import { AboutComponent } from "./components/pages/about/about.component";
import { TipsComponent } from "./components/pages/tips/tips.component";
import { SurveyComponent } from "./components/pages/survey/survey.component";
import { HelpComponent } from "./components/pages/help/help.component";
import { ContactComponent } from "./components/pages/contact/contact.component";
import { ClaimQuestionComponent } from "./components/pages/claim-question/claim-question.component";
import { ClaimsComponent } from "./components/pages/claims/claims.component";
import { ClaimStatusComponent } from "./components/pages/claim-status/claim-status.component";
import { QuerysComponent } from "./components/pages/querys/querys.component";
import { PartnersComponent } from "./components/pages/partners/partners.component";
import { TermsConditionsComponent } from "./components/pages/terms-conditions/terms-conditions.component";
import { PrivacyPoliciesComponent } from "./components/pages/privacy-policies/privacy-policies.component";
import { TopClaimTypesComponent } from "./components/pages/top-claim-types/top-claim-types.component";
import { AsideLeftComponent } from "./components/pages/top-claim-types/top-claim-sections/aside-left.component";
import { SectionContentComponent } from "./components/pages/top-claim-types/top-claim-content/section-content.component";
import { StatsComponent } from "./components/pages/stats/stats.component";
import { FaqComponent } from "./components/pages/faq/faq.component";

import { StatusInformationComponent } from "./shared/status/status-information.component";

import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from "ng-recaptcha";

import { InputPatternDirective } from "./directives/input-pattern.directive";
import { BlockCopyPasteDirective } from "./directives/block-copy-paste.directive";
import { FocusInvalidInputDirective } from "./directives/focus-invalid-input.directive";

import { StyleService } from "./services/style.service";
import { CacheService } from "./services/cache.service";
import { environment } from "../environments/environment";
import { ConfigurationInfoService } from "./services/common/configuration.service";
import { ClaimsService } from "./services/claims/claims.service";
import { QueryService } from "./services/querys/querys.service";

import { CarouselModule } from "ngx-owl-carousel-o";
import { FilterPipe } from "./filter.pipe";
import { TemplateFirstTipComponent } from "./components/pages/templates/tips/tmp-01.component";
import { InterestYouComponent } from "./components/pages/templates/tips/interest-you.component";
import { TemplateSecondTipComponent } from "./components/pages/templates/tips/tmp-02.component";
import { SurveyService } from "./services/survey/survey.service";
import { TipsService } from "./services/tips/tips.services";
import { WspFixedComponent } from "./components/layout/wsp-fixed/wsp-fixed.component";
import { DatePipe } from "@angular/common";
import { LineSeparatorComponent } from "./components/layout/line-separator/line-separator.component";

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    WspFixedComponent,
    AboutComponent,
    TipsComponent,
    SurveyComponent,
    TemplateFirstTipComponent,
    TemplateSecondTipComponent,
    HelpComponent,
    ClaimsComponent,
    ContactComponent,
    ClaimStatusComponent,
    QuerysComponent,
    InputPatternDirective,
    PartnersComponent,
    BlockCopyPasteDirective,
    ClaimQuestionComponent,
    FocusInvalidInputDirective,
    TermsConditionsComponent,
    TermsConditionsComponent,
    PrivacyPoliciesComponent,
    StatusInformationComponent,
    TopClaimTypesComponent,
    AsideLeftComponent,
    SectionContentComponent,
    StatsComponent,
    FaqComponent,
    FilterPipe,
    InterestYouComponent,
    LineSeparatorComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    RecaptchaV3Module,
    SpinnerModule,

    MatButtonModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatRadioModule,
    MatSelectModule,
    MatStepperModule,
    MatAutocompleteModule,
    MatDatepickerModule,
    MatProgressBarModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    SharedModule,
    BrowserAnimationsModule,
    MatRippleModule,
    MatTableModule,
    NgHeroiconsModule,
    RxReactiveFormsModule,
    MatDialogModule,
    ClarityModule,
    CarouselModule,
    MatExpansionModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    StyleService,
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useValue: environment.recaptcha.siteKey,
    },
    CacheService,
    ClaimsService,
    ConfigurationInfoService,
    QueryService,
    SurveyService,
    TipsService,
    DatePipe,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
