import { Component, OnInit, Output, EventEmitter } from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
  FormControl,
} from "@angular/forms";
import { tap } from "rxjs";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { MatDialog } from "@angular/material/dialog";
import { ReCaptchaV3Service } from "ng-recaptcha";
import { StyleService } from "../../../services/style.service";
import { CacheService } from "../../../services/cache.service";
import { environment } from "../../../../environments/environment";
import { ConfigurationInfoService } from "../../../services/common/configuration.service";
import { DialogInformationComponent } from "../../../shared/dialog/dialog-information.component";
import { IControlValidation } from "../../../interfaces/controlValidation.interface";

declare const require: any;

@Component({
  selector: "querys",
  templateUrl: "./querys.component.html",
  styleUrls: ["./querys.components.scss"],
})
export class QuerysComponent implements OnInit {
  @Output() closeModalEvent = new EventEmitter<boolean>();

  formGroup: UntypedFormGroup;
  submitted = false;
  success = false;
  error = false;

  verticalStepperForm: UntypedFormGroup;
  titleAlert: string = "Este campo es obligatorio";
  post: any = "";

  uploading = false;
  errorMsg: string = "";
  resultMsg: string = "";
  requerido = false;

  tipoPersona: any[];
  tipoDocumento: any[];
  // tipoProducto: any[];
  tipoConsulta: any[];
  entidadFinanciera: any[];
  currencyType: any[];

  isMarkedtipoDocumento = true;
  isMarkedNumeroDocumento = true;
  isMarkedRazonSocial = true;
  isMarkedName = true;

  showSuggestionsList: boolean = false;
  suggestionOpened: boolean = false;
  originalSuggestions: string[] = [
    "@gmail.com",
    "@hotmail.com",
    "@yahoo.com",
    "@yahoo.es",
    "@outlook.com",
  ];

  suggestions: string[] = [...this.originalSuggestions];

  controlConfig: {
    documentNumber: IControlValidation;
  } = {
    documentNumber: {
      minLength: "8",
      maxLength: "8",
      pattern: "onlyNumbers",
    },
  };

  public title: string = "";
  public subTitle: string = "";
  public description: string = "";

  constructor(
    private recaptchaV3Service: ReCaptchaV3Service,
    private formBuilder: UntypedFormBuilder,
    private readonly httpClient: HttpClient,
    private styleService: StyleService,
    private cacheService: CacheService,
    private configurationInfoService: ConfigurationInfoService,
    public dialogo: MatDialog
  ) {}

  onClose(event: any) {
    this.closeModalEvent.emit(false);
  }

  ngOnInit(): void {
    this.createForm();

    setTimeout(() => {
      this.getData();
    }, 500);

    this.styleService.addStyle(
      "querys-form-wizard",
      require("./querys.component.scss")
    );
  }

  ngOnDestroy() {
    this.styleService.removeStyle("querys-form-wizard");
  }

  filterPage(type: number, array: any[]) {
    return array.filter((arr) => arr.id == type);
  }

  getData() {
    this.configurationInfoService
      .GetPagesContent()
      .subscribe((response: any) => {
        if (response.ok) {
          const data = this.filterPage(Number(5), response.data.pages);

          this.title = data[0].title;

          this.subTitle = data[0].subTitle;
        }
      });

    let cache = this.cacheService.loadConfigurationInfo();

    if (cache != null) {
      const objCache = JSON.parse(cache);

      this.tipoPersona = objCache.data.personTypes;

      if (objCache.ok && this.cacheService.loadQueryForm() != null) {
        this.setData(objCache, false);
      } else {
        this.setData(objCache, true);
      }
    } else {
      this.configurationInfoService
        .GetSettingsInfo()
        .subscribe((response: any) => {
          this.tipoPersona = response.data.personTypes;
          this.setData(response, true);
        });
    }
  }

  setData(objCache: any, isCache: boolean) {
    if (isCache) {
      this.cacheService.saveConfigurationInfo(objCache);
      this.tipoDocumento = this.filterByPersona(1, objCache.data.documentTypes);
      this.formGroup.controls["tipoDocumento"].setValue(
        this.tipoDocumento[0].id
      );
      this.formGroup.controls["numeroDocumento"].enable();
    } else {
      setTimeout(() => {
        this.getCacheForm(objCache);
      }, 1000);
    }

    // this.tipoProducto = objCache.data.productTypes;
    this.currencyType = objCache.data.currencyType;
    this.tipoConsulta = objCache.data.queryTypes;
  }

  createForm() {
    let MOBILE_PATTERN = /[0-9\+\-\ ]/;

    this.formGroup = this.formBuilder.group({
      tipoPersona: [null, Validators.required],
      tipoDocumento: [null, Validators.required],
      numeroDocumento: [
        null,
        Validators.compose([
          Validators.required,
          Validators.minLength(
            Number(this.controlConfig.documentNumber.minLength)
          ),
          Validators.minLength(
            Number(this.controlConfig.documentNumber.maxLength)
          ),
        ]),
      ],
      razonSocial: [""],
      nombres: ["", Validators.required],
      apellidoPaterno: ["", [Validators.required, Validators.minLength(3)]],
      apellidoMaterno: ["", Validators.required],
      celular: [
        "",
        [Validators.maxLength(9), Validators.pattern(MOBILE_PATTERN)],
      ],
      email: new FormControl("", [
        Validators.required,
        Validators.email,
        this.emailValidator,
      ]),
      // tipoProducto: [""],
      tipoConsulta: [""],
      consulta: [
        null,
        [
          Validators.required,
          Validators.minLength(10),
          Validators.maxLength(5000),
        ],
      ],
      aceptoTerminos: [false, [Validators.requiredTrue]],
      dniRepresentanteLegal: [""],
      nombreRepresentanteLegal: [""],
    });

    this.formGroup.controls["tipoPersona"].setValue(1);
    this.formGroup.controls["numeroDocumento"].disable();

    this.formGroup.valueChanges
      .pipe(tap((formValue) => this.cacheService.saveQueryForm(formValue)))
      .subscribe();
  }

  emailValidator(control: FormControl): { [s: string]: boolean } {
    if (
      !control.value ||
      control.value.indexOf("@") === -1 ||
      control.value.indexOf(".") === -1
    ) {
      return { invalidEmail: true };
    }
    return null;
  }

  showSuggestions(inputValue: string): void {
    if (inputValue.includes("@")) {
      this.showSuggestionsList = true;
    } else {
      this.showSuggestionsList = false;
    }
  }

  openSuggestions(event: any): void {
    const emailValue: string = event.target.value;
    if (emailValue.includes("@")) {
      const atIndex: number = emailValue.indexOf("@");
      const domainStartIndex: number = atIndex + 1;
      const domain: string = emailValue.slice(domainStartIndex).toLowerCase();

      const filteredSuggestions = this.originalSuggestions.filter(
        (suggestion) => suggestion.includes(domain)
      );
      this.suggestions =
        filteredSuggestions.length > 0
          ? filteredSuggestions
          : this.originalSuggestions;

      this.showSuggestionsList = this.suggestions.length > 0;
      this.suggestionOpened = true;
    }
  }

  closeSuggestions(): void {
    setTimeout(() => {
      if (this.showSuggestionsList && !this.suggestionOpened) {
        this.showSuggestionsList = false;
      }
      this.suggestionOpened = false;
    }, 200);
  }

  selectSuggestion(suggestion: string): void {
    const currentValue = this.formGroup.controls["email"].value;
    const [prefix, _] = currentValue.split("@");
    const newValue = prefix + suggestion;
    this.formGroup.controls["email"].setValue(newValue);
    this.showSuggestionsList = false;
  }

  onKeyUp(event: KeyboardEvent): void {
    if (
      (event.key === "Backspace" || event.key === "Delete") &&
      this.formGroup.controls["email"].value === ""
    ) {
      this.onEmailChange();
    }
    console.log(event.key);
  }

  onEmailChange(): void {
    const emailValue: string = this.formGroup.controls["email"].value;
    if (emailValue === "") {
      this.suggestions = [];
      this.showSuggestionsList = false;
    }
    this.suggestionOpened = false;
  }

  getCacheForm(dataCache: any) {
    let formValue = JSON.parse(this.cacheService.loadQueryForm());

    if (formValue != null) {
      this.formGroup.controls["tipoPersona"].setValue(formValue.tipoPersona);
      this.tipoDocumento = this.filterByPersona(
        formValue.tipoPersona,
        dataCache.data.documentTypes
      );

      this.formGroup.controls["tipoDocumento"].setValue(
        formValue.tipoDocumento
      );
      this.formGroup.controls["numeroDocumento"].setValue(
        formValue.numeroDocumento
      );
      this.formGroup.controls["numeroDocumento"].enable();

      this.formGroup.controls["nombres"].setValue(formValue.nombres);
      this.formGroup.controls["apellidoMaterno"].setValue(
        formValue.apellidoMaterno
      );
      this.formGroup.controls["apellidoPaterno"].setValue(
        formValue.apellidoPaterno
      );
      this.formGroup.controls["razonSocial"].setValue(formValue.razonSocial);
      this.formGroup.controls["celular"].setValue(formValue.celular);
      this.formGroup.controls["email"].setValue(formValue.email);
      this.formGroup.controls["consulta"].setValue(formValue.consulta);
      // this.formGroup.controls["tipoProducto"].setValue(formValue.tipoProducto);
      this.formGroup.controls["tipoConsulta"].setValue(formValue.tipoConsulta);

      this.formGroup.controls["dniRepresentanteLegal"].setValue(
        formValue.dniRepresentanteLegal
      );
      this.formGroup.controls["nombreRepresentanteLegal"].setValue(
        formValue.nombreRepresentanteLegal
      );
      this.formGroup.controls["aceptoTerminos"].setValue(
        formValue.aceptoTerminos
      );

      this.changeDocumentType(formValue);
    }
  }

  resetForm() {
    this.formGroup.controls["numeroDocumento"].setValue("");
    this.formGroup.controls["numeroDocumento"].enable();

    this.formGroup.controls["nombres"].setValue("");
    this.formGroup.controls["apellidoMaterno"].setValue("");
    this.formGroup.controls["apellidoPaterno"].setValue("");
    this.formGroup.controls["razonSocial"].setValue("");
    this.formGroup.controls["celular"].setValue("");
    this.formGroup.controls["email"].setValue("");
    this.formGroup.controls["consulta"].setValue("");
    // this.formGroup.controls["tipoProducto"].setValue("");
    this.formGroup.controls["tipoConsulta"].setValue("");
    this.formGroup.controls["aceptoTerminos"].setValue(false);

    this.formGroup.controls["numeroDocumento"].setErrors(null);
    this.formGroup.controls["numeroDocumento"].setErrors(null);
    this.formGroup.controls["nombres"].setErrors(null);
    this.formGroup.controls["apellidoMaterno"].setErrors(null);
    this.formGroup.controls["apellidoPaterno"].setErrors(null);
    this.formGroup.controls["razonSocial"].setErrors(null);
    this.formGroup.controls["celular"].setErrors(null);
    this.formGroup.controls["email"].setErrors(null);
    this.formGroup.controls["consulta"].setErrors(null);
    // this.formGroup.controls["tipoProducto"].setErrors(null);
    this.formGroup.controls["tipoConsulta"].setErrors(null);
    this.formGroup.controls["aceptoTerminos"].setErrors(null);
  }

  keyUp(input: string, event: any) {
    switch (input) {
      case "names":
        event.target.value.length > 0
          ? (this.isMarkedName = false)
          : (this.isMarkedName = true);
        break;
      case "razonsocial":
        event.target.value.length > 0
          ? (this.isMarkedRazonSocial = false)
          : (this.isMarkedRazonSocial = true);
        break;
      case "numeroDocumento":
        event.target.value.length > 0
          ? (this.isMarkedNumeroDocumento = false)
          : (this.isMarkedNumeroDocumento = true);
        break;
    }
  }

  filterByPersona(type: number, array: any[]) {
    return type == 1
      ? array.filter((arr) => arr.name !== "RUC")
      : array.filter((arr) => arr.name === "RUC");
  }

  filterByMoneyType(type: number, array: any[]) {
    return array.filter((arr) => arr.id !== type);
  }

  changeMoneyType(event: any) {
    let configurationInfo = JSON.parse(
      this.cacheService.loadConfigurationInfo()
    );

    this.tipoDocumento = this.filterByMoneyType(
      Number(this.formGroup.get("currencyType").value),
      configurationInfo.data.currencyType
    );
  }

  changePersonType(event: any, active: boolean) {
    let documentType: number;

    if (active) {
      let configurationInfo = JSON.parse(
        this.cacheService.loadConfigurationInfo()
      );

      this.tipoDocumento = this.filterByPersona(
        event.value,
        configurationInfo.data.documentTypes
      );

      this.formGroup.controls["tipoDocumento"].setValue(
        this.tipoDocumento[0].id
      );
    }

    let value = event.value;

    if (value === 0) {
      this.isMarkedtipoDocumento = true;
    } else {
      this.isMarkedtipoDocumento = false;

      //this.formGroup.get('numeroDocumento').setValue('');

      this.formGroup.controls["numeroDocumento"].enable();

      if (value == 1) {
        //tipoPersona

        // this.formGroup.get('nombres').reset();
        this.formGroup.get("nombres").setValidators([Validators.required]);
        this.formGroup.get("nombres").updateValueAndValidity();

        // this.formGroup.get('apellidoPaterno').reset();
        this.formGroup
          .get("apellidoPaterno")
          .setValidators([Validators.required]);
        this.formGroup.get("apellidoPaterno").updateValueAndValidity();

        // this.formGroup.get('apellidoMaterno').reset();
        this.formGroup
          .get("apellidoMaterno")
          .setValidators([Validators.required]);
        this.formGroup.get("apellidoMaterno").updateValueAndValidity();

        this.formGroup.get("razonSocial").clearValidators();
        this.formGroup.get("razonSocial").setErrors(null);
        this.formGroup.get("razonSocial").updateValueAndValidity();

        switch (this.formGroup.get("tipoDocumento").value) {
          case 2:
            this.controlConfig.documentNumber.minLength = "8";
            this.controlConfig.documentNumber.maxLength = "8"; // DNI
            this.controlConfig.documentNumber.pattern = "onlyNumbers";
            break;
          case 3: //Carnet de extranjeria
          case 4: //Pasaporte
          case 5: //Cedula Diplomatica
            this.controlConfig.documentNumber.minLength = "1";
            this.controlConfig.documentNumber.maxLength = "15";
            this.controlConfig.documentNumber.pattern = "alphaNumeric";
            break;
          default:
            this.formGroup.controls["numeroDocumento"].disable();
            break;
        }
      } else {
        this.formGroup.get("nombres").clearValidators();
        this.formGroup.get("nombres").updateValueAndValidity();

        this.formGroup.get("apellidoMaterno").clearValidators();
        this.formGroup.get("apellidoMaterno").updateValueAndValidity();

        this.formGroup.get("apellidoPaterno").clearValidators();
        this.formGroup.get("apellidoPaterno").updateValueAndValidity();

        this.formGroup.get("razonSocial").reset();
        this.formGroup.get("razonSocial").setValidators([Validators.required]);
        this.formGroup.get("razonSocial").updateValueAndValidity();

        switch (this.formGroup.get("tipoDocumento").value) {
          case 1:
            this.controlConfig.documentNumber.minLength = "11";
            this.controlConfig.documentNumber.maxLength = "11";
            this.controlConfig.documentNumber.pattern = "onlyNumbers";
            break;
          default:
            this.formGroup.controls["numeroDocumento"].disable();
            break;
        }
      }

      this.formGroup
        .get("numeroDocumento")
        .setValidators([
          Validators.required,
          Validators.minLength(
            Number(this.controlConfig.documentNumber.minLength)
          ),
          Validators.maxLength(
            Number(this.controlConfig.documentNumber.maxLength)
          ),
          Validators.pattern("^.+$"),
        ]);

      this.formGroup.updateValueAndValidity();
    }
  }

  changeDocumentType(data: any) {
    if (data.tipoPersona == 1) {
      switch (data.tipoDocumento) {
        case 2:
          this.controlConfig.documentNumber.minLength = "8";
          this.controlConfig.documentNumber.maxLength = "8"; // DNI
          this.controlConfig.documentNumber.pattern = "onlyNumbers";
          break;
        case 3: //Carnet de extranjeria
        case 4: //Pasaporte
        case 5: //Cedula Diplomatica
          this.controlConfig.documentNumber.minLength = "1";
          this.controlConfig.documentNumber.maxLength = "15";
          this.controlConfig.documentNumber.pattern = "alphaNumeric";
          break;
        default:
          this.formGroup.controls["numeroDocumento"].disable();
          break;
      }
    } else {
      switch (data.tipoDocumento) {
        case 1:
          this.controlConfig.documentNumber.minLength = "11";
          this.controlConfig.documentNumber.maxLength = "11";
          this.controlConfig.documentNumber.pattern = "onlyNumbers";
          break;
        default:
          this.formGroup.controls["numeroDocumento"].disable();
          break;
      }
    }

    this.formGroup
      .get("numeroDocumento")
      .setValidators([
        Validators.required,
        Validators.minLength(
          Number(this.controlConfig.documentNumber.minLength)
        ),
        Validators.maxLength(
          Number(this.controlConfig.documentNumber.maxLength)
        ),
        Validators.pattern("^.+$"),
      ]);

    this.formGroup.updateValueAndValidity();
  }

  changetipoDocumento(event: any) {
    this.formGroup.valid ? (this.requerido = true) : (this.requerido = false);

    let configurationInfo = JSON.parse(
      this.cacheService.loadConfigurationInfo()
    );

    this.tipoDocumento = this.filterByPersona(
      Number(this.formGroup.get("tipoPersona").value),
      configurationInfo.data.documentTypes
    );

    this.changePersonType(
      { value: this.formGroup.get("tipoPersona").value },
      false
    );
  }

  selectionChange(event) {
    if (event.isUserInput) {
      console.log(event.source.value, event.source.selected);
    }
  }

  get f() {
    return this.formGroup.controls;
  }

  public findInvalidControls() {
    const invalid = [];
    const controls = this.formGroup.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        invalid.push(name);
      }
    }
    return invalid;
  }

  onSubmit(): void {
    this.submitted = true;

    if (this.formGroup.get("tipoPersona").value === 2) {
      this.formGroup.get("nombres").clearValidators();
      this.formGroup.get("nombres").updateValueAndValidity();

      this.formGroup.get("apellidoMaterno").clearValidators();
      this.formGroup.get("apellidoMaterno").updateValueAndValidity();

      this.formGroup.get("apellidoPaterno").clearValidators();
      this.formGroup.get("apellidoPaterno").updateValueAndValidity();

      this.formGroup.get("razonSocial").setValidators([Validators.required]);
    } else {
      // this.formGroup.get('nombres').reset();
      this.formGroup.get("nombres").setValidators([Validators.required]);
      this.formGroup.get("nombres").updateValueAndValidity();

      // this.formGroup.get('apellidoPaterno').reset();
      this.formGroup
        .get("apellidoPaterno")
        .setValidators([Validators.required]);
      this.formGroup.get("apellidoPaterno").updateValueAndValidity();

      // this.formGroup.get('apellidoMaterno').reset();
      this.formGroup
        .get("apellidoMaterno")
        .setValidators([Validators.required]);
      this.formGroup.get("apellidoMaterno").updateValueAndValidity();

      this.formGroup.get("razonSocial").clearValidators();
      this.formGroup.get("razonSocial").updateValueAndValidity();
    }

    if (this.formGroup.valid) {
      this.uploading = true;

      this.recaptchaV3Service
        .execute("importantAction")
        .subscribe((token: string) => {
          let httpOptions = {
            reportProgress: true,
            headers: new HttpHeaders({
              "Content-Type": "application/json",
              "g-recaptcha-response": token,
            }),
          };

          // if (this.formGroup.get("tipoProducto") == null) {
          //   this.formGroup.value.tipoProducto = null;
          // }

          if (this.formGroup.get("tipoConsulta") == null) {
            this.formGroup.value.tipoConsulta = null;
          }

          this.httpClient
            .post(
              `${environment.urlApiDCF}QueryManagement/QueryRegister`,
              this.formGroup.value,
              httpOptions
            )
            .subscribe(
              (response) => {
                let res = JSON.parse(JSON.stringify(response, null, 2));

                if (res.ok) {
                  this.showMessage(
                    "Mensaje de confirmación",
                    `Su consulta se ha enviado correctamente.`
                  );
                  this.resetForm();
                } else {
                  this.showMessage(
                    "Mensaje de error",
                    "Error al enviar la consulta. Por favor inténtelo de nuevo."
                  );
                }

                this.uploading = false;
              },
              (errorData) => {
                this.showMessage(
                  "Mensaje de error",
                  "Ha ocurrido un error, por favor inténtelo de nuevo más tarde"
                );
                console.log(errorData.error.errors);
              }
            );
        });
    } else {
      return;
    }
  }

  showMessage(title: string, message: string): void {
    this.dialogo
      .open(DialogInformationComponent, {
        data: { title: title, message: message },
      })
      .afterClosed()
      .subscribe((confirmado: Boolean) => {
        console.log(confirmado);
      });
  }
}
