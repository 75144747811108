import { Component, OnInit, NgModule, ViewEncapsulation } from "@angular/core";
import { ConfigurationInfoService } from "../../../services/common/configuration.service";
import { TipsService } from "src/app/services/tips/tips.services";

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: "app-tips",
  templateUrl: "./tips.component.html",
  styleUrls: ["./tips.component.scss"],
})
export class TipsComponent implements OnInit {
  public title: string = "";
  public subTitle: string = "";
  public data: any;
  public filter: any;
  visibleCards: number = 6;
  origin: string;
  textFilter: string;
  dataTips: any[];
  showListOptions: boolean = false;
  showProductOptions: boolean = false;
  showReasonOptions: boolean = false;
  cards = [
    {
      id: 1,
      image: "assets/img/tips/img_consejos_card.jpg",
      category: ["Category1", "category2"],
      title: "Luchar contra todos",
      content:
        "No es de sorprendernos, estos nos transportan a mundos mágicos, producen emociones como la alegría, simpatía y compasión, por nombrar solo algunas",
      autor: "Jhon Smith",
      date: "01 marzo 2024",
    },
    {
      id: 2,
      image: "assets/img/tips/img_consejos_card.jpg",
      category: ["Category3", "category4"],
      title: "La venganza de los olvidados",
      content:
        "Así que, si quieres viajar a un mundo imaginario, ¡has llegado al lugar correcto! Navega nuestro portal",
      autor: "Jhon Smith",
      date: "02 marzo 2024",
    },
    {
      id: 3,
      image: "assets/img/tips/img_consejos_card.jpg",
      category: ["Category5", "category6"],
      title: "carga de datos",
      content:
        "Y hablando del temible lobo feroz, este se encontraba merodeando por el bosque cuando vio al cerdito menor durmiendo",
      autor: "Jhon Smith",
      date: "03 marzo 2024",
    },
    {
      id: 4,
      image: "assets/img/tips/img_consejos_card.jpg",
      category: ["Category7", "category8"],
      title: "prueba de campo",
      content:
        "Hermanitos, hace mucho frío y ustedes la han pasado muy mal, así que disfrutemos la noche al calor de la fogata ",
      autor: "Jhon Smith",
      date: "04 marzo 2024",
    },
    {
      id: 5,
      image: "assets/img/tips/img_consejos_card.jpg",
      category: ["Category9", "category10"],
      title: "Ahora somos los mejores",
      content:
        "En un pueblito no muy lejano, vivía una mamá cerdita junto con sus tres cerditos.",
      autor: "Jhon Smith",
      date: "05 marzo 2024",
    },
    {
      id: 6,
      image: "assets/img/tips/img_consejos_card.jpg",
      category: ["Category11", "category12"],
      title: "Entiendo lo que no entiendo",
      content:
        "Un día cualquiera, mamá cerdita fue a visitar a sus queridos cerditos y descubrió que todos tres habían construido casitas de ladrillos.",
      autor: "Jhon Smith",
      date: "06 marzo 2024",
    },
  ];
  searchText: any;

  constructor(
    private configurationInfoService: ConfigurationInfoService,
    private tipsService: TipsService
  ) {}

  ngOnInit() {
    this.origin = window.location.href;
    this.filteredCards = this.dataTips;
    this.getData();
  }

  ngOnDestroy() {}

  filteredCards: any[] = [];
  filterOptions: string[] = [];
  allTextsProducts: string[] = [];
  allTextsReasons: string[] = [];
  tags: string[] = [];
  searchPerformed: boolean = false;

  filterPage(filters: string[], array: any[]) {
    if (!array) {
      return [];
    }
    return array.filter((item) => {
      if (item) {
        return filters.every(
          (filter) =>
            item.title.toLowerCase().includes(filter) ||
            item.summary.toLowerCase().includes(filter) ||
            item.textProduct.toLowerCase().includes(filter) ||
            item.textProblem.toLowerCase().includes(filter) ||
            item.textReason.toLowerCase().includes(filter) ||
            item.date.toLowerCase().includes(filter)
        );
      }
      return false;
    });
  }

  addTag(tag: string) {
    if (tag && !this.tags.includes(tag)) {
      this.tags.push(tag);
    }
  }

  performSearch(option?: string) {
    const normalizedOption = option ? option.toLowerCase() : "";
    const normalizedSearchText = this.searchText
      ? this.searchText.toLowerCase()
      : "";

    if (normalizedOption && !this.tags.includes(normalizedOption)) {
      this.tags.push(normalizedOption);
      this.addTag(normalizedOption);
    }

    if (normalizedSearchText && !this.tags.includes(normalizedSearchText)) {
      this.tags.push(normalizedSearchText);
    }

    if (this.tags.length === 0) {
      this.searchPerformed = false;
      this.filteredCards = this.dataTips;
    } else {
      this.filteredCards = this.filterPage(this.tags, this.dataTips);
      this.searchPerformed = true;
    }

    this.textFilter = "";
    this.searchText = "";
  }

  toggleListOptions(optionType: string) {
    console.log(optionType);
    if (optionType === "products") {
      this.showProductOptions = !this.showProductOptions;
      if (this.showProductOptions) {
        this.showReasonOptions = false;
      }
    } else if (optionType === "reasons") {
      this.showReasonOptions = !this.showReasonOptions;
      if (this.showReasonOptions) {
        this.showProductOptions = false;
      }
    }
  }

  removeTag(tag: string) {
    this.tags = this.tags.filter((t) => t !== tag);
    if (this.tags.length === 0) {
      this.searchPerformed = false;
      this.filteredCards = this.dataTips;
    } else {
      this.filteredCards = this.filterPage(this.tags, this.dataTips);
      this.searchPerformed = true;
    }
  }

  loadMore() {
    this.visibleCards += 6;
  }

  getData() {
    let dataLoaded = false;

    this.tipsService.GetTips().subscribe((response: any) => {
      this.dataTips = response.data
      .filter((tip: any) => tip.webPublish) // Filter out tips with webPublish set to false
      .map((tip: any) => ({
        ...tip,
        date: new Date(tip.creationDate).toLocaleDateString("es-ES", {
          year: "numeric",
          month: "long",
          day: "numeric",
        }),
      }));

      /* this.dataTips = response.data.map((tip: any) => ({
        ...tip,
        date: new Date(tip.creationDate).toLocaleDateString("es-ES", {
          year: "numeric",
          month: "long",
          day: "numeric",
        }),
      })); */

      this.allTextsProducts = [];
      this.allTextsReasons = [];
      this.dataTips.forEach((tip: any) => {
        this.allTextsProducts.push(tip.textProduct);
        this.allTextsReasons.push(tip.textReason);
      });

      this.allTextsProducts = this.allTextsProducts.filter(
        (value, index, self) => self.indexOf(value) === index
      );
      this.allTextsReasons = this.allTextsReasons.filter(
        (value, index, self) => self.indexOf(value) === index
      );

      dataLoaded = true;

      if (dataLoaded) {
        this.filteredCards = this.dataTips;
        if (this.searchText) {
          this.filteredCards = this.filterPage(
            [this.searchText.toLowerCase()],
            this.dataTips
          );
          this.searchPerformed = true;
        }
      }
    });
  }
}
