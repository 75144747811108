import { Component, Output, OnInit, ViewEncapsulation } from '@angular/core';
import { AsideLeftComponent } from './top-claim-sections/aside-left.component';
import { ConfigurationInfoService } from '../../../services/common/configuration.service';

declare const require: any;

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-top-claim-types',
  templateUrl: './top-claim-types.component.html',
  styleUrls: ['./top-claim-types.component.scss']
})

export class TopClaimTypesComponent implements OnInit {

  public href: string = "";
  public title: string = "";
  public subTitle: string = "";
  public data: any;
  public filter: any;

  constructor(
    private configurationInfoService: ConfigurationInfoService,
  ) {
  }

  ngOnInit() {
    let params = new URLSearchParams(window.location.search);
    this.getData();
  }

  ngOnDestroy() { }

  filterPage(type: number, array: any[]) {
    return array.filter((arr) => arr.id == type);
  }

  getData() {

    this.configurationInfoService.GetPagesContent().subscribe((response: any) => {

      if (response.ok) {
        const data = this.filterPage(
          Number(8),
          response.data.pages
        );

        console.log(data[0]);

        this.title = data[0].title;

        this.subTitle = data[0].subTitle;
      }

    });
  }
}