<section id="claim"
         class="claim-area uk-claim uk-section uk-section-area">
  <div class="uk-container">
    <div class="uk-section-title section-title uk-align-center">
      <span>{{subTitle}}</span>
      <h2>{{title}}</h2>
    </div>
  </div>
</section>

<section id="claim"
         class="uk-services-details uk-section2">
  <div class="uk-container">
    <div class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-1@m uk-child-width-1-1@s">
      <div class="item">
        <form id="claimForm">
          <div class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-3@m uk-child-width-1-1@s">
          </div>
          <div class="item">
            <div class="inner uk-width-expand">
              <div class="services-details-desc">
                <div class="modal-body">
                  <h3>{{sectionTitle}}</h3>
                  <h6>{{sectionSubTitle}}</h6>

                  <div [innerHTML]="sectionDescription">
                    {{sectionDescription}}
                  </div>

                </div>

              </div>
            </div>
          </div>

        </form>
      </div>

      <div class="item">

      </div>
    </div>
  </div>
</section>
