import { MAT_DIALOG_DATA, MatDialogRef, MatDialogModule } from '@angular/material/dialog';
import { Component, Inject } from '@angular/core';

@Component({
  selector: 'app-dialog-confirmation',
  template: `
    <h1 mat-dialog-title>{{ data.title }}</h1>
    <div mat-dialog-content>
      <p>{{ data.message }}</p>
    </div>
    <div mat-dialog-actions style="text-align:center;">
      <button mat-button  class="uk-button uk-button-primary uk-width-1-4 uk-margin-small-bottom" style="margin:0 10px;"
        mat-dialog-close="yes" (click)="onNoClick('yes')">Sí</button>
      <button mat-button class="uk-button uk-button-primary uk-width-1-4 uk-margin-small-bottom" style="margin:0 10px;"
        mat-dialog-close="no" (click)="onNoClick('no')">No</button>    
    </div>
  `,
})
export class DialogConfirmationComponent {
  constructor(
    public dialogRef: MatDialogRef<DialogConfirmationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  onNoClick(result): void {
    this.dialogRef.close(result);
  }
}
