import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  ViewEncapsulation,
} from "@angular/core";
import { CustomvalidationService } from "../../../services/custom-validation.service";
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  FormArray,
  FormControl,
  Validators,
  UntypedFormControl,
} from "@angular/forms";
import {
  HttpClient,
  HttpRequest,
  HttpEventType,
  HttpResponse,
  HttpHeaders,
  HttpErrorResponse,
} from "@angular/common/http";
import { finalize } from "rxjs/operators";
import { Router } from "@angular/router";
import { StyleService } from "../../../services/style.service";
import { CacheService } from "../../../services/cache.service";
import { environment } from "../../../../environments/environment";
import { ConfigurationInfoService } from "../../../services/common/configuration.service";
import { ReCaptchaV3Service } from "ng-recaptcha";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { DialogInformationComponent } from "../../../shared/dialog/dialog-information.component";
import { StatusInformationComponent } from "../../../shared/status/status-information.component";
import { MatTableDataSource } from "@angular/material/table";
import { DatePipe, formatDate } from "@angular/common";
import {
  ClrTimelineLayout,
  ClrTimeline,
  ClrTimelineStep,
} from "@clr/angular/timeline";
import { Observable, tap } from "rxjs";
import {
  RxwebValidators,
  NumericValueType,
} from "@rxweb/reactive-form-validators";
import * as UIkit from "uikit";
import * as JSZip from "jszip";
import * as FileSaver from "file-saver";
import { MatRadioButton, MatRadioChange } from "@angular/material/radio";
import { DialogConfirmationComponent } from "../../../shared/dialog-confirmation/dialog-confirmation.component";

interface StudentFormSubmissionResult {}

export interface ClaimStatus {
  codigoSolicitudReclamo: string;
  codigoReclamo: string;
  tipoDocumento: string;
  nroDocumento: string;
  estadoReclamo: string;
  fechaInicio: string;
  fechaModificado: string;
  fechaFin: string;
}

export interface Archivo {
  codigoArchivoAdjunto: number;
  codigoInstanciaProceso: number;
  descripcionTarea: string;
  nombreArchivo: string;
  ubicacion: string;
  codigoSolicitudReclamo: number;
  codigoTipoTarea: number;
  perfilNoAutorizado: string;
}

export interface Conciliacion {
  codigoConciliacion: number;
  codigoSolicitudReclamo: number;
  codigoInstanciaProceso: number;
  codigoTipoTarea: number;
  valorAnterior: string;
  valorNuevo: string;
  simboloMoneda: string;
  montoConciliacion: number;
  tipoPropuesta: string;
  devolucionMonetaria: string;
  plazoCumplimiento: number;
  fechaCreacion: string;
  fechaInicio: string;
  isCurrent: number;
  listaArchivos: Archivo[];
}

export interface IControlValidation {
  minLength: string;
  maxLength: string;
  pattern?: string;
}

declare const require: any;

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: "app-claim-status",
  templateUrl: "./claim-status.component.html",
  styleUrls: ["./claim-status.component.scss"],
})
export class ClaimStatusComponent implements OnInit {
  @Output() closeModalEvent = new EventEmitter<boolean>();

  formGroup: UntypedFormGroup;
  formGroupConciliacion: UntypedFormGroup;
  isMarkedNumero = true;
  requerido = false;
  consulting = false;
  submitted = false;
  success = false;
  error = false;
  claimStatus: ClaimStatus[] = [];
  conciliacionActual: Conciliacion;
  conciliacionesHistoricas: Conciliacion[] = [];
  orientation: string;
  items: any = [];
  DownloadFiles: boolean;
  UploadFiles: boolean;
  RemedialMessages: any;
  responseTracking: any;
  submissionResults: StudentFormSubmissionResult[] = [];
  errorMsg = "";
  selectedSupportingDocuments: File[];
  selectedConciliacionDocuments: File[];
  downloading = false;
  uploading = false;
  uploadingConciliacion = false;
  uploadProgress = 0;
  codigoInstanciaProceso: any;
  codigoTarea: any;
  tipoDocumento: any;
  tipoPersona: any;
  codigoPersona: any;
  nombreCompletoSolicitante: any;
  fechaInicio: any;
  fechaInicioReclamo: any;
  codigoEstado: any;
  fileActaConciliacion: any;
  estadoActual: any;
  sectionDescription: any;
  sectionTitle: any;

  public title: string = "";
  public subTitle: string = "";

  public questionA: any = "";

  public displayedColumns = [
    "codigoSolicitudReclamo",
    "codigoReclamo",
    "tipoDocumento",
    "nroDocumento",
    "fechaInicio",
    "fechaFin",
  ];

  public dataSource = new MatTableDataSource<ClaimStatus>();

  controlConfig: {
    documentNumber: IControlValidation;
    codigoReclamo: IControlValidation;
  } = {
    documentNumber: {
      minLength: "1",
      maxLength: "15",
      pattern: "alphaNumeric",
    },
    codigoReclamo: {
      minLength: "1",
      maxLength: "9",
    },
  };

  constructor(
    private recaptchaV3Service: ReCaptchaV3Service,
    private formBuilder: UntypedFormBuilder,
    private formBuilderConciliacion: UntypedFormBuilder,
    private readonly httpClient: HttpClient,
    private styleService: StyleService,
    private cacheService: CacheService,
    private customValidator: CustomvalidationService,
    private router: Router,
    private configurationInfoService: ConfigurationInfoService,
    public dialogo: MatDialog,
    private snackBar: MatSnackBar,
    private datePipe: DatePipe
  ) {}

  ngOnInit(): void {
    this.selectedSupportingDocuments = [];
    this.selectedConciliacionDocuments = [];
    this.orientation = "horizontal";
    this.createForm();
    this.getData();
    //this.styleService.addStyle("status-form", require("./claim-status.component.scss"));
  }

  ngOnDestroy() {
    //this.styleService.removeStyle("status-form");
  }

  onClose(event: any) {
    this.closeModalEvent.emit(false);
  }

  filterPage(type: number, array: any[]) {
    return array.filter((arr) => arr.id == type);
  }

  getData() {
    this.configurationInfoService
      .GetPagesContent()
      .subscribe((response: any) => {
        if (response.ok) {
          const data = this.filterPage(Number(7), response.data.pages);

          this.title = data[0].title;

          this.subTitle = data[0].subTitle;

          this.sectionTitle = data[0].sections[0].title;
          this.sectionDescription = data[0].sections[0].description;
        }
      });
  }

  createForm() {
    this.formGroup = this.formBuilder.group({
      nroDocumento: [
        null,
        Validators.compose([
          Validators.minLength(
            Number(this.controlConfig.documentNumber.minLength)
          ),
          Validators.required,
        ]),
      ],
      codigoReclamo: [
        null,
        Validators.compose([
          Validators.minLength(
            Number(this.controlConfig.codigoReclamo.minLength)
          ),
          Validators.required,
        ]),
      ],
    });

    this.formGroupConciliacion = this.formBuilderConciliacion.group({
      consulta: [null],
      questionA: new UntypedFormControl(""),
    });

    this.formGroupConciliacion
      .get("questionA")
      .valueChanges.subscribe((value) => {
        if (value === "no") {
          this.formGroupConciliacion
            .get("consulta")
            .setValidators([
              Validators.required,
              Validators.minLength(10),
              Validators.maxLength(5000),
            ]);
        } else {
          this.formGroupConciliacion.get("consulta").clearValidators();
        }
        this.formGroupConciliacion.get("consulta").updateValueAndValidity();
      });
  }
  changeQuestion(mrChange: MatRadioChange) {
    let mrButton: MatRadioButton = mrChange.source;

    switch (mrButton.name) {
      case "questionA":
        this.questionA = mrButton.value;
        break;
      default:
        break;
    }
  }

  showStatus() {
    this.recaptchaV3Service
      .execute("importantAction")
      .subscribe((token: string) => {
        let httpOptions = {
          reportProgress: true,
          headers: new HttpHeaders({
            "g-recaptcha-response": token,
          }),
        };

        this.httpClient
          .post(
            `${environment.urlApiDCF}ClaimManagement/GetStatus`,
            this.formGroup.value,
            httpOptions
          )
          .subscribe(
            (response) => {
              console.log("response: ", response);
              let res = JSON.parse(JSON.stringify(response, null, 2));
              let ultimateState: number = null;

              if (res.ok) {
                // Get tracking information
                this.responseTracking = res.data;

                this.dataSource = new MatTableDataSource(
                  this.responseTracking.claimsStatus
                );
                this.responseTracking.claimsStatus.forEach((element) => {
                  this.codigoTarea = element.codigoTarea;
                  this.tipoDocumento = element.tipoDocumento;
                  this.tipoPersona = element.tipoPersona;
                  this.codigoPersona = element.codigoPersona;
                  this.nombreCompletoSolicitante = element.nombreCompleto;
                  this.fechaInicio = element.fechaInicio;
                  this.codigoEstado = element.estadoReclamo;
                  this.fechaInicioReclamo = element.fechaRegistro;
                  this.fileActaConciliacion =
                    environment.urlReports +
                    "Conciliacion/Generados/" +
                    element.nombreArchivo;
                });
                this.success = true;

                this.responseTracking.trackingStatus.forEach((element) => {
                  console.log("element: ", element);
                  if (element.codigoInstanciaProceso != null)
                    this.codigoInstanciaProceso =
                      element.codigoInstanciaProceso;

                  if (
                    element.codigoEtapa == 8 &&
                    element.codigoInstanciaProceso != null
                  ) {
                    this.DownloadFiles = true;
                  }

                  if (
                    element.codigoEtapa == 2 &&
                    element.enProceso &&
                    element.fechaFin == null
                  ) {
                    this.UploadFiles = true;

                    this.RemedialMessages = element.remedialMessages;
                  }

                  // if (
                  //   element.fechaInicio !== undefined &&
                  //   element.fechaInicio !== null
                  // ) {
                  //   ultimateState = element.codigoEtapa;
                  // }

                  let state =
                    element.codigoEtapa == 4
                      ? "error"
                      : element.codigoInstanciaProceso
                      ? "success"
                      : "not-started";

                  let stageDate =
                    element.fechaInicio != null
                      ? formatDate(element.fechaInicio, "yyyy-MM-dd", "en-US")
                      : "-";
                  // if (state == "success"){
                  //   stageDate = element.fechaFin != null ? formatDate(element.fechaFin, "yyyy-MM-dd", "en-US") : "-";
                  // } else {
                  //   stageDate = element.fechaInicio != null ? formatDate(element.fechaInicio, "yyyy-MM-dd", "en-US") : "-";
                  // }
                  let item = {
                    active: true,
                    state: state,
                    header: stageDate,
                    title: element.nombreEtapa,
                    description: element.descripcionEtapa,
                  };
                  this.items.push(item);
                  console.log("item: ", item);
                });
                // Get hystoric of Conciliaciones
                console.log(
                  "this.responseTracking.claimsStatus: ",
                  this.responseTracking.claimsStatus
                );
                console.log(
                  "this.responseTracking.claimsStatus[0]: ",
                  this.responseTracking.claimsStatus[0]
                );
                console.log(
                  "CodigoSolicitudReclamo: ",
                  this.responseTracking.claimsStatus[0].codigoSolicitudReclamo.toString()
                );
                console.log(
                  "CodigoInstanciaProceso: ",
                  this.responseTracking.claimsStatus[0].codigoInstanciaProceso.toString()
                );

                var paramatersConciliacion = {
                  CodigoSolicitudReclamo:
                    this.responseTracking.claimsStatus[0].codigoSolicitudReclamo.toString(),
                  CodigoInstanciaProceso:
                    this.responseTracking.claimsStatus[0].codigoInstanciaProceso.toString(),
                };
                this.httpClient
                  .post(
                    `${environment.urlApiDCF}ClaimManagement/GetConciliaciones`,
                    paramatersConciliacion,
                    httpOptions
                  )
                  .subscribe(
                    (responseConciliaciones: any) => {
                      console.log(
                        "responseConciliaciones: ",
                        responseConciliaciones
                      );
                      // conciliacionActual: Conciliacion;
                      // conciliacionesHistoricas: Conciliacion[] = [];
                      responseConciliaciones.data.conciliaciones.forEach(
                        (conciliacion: Conciliacion) => {
                          if (conciliacion.isCurrent === 1) {
                            this.conciliacionActual = conciliacion;
                          } else {
                            conciliacion.fechaInicio = this.datePipe.transform(
                              conciliacion.fechaInicio,
                              "dd/MM/yyyy"
                            );
                            this.conciliacionesHistoricas.push(conciliacion);
                          }
                        }
                      );
                    },
                    (error) => {
                      console.error("Error fetching conciliaciones: ", error);
                    }
                  );
              } else {
                this.success = false;
                this.showMessage(
                  "Mensaje de alerta",
                  "No se han encontrado registros del estado de su reclamo.",
                  false
                );
              }

              this.consulting = false;
            },
            (errorData) => {
              this.consulting = false;
              this.showMessage(
                "Mensaje de error",
                "Ha ocurrido un error, por favor inténtelo de nuevo más tarde",
                false
              );
            }
          );
      });
  }

  onSubmit(): void {
    this.submitted = true;

    if (this.formGroup.valid) {
      this.items.splice(0);

      this.success = false;

      this.consulting = true;

      this.DownloadFiles = false;

      this.UploadFiles = false;

      this.showStatus();
    } else {
      console.log("this.formGroup.valid: ", this.formGroup.valid);
      console.log("this.formGroup: ", this.formGroup);
      return;
    }
  }

  onSubmitConciliacion(): void {
    this.submitted = true;
    const formData = new FormData();

    formData.append("NroDocumento", this.formGroup.get("nroDocumento").value);
    formData.append("CodigoReclamo", this.formGroup.get("codigoReclamo").value);
    formData.append("CodigoTarea", this.codigoTarea);
    formData.append("codigoInstanciaProceso", this.codigoInstanciaProceso);
    formData.append("codigoPersona", this.codigoPersona);
    formData.append("tipoPersona", this.tipoPersona);
    formData.append("tipoDocumento", this.tipoDocumento);
    formData.append("question", this.questionA);
    formData.append("CodigoEstado", this.codigoEstado);
    if (this.questionA == "no")
      formData.append(
        "Consulta",
        this.formGroupConciliacion.get("consulta").value
      );

    if (this.selectedConciliacionDocuments != null) {
      this.selectedConciliacionDocuments.forEach((f) =>
        formData.append("DocumentosConciliacion", f)
      );
    }

    if (this.formGroup.valid) {
      this.uploadingConciliacion = true;

      var questionA = this.questionA;
      if (questionA == "yes") {
        this.submitConciliacion(formData);
      } else {
        const dialogRef = this.dialogo.open(DialogConfirmationComponent, {
          width: "420px",
          maxWidth: "100%",
          data: {
            title: "Confirmación",
            message: '¿Está seguro que quiere seleccionar "No"?',
          },
        });

        dialogRef.afterClosed().subscribe((result) => {
          console.log("result: ", result);
          if (result === "yes") {
            // User confirmed the action
            this.showSuccessMessage("Gracias, por tu respuesta");
            this.submitConciliacion(formData);
          } else {
            // User did not confirm the action, revert the selection or set it to "Sí"
            this.uploadingConciliacion = false;
            this.questionA = "yes";
          }
        });
      }
    }
  }

  submitConciliacion(formData): void {
    this.recaptchaV3Service
      .execute("importantAction")
      .subscribe((token: string) => {
        let options = {
          reportProgress: true,
          headers: new HttpHeaders({
            "g-recaptcha-response": token,
          }),
        };

        //TODO: on conciliation proces you need to distinguish if its a conciliación with acta or NOT!
        const req = new HttpRequest(
          "POST",
          `${environment.urlApiDCF}ClaimManagement/Conciliacion`,
          formData,
          options
        );

        this.httpClient
          .request<StudentFormSubmissionResult[]>(req)
          .pipe(
            finalize(() => {
              this.uploading = false;
            })
          )
          .subscribe(
            (response) => {
              if (response.type === HttpEventType.UploadProgress) {
                this.uploadProgress = Math.round(
                  (100 * response.loaded) / response.total
                );
              } else if (response instanceof HttpResponse) {
                let res = JSON.parse(JSON.stringify(response, null, 2));

                if (res.ok) {
                  var successMessage =
                    this.codigoEstado == "Conciliación informada al cliente"
                      ? "Conciliación actualizada correctamente."
                      : "Sus documentos de conciliación se han enviado correctamente.";
                  this.showMessage(
                    "Mensaje de confirmación",
                    successMessage,
                    true
                  );
                  this.items.splice(0);
                  this.UploadFiles = false;
                  this.success = false;
                } else {
                  var errorMessage =
                    this.codigoEstado == "Conciliación informada al cliente"
                      ? "No se ha podido actualizar la Conciliación."
                      : "Error al enviar sus documentos de conciliación . Por favor inténtelo de nuevo.";

                  this.showMessage("Mensaje de error", errorMessage, false);
                }
              }
            },
            (errorData) => {
              this.success = false;
              this.error = true;
              this.showMessage(
                "Mensaje de error",
                "Error al enviar sus documentos de conciliación. Por favor inténtelo más tarde.",
                false
              );
            }
          );
      });
  }

  onSubmitFile(event: any): void {
    this.submitted = true;

    console.log(
      "selectedSupportingDocuments: ",
      this.selectedSupportingDocuments
    );

    event.preventDefault();

    if (this.formGroup.valid && this.selectedSupportingDocuments != null) {
      this.uploading = true;

      const formData = new FormData();

      formData.append("NroDocumento", this.formGroup.get("nroDocumento").value);
      formData.append(
        "CodigoReclamo",
        this.formGroup.get("codigoReclamo").value
      );
      formData.append("CodigoTarea", this.codigoTarea);
      formData.append("codigoInstanciaProceso", this.codigoInstanciaProceso);
      formData.append("codigoPersona", this.codigoPersona);
      formData.append("tipoPersona", this.tipoPersona);
      formData.append("tipoDocumento", this.tipoDocumento);

      if (this.selectedSupportingDocuments != null) {
        this.selectedSupportingDocuments.forEach((f) =>
          formData.append("DocumentosSustento", f)
        );
      }

      this.recaptchaV3Service
        .execute("importantAction")
        .subscribe((token: string) => {
          let options = {
            reportProgress: true,
            headers: new HttpHeaders({
              "g-recaptcha-response": token,
            }),
          };

          const req = new HttpRequest(
            "POST",
            `${environment.urlApiDCF}ClaimManagement/Subsanacion`,
            formData,
            options
          );

          this.httpClient
            .request<StudentFormSubmissionResult[]>(req)
            .pipe(
              finalize(() => {
                this.uploading = false;
              })
            )
            .subscribe(
              (response) => {
                if (response.type === HttpEventType.UploadProgress) {
                  this.uploadProgress = Math.round(
                    (100 * response.loaded) / response.total
                  );
                } else if (response instanceof HttpResponse) {
                  let res = JSON.parse(JSON.stringify(response, null, 2));

                  if (res.ok) {
                    this.showMessage(
                      "Mensaje de confirmación",
                      `Sus documentos de subsanación se han enviado correctamente.`,
                      true
                    );
                    this.items.splice(0);
                    this.UploadFiles = false;
                    this.success = false;
                  } else {
                    this.showMessage(
                      "Mensaje de error",
                      "Error al enviar sus documentos de subsanación . Por favor inténtelo de nuevo.",
                      false
                    );
                  }
                }
              },
              (errorData) => {
                this.success = false;
                this.error = true;
                this.showMessage(
                  "Mensaje de error",
                  "Error al enviar sus documentos de subsanación. Por favor inténtelo más tarde.",
                  false
                );
              }
            );
        });
    } else {
      console.log("obSubmitFile: invalid");
      if (this.selectedSupportingDocuments != null)
        console.log("no files selected");
      return;
    }
  }

  openDetails() {
    this.details("", "");
  }

  details(title: string, message: string): void {
    this.dialogo
      .open(StatusInformationComponent, {
        data: { title: title, message: message },
      })
      .afterClosed()
      .subscribe((confirmado: Boolean) => {
        console.log(confirmado);
      });
  }

  showMessage(title: string, message: string, reload: boolean): void {
    this.dialogo
      .open(DialogInformationComponent, {
        data: { title: title, message: message },
      })
      .afterClosed()
      .subscribe((confirmado: Boolean) => {
        console.log(confirmado);
        if (reload) {
          window.location.reload();
        }
      });
  }

  async downloadFiles() {
    try {
      if (this.codigoInstanciaProceso != null) {
        const fileData: any = await this.getFile(
          `${environment.urlApiDCF}ClaimManagement/GetResolucion`,
          this.codigoInstanciaProceso
        );

        if (fileData.type !== "error") {
          const blob = new Blob([fileData], { type: "application/zip" });
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = url;
          a.download = "DCF-documentos";
          a.click();
          setTimeout(() => {
            window.URL.revokeObjectURL(url);
          }, 0);
        } else {
          this.showMessage(
            "Mensaje de error",
            "Ha ocurrido un error al descargar los archivos, por favor inténtelo de nuevo.",
            false
          );
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  downloadFiles2() {
    this.downloading = true;

    this.recaptchaV3Service
      .execute("importantAction")
      .subscribe((token: string) => {
        let httpOptions = {
          reportProgress: true,
          headers: new HttpHeaders({
            "Content-Type": "application/json",
            "g-recaptcha-response": token,
          }),
        };

        this.httpClient
          .post(
            `${environment.urlApiDCF}ClaimManagement/GetResolucion`,
            {
              CodigoInstanciaProceso: this.codigoInstanciaProceso,
              CodigoAplicacion: 3,
              CodigoTipoTarea: 17,
            },
            httpOptions
          )
          .subscribe(
            (response) => {
              this.items.splice(0);

              this.success = true;
              this.downloading = false;
              this.processFiles(response);
            },
            (errorData) => {
              this.downloading = false;
              this.showMessage(
                "Mensaje de error",
                "Ha ocurrido un error al descargar los archivos, por favor inténtelo de nuevo.",
                false
              );
            }
          );
      });
  }

  processFiles(data: any) {
    const blob = new Blob([data], { type: "application/pdf" });
    const url = window.URL.createObjectURL(blob);
    window.open(url);
  }

  resetForm() {
    this.formGroup.reset();
  }

  async getFile(url: string, codigoInstanciaProceso: string) {
    const httpOptions = {
      responseType: "blob" as "json",
    };
    const res = await this.httpClient
      .post(
        url,
        {
          CodigoInstanciaProceso: codigoInstanciaProceso,
          CodigoAplicacion: 3,
          CodigoTipoTarea: 17,
        },
        httpOptions
      )
      .toPromise()
      .catch((err: HttpErrorResponse) => {
        const error = err.error;
        return error;
      });
    return res;
  }

  humanFileSize(bytes: number): string {
    if (Math.abs(bytes) < 1024) {
      return bytes + " B";
    }
    const units = ["kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    let u = -1;
    do {
      bytes /= 1024;
      u++;
    } while (Math.abs(bytes) >= 1024 && u < units.length - 1);
    return bytes.toFixed(1) + " " + units[u];
  }

  removeFile(item: any, origin: string) {
    let index: any;

    switch (origin) {
      case "SupportingDocuments":
        index = this.selectedSupportingDocuments.findIndex(
          (d) => d.name === item.name
        );
        this.selectedSupportingDocuments.splice(index, 1);
        break;
      case "ConciliacionDocuments":
        index = this.selectedConciliacionDocuments.findIndex(
          (d) => d.name === item.name
        );
        this.selectedConciliacionDocuments.splice(index, 1);
        break;
    }
  }

  chooseFiles(files: FileList, origin: string) {
    this.uploadProgress = 0;
    this.errorMsg = "";

    switch (
      origin
      //case 'SupportingDocuments': this.selectedSupportingDocuments = []; break;
    ) {
    }

    if (files.length === 0 || files.length > 5) {
      this.errorMsg =
        "El número de archivos excede el limite permitido en este sitio. Max. 5 archivos.";
      return;
    }

    for (let i = 0; i < files.length; i++) {
      if (files[i].size > 30090627) {
        this.errorMsg = `El tamaño del archivo ${files[i].name} excede el tamaño permitido en este sitio.`;
      } else {
        switch (origin) {
          case "SupportingDocuments":
            this.selectedSupportingDocuments.push(files[i]);
            break;
          case "ConciliacionDocuments":
            this.selectedConciliacionDocuments.push(files[i]);
            break;
        }
      }
    }
  }
  showSuccessMessage(message: string) {
    this.snackBar.open(message, "Cerrar", {
      duration: 3000,
      horizontalPosition: "right",
      verticalPosition: "top",
      panelClass: ["snack-bar"],
    });
  }

  replacePublicUrl(fileLocation: string) {
    if (fileLocation.startsWith("~/App_Data/")) {
      return fileLocation.replace("~/App_Data/", environment.urlMediaGeneral);
    } else if (fileLocation.startsWith("~/Reporte/")) {
      return fileLocation.replace("~/Reporte/", environment.urlReports);
    }
    return fileLocation;
  }
}
