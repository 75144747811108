import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { StyleService } from "../../services/style.service";

declare const require: any;

@Component({
  selector: 'app-dialog-information',
  templateUrl: './dialog-information.component.html'
  // styleUrls: ['./dialog-information.component.scss'],
})

export class DialogInformationComponent implements OnInit {

  constructor(
    public dialog: MatDialogRef<DialogInformationComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private styleService: StyleService
  ) { }

  close(): void {
    this.dialog.close(false);
  }

  ngOnInit() {
    this.styleService.addStyle("querys-form-wizard", require("./dialog-information.component.scss"));

  }
  ngOnDestroy() {
    this.styleService.removeStyle("querys-form-wizard");
  }
}