import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, map } from "rxjs";
import { environment } from "src/environments/environment";
import { HomeTipsPaths, TipsPaths } from "./tips.paths";
import { TipsModel } from "../../models/tips/tips";

@Injectable()
export class TipsService {
  _url: string;

  constructor(private http: HttpClient) {
    this._url = environment.urlApiDCF;
  }

  GetTips(): Observable<TipsModel.Response> {
    return this.http
      .get(`${this._url}${TipsPaths.GetTips}`)
      .pipe(map((response) => response)) as Observable<TipsModel.Response>;
  }

  GetHomeTips(): Observable<TipsModel.Response> {
    return this.http
      .get(`${this._url}${HomeTipsPaths.HomeGetTips}`)
      .pipe(map((response) => response)) as Observable<TipsModel.Response>;
  }
}
