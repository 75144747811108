<section id="claim" class="claim-area uk-claim uk-section uk-section-area">
  <div class="uk-container">
    <div class="uk-section-title section-title uk-align-center">
      <span>{{ tipFiltered.date }}</span>
      <h2>{{ tipFiltered.title }}</h2>
    </div>
  </div>
</section>
<section class="uk-tips tips-area uk-section">
  <div class="uk-container">
    <div
      class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-1@m uk-child-width-1-1@s"
    >
      <div class="item">
        <div class="tips-content">
          <div class="banner">
            <picture>
              <source
                src="{{
                  tipFiltered.pathImage
                    ? tipFiltered.pathImage
                    : '../../../../assets/img/tips/img_template_banner.jpg'
                }}"
                media="(min-width: 1025px)"
              />
              <img
                src="{{
                  tipFiltered.pathImage
                    ? tipFiltered.pathImage
                    : '../../../../assets/img/tips/img_template_banner.jpg'
                }}"
              />
            </picture>
          </div>
          <div class="tips-text">
            <div class="title" [innerHTML]="tipFiltered.title"></div>
            <p [innerHTML]="tipFiltered.detail"></p>
          </div>
          <div class="tips-text">
            <div class="title">También te puede interesar</div>
            <app-interest-you></app-interest-you>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
