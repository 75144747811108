import {Directive, HostListener, Input} from '@angular/core';

@Directive({
    selector: '[appInputPattern]'
})
export class InputPatternDirective {

    @Input() customPatter: string;

    patterns = {
        alphaNumeric: /[^a-zA-Z0-9\s]*/g,
        onlyNumbers: /[^0-9]*/g,
        // email: /^\w+([\\.-]?\w+)@\w+([\\.-]?\w+)(\.\w{2,10})+([\-]?\s{0,10})$/,
        onlyAlpha: /[^a-zA-Z\s]*/g
    };

    constructor() {
    }

    getValue(value, patternName: string): string {
        const pattern = this.patterns[patternName];
        const valueReplaced = value.replace(pattern, '');
        return valueReplaced;
    }

    @HostListener('keyup', ['$event'])
    onKeyUpReplaced(event) {
        const {customPatter} = this;
        const value = event.target.value;
        event.target.value = this.getValue(value, customPatter);
    }

}
