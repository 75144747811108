export const environment = {
  production: false,
  defaultUser: "guest",
  limitedFile: 30,
  urlMediaGeneral: "https://intranet.dcf-qa.upfront.pe/Media/",
  urlReports: "https://intranet.dcf-qa.upfront.pe/Reports/",
  urlApiDCF: "https://backend.dcf-qa.upfront.pe/api/",
  // urlApiDCF: "https://localhost:44377/api/",
  recaptcha: {
    siteKey: "6Le8rMEfAAAAAGCS2-BZuaRgw7S5_MZKtJI5J0z8",
  },
};
