import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ConfigurationInfoService } from '../../../services/common/configuration.service';

declare const require: any;
@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ContactComponent implements OnInit {

  public title: string = "";
  public subTitle: string = "";
  public sectionDescription: any;
  public filter: any;

  constructor(private configurationInfoService: ConfigurationInfoService,
  ) { }

  ngOnInit() {
    this.getData();
  }
  ngOnDestroy() { }

  filterPage(type: number, array: any[]) {
    return array.filter((arr) => arr.id == type);
  }

  getData() {

    this.configurationInfoService.GetPagesContent().subscribe((response: any) => {

      if (response.ok) {
        const data = this.filterPage(
          Number(13),
          response.data.pages
        );

        this.title = data[0].title;

        this.subTitle = data[0].subTitle;

        this.sectionDescription = data[0].sections[0].description;
      }

    });
  }
}



