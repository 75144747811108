
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { MatRadioButton, MatRadioChange } from '@angular/material/radio';
import * as UIkit from 'uikit';
import { ConfigurationInfoService } from '../../../services/common/configuration.service';

interface CertificateSubmissionResult {
    fileName: string;
    fileSize: number;
}

declare const require: any;

@Component({
    encapsulation: ViewEncapsulation.None,
    selector: 'claim-question',
    templateUrl: './claim-question.component.html',
    styleUrls: ['./claim-question.component.scss'],
})

export class ClaimQuestionComponent implements OnInit {

    public formGroup: UntypedFormGroup;
    public post: any = '';

    public questionA: any = '';
    public questionB: any = '';
    public questionC: any = '';
    public questionD: any = '';


    public title: string = "";
    public subTitle: string = "";
    public sectionDescription: any;
    public sectionTitle: any;
    public sectionSubTitle: any;

    constructor(
        private formBuilder: UntypedFormBuilder,
        private configurationInfoService: ConfigurationInfoService,
    ) { }

    RESPUESTAS_LIST = [
        { id: '1', value: 'Para poder presentar un reclamo ante la Defensoría, primero debes haber presentado un reclamo ante tu banco sobre los mismos hechos y obtenido una respuesta negativa.' },
        { id: '2', value: 'La Defensoría sólo puede atender reclamos cuyos hechos no superen los 2 años de antigüedad.' },
        { id: '3', value: 'La Defensoría no es competente para tramitar reclamos cuyo importe o suma de importes es superior a S/ 70,000.00 o su equivalente en otras monedas.' },
        { id: '4', value: 'La Defensoría no es competente para tramitar denuncias sobre los mismos hechos que se encuentren en proceso o concluidas ante Indecopi o Poder Judicial.' },
        { id: '5', value: '<b>Por tanto, tu reclamo será denegado por no ser competentes para antenderlo. </b>' },
    ];

    ngOnInit(): void {

        this.getData();

        this.createForm();
    }

    ngOnDestroy() { }

    createForm() {

        this.formGroup = this.formBuilder.group({
            step1: this.formBuilder.group({
                questionA: new UntypedFormControl('', Validators.required),
                questionB: new UntypedFormControl('', Validators.required),
                questionC: new UntypedFormControl('', Validators.required),
                questionD: new UntypedFormControl('', Validators.required)
            })
        });
    }

    getData() {

        this.configurationInfoService.GetPagesContent().subscribe((response: any) => {

            if (response.ok) {
                const data = this.filterPage(
                    Number(14),
                    response.data.pages
                );

                this.title = data[0].title;
                this.subTitle = data[0].subTitle;

                this.sectionTitle = data[0].sections[0].title;
                this.sectionSubTitle = data[0].sections[0].subTitle;
                this.sectionDescription = data[0].sections[0].description;
            }
        });

    }

    filterPage(type: number, array: any[]) {
        return array.filter((arr) => arr.id == type);
    }

    changeQuestion(mrChange: MatRadioChange) {

        let mrButton: MatRadioButton = mrChange.source;

        switch (mrButton.name) {
            case 'questionA':
                this.questionA = mrButton.value;
                break;
            case 'questionB':
                this.questionB = mrButton.value;
                break;
            case 'questionC':
                this.questionC = mrButton.value;
                break;
            case 'questionD':
                this.questionD = mrButton.value;
                break;
            default:
                break;
        }
    }

    selectionChange(event) {
        if (event.isUserInput) {
        }
    }

    onNextStepQuestions() {

        let message = '';

        if (this.questionA.toLowerCase() === "yes" && this.questionB.toLowerCase() === "yes" && this.questionC.toLowerCase() === "yes" && this.questionD.toLowerCase() === "no") {

        }
        else {
            if (this.questionA.toLowerCase() === "yes" && this.questionB.toLowerCase() === "no" && this.questionC.toLowerCase() === "no" && this.questionD.toLowerCase() === "yes") {
                message = this.RESPUESTAS_LIST.find(x => x.id === '2').value;
                message += '<br><br>' + this.RESPUESTAS_LIST.find(x => x.id === '3').value;
                message += '<br><br>' + this.RESPUESTAS_LIST.find(x => x.id === '4').value;
            }
            else if (this.questionA.toLowerCase() === "yes" && this.questionB.toLowerCase() === "no" && this.questionC.toLowerCase() === "no" && this.questionD.toLowerCase() === "no") {
                message = this.RESPUESTAS_LIST.find(x => x.id === '2').value;
                message += '<br><br>' + this.RESPUESTAS_LIST.find(x => x.id === '3').value;
            }
            else if (this.questionA.toLowerCase() === "yes" && this.questionB.toLowerCase() === "no" && this.questionC.toLowerCase() === "yes" && this.questionD.toLowerCase() === "yes") {
                message = this.RESPUESTAS_LIST.find(x => x.id === '2').value;
                message += '<br><br>' + this.RESPUESTAS_LIST.find(x => x.id === '4').value;
            }
            else if (this.questionA.toLowerCase() === "yes" && this.questionB.toLowerCase() === "no" && this.questionC.toLowerCase() === "yes" && this.questionD.toLowerCase() === "no") {
                message = this.RESPUESTAS_LIST.find(x => x.id === '2').value
            }
            else if (this.questionA.toLowerCase() === "yes" && this.questionB.toLowerCase() === "yes" && this.questionC.toLowerCase() === "no" && this.questionD.toLowerCase() === "yes") {
                message = this.RESPUESTAS_LIST.find(x => x.id === '3').value;
                message += '<br><br>' + this.RESPUESTAS_LIST.find(x => x.id === '4').value;
            }
            else if (this.questionA.toLowerCase() === "yes" && this.questionB.toLowerCase() === "yes" && this.questionC.toLowerCase() === "no" && this.questionD.toLowerCase() === "no") {
                message = this.RESPUESTAS_LIST.find(x => x.id === '3').value
            }
            else if (this.questionA.toLowerCase() === "yes" && this.questionB.toLowerCase() === "yes" && this.questionC.toLowerCase() === "yes" && this.questionD.toLowerCase() === "yes") {
                message = this.RESPUESTAS_LIST.find(x => x.id === '4').value
            }
            //Neutro
            if (this.questionA.toLowerCase() === "no" && this.questionB.toLowerCase() === "no" && this.questionC.toLowerCase() === "no" && this.questionD.toLowerCase() === "yes") {
                message = this.RESPUESTAS_LIST.find(x => x.id === '1').value;
                message += '<br><br>' + this.RESPUESTAS_LIST.find(x => x.id === '2').value;
                message += '<br><br>' + this.RESPUESTAS_LIST.find(x => x.id === '3').value;
                message += '<br><br>' + this.RESPUESTAS_LIST.find(x => x.id === '4').value;
            }
            else if (this.questionA.toLowerCase() === "no" && this.questionB.toLowerCase() === "no" && this.questionC.toLowerCase() === "no" && this.questionD.toLowerCase() === "no") {
                message = this.RESPUESTAS_LIST.find(x => x.id === '1').value;
                message += '<br><br>' + this.RESPUESTAS_LIST.find(x => x.id === '2').value;
                message += '<br><br>' + this.RESPUESTAS_LIST.find(x => x.id === '3').value;
            }
            else if (this.questionA.toLowerCase() === "no" && this.questionB.toLowerCase() === "no" && this.questionC.toLowerCase() === "yes" && this.questionD.toLowerCase() === "yes") {
                message = this.RESPUESTAS_LIST.find(x => x.id === '1').value;
                message += '<br><br>' + this.RESPUESTAS_LIST.find(x => x.id === '2').value;
                message += '<br><br>' + this.RESPUESTAS_LIST.find(x => x.id === '4').value;
            }
            else if (this.questionA.toLowerCase() === "no" && this.questionB.toLowerCase() === "no" && this.questionC.toLowerCase() === "yes" && this.questionD.toLowerCase() === "no") {
                message = this.RESPUESTAS_LIST.find(x => x.id === '1').value;
                message += '<br><br>' + this.RESPUESTAS_LIST.find(x => x.id === '2').value;
            }
            else if (this.questionA.toLowerCase() === "no" && this.questionB.toLowerCase() === "yes" && this.questionC.toLowerCase() === "no" && this.questionD.toLowerCase() === "yes") {
                message = this.RESPUESTAS_LIST.find(x => x.id === '1').value;
                message += '<br><br>' + this.RESPUESTAS_LIST.find(x => x.id === '3').value;
                message += '<br><br>' + this.RESPUESTAS_LIST.find(x => x.id === '4').value;
            }
            else if (this.questionA.toLowerCase() === "no" && this.questionB.toLowerCase() === "yes" && this.questionC.toLowerCase() === "no" && this.questionD.toLowerCase() === "no") {
                message = this.RESPUESTAS_LIST.find(x => x.id === '1').value;
                message += '<br><br>' + this.RESPUESTAS_LIST.find(x => x.id === '3').value;
            }
            else if (this.questionA.toLowerCase() === "no" && this.questionB.toLowerCase() === "yes" && this.questionC.toLowerCase() === "yes" && this.questionD.toLowerCase() === "yes") {
                message = this.RESPUESTAS_LIST.find(x => x.id === '1').value;
                message += '<br><br>' + this.RESPUESTAS_LIST.find(x => x.id === '4').value;
            }
            else if (this.questionA.toLowerCase() === "no" && this.questionB.toLowerCase() === "yes" && this.questionC.toLowerCase() === "yes" && this.questionD.toLowerCase() === "no") {
                message = this.RESPUESTAS_LIST.find(x => x.id === '1').value
            }

            message += '<br><br>' + this.RESPUESTAS_LIST.find(x => x.id === '5').value;

            UIkit.modal.alert('<h3>Les informamos que:</h3><br>' + message);
        }
    }

    onNextStep() {
        if (!this.formGroup.valid) {
            console.log('Please provide all the required values!')
            return false;
        } else {
            console.log(this.formGroup.value)
        }
    }

    onSubmit(post) {
        this.post = post;
    }
}
