<section id="claim" class="claim-area uk-claim uk-section uk-section-area">
  <div class="uk-container">
    <div class="uk-section-title section-title uk-align-center">
      <span>{{ subTitle }}</span>
      <h2>{{ title }}</h2>
    </div>
  </div>
</section>
<section
  class="services-details-area uk-services-details uk-section"
  style="padding-top: 10px"
>
  <div class="uk-container">
    <p class="mat-error uk-margin-left-15">* Campo obligatorio</p>
    <form
      novalidate
      focusInvalidInput
      class="bg-card shadow rounded overflow-hidden uk-grid-small"
      [formGroup]="formGroup"
      (ngSubmit)="onSubmit($event)"
      (closeModalEvent)="onClose($event)"
      #f="ngForm"
      id="form"
    >
      <fieldset class="uk-fieldset">
        <ol type="I" start="1" class="form-list">
          <li>
            <div class="uk-margin step1">
              <h3>Datos personales del reclamante</h3>
              <strong>{{ f.value.firstname }} </strong>
              <div>
                <mat-form-field class="uk-width-1-4@s" appearance="outline">
                  <mat-label>¿Qué tipo de persona eres?</mat-label>
                  <mat-select
                    [formControlName]="'tipoPersona'"
                    [placeholder]="'¿Qué tipo de persona eres?'"
                    (selectionChange)="changePersonType($event, true)"
                    required
                  >
                    <mat-option
                      *ngFor="let persona of tipoPersona"
                      [value]="persona.id"
                    >
                      {{ persona.name }}</mat-option
                    >
                  </mat-select>
                  <mat-icon matPrefix>search</mat-icon>
                  <mat-error *ngIf="!formGroup.controls['tipoPersona'].valid">
                    Campo obligatorio
                  </mat-error>
                </mat-form-field>

                <mat-form-field class="uk-width-1-3@s" appearance="outline">
                  <mat-label>¿Cuál es tu documento de indentidad?</mat-label>
                  <mat-select
                    [formControlName]="'documentTypes'"
                    [placeholder]="'¿Cuál es tu documento de indentidad?'"
                    (selectionChange)="changetipoDocumento($event)"
                    required
                  >
                    <mat-option
                      *ngFor="let itemDocumentType of documentTypes"
                      [value]="itemDocumentType.id"
                    >
                      {{ itemDocumentType.name }}
                    </mat-option>
                  </mat-select>
                  <mat-icon matPrefix>search</mat-icon>
                  <mat-error *ngIf="!formGroup.controls['documentTypes'].valid">
                    Campo obligatorio
                  </mat-error>
                </mat-form-field>

                <mat-form-field class="uk-width-1-3@s" appearance="outline">
                  <mat-label>Número de documento</mat-label>
                  <input
                    matInput
                    type="text"
                    name="numeroDocumento"
                    id="numeroDocumento"
                    placeholder="Ingresa tu número de documento"
                    formControlName="numeroDocumento"
                    [minlength]="controlConfig.documentNumber.minLength"
                    [maxlength]="controlConfig.documentNumber.maxLength"
                    [ngClass]="{
                      'uk-form-danger-custom':
                        requerido && isMarkedNumeroDocumento
                    }"
                    appInputPattern
                    [customPatter]="controlConfig.documentNumber.pattern"
                    (keyup)="keyUp('numeroDocumento', $event)"
                  />

                  <mat-error
                    *ngIf="
                      formGroup.controls['numeroDocumento'].invalid &&
                      (formGroup.controls['numeroDocumento'].dirty ||
                        formGroup.controls['numeroDocumento'].touched)
                    "
                  >
                    <label
                      class="uk-text-small uk-text-danger"
                      *ngIf="formGroup.controls['numeroDocumento'].invalid"
                    >
                      Campo obligatorio.
                    </label>

                    <label
                      class="uk-text-small uk-text-danger"
                      *ngIf="
                        formGroup.controls['numeroDocumento'].errors[
                          'minlength'
                        ]
                      "
                    >
                      {{
                        "Debería tener " +
                          controlConfig.documentNumber.minLength +
                          " como mínimo."
                      }}
                    </label>
                  </mat-error>

                  <mat-icon matPrefix>create</mat-icon>
                </mat-form-field>
              </div>
              <div>
                <mat-form-field
                  class="uk-width-1-2@s"
                  *ngIf="formGroup.get('tipoPersona')?.value == 2"
                  appearance="outline"
                >
                  <input
                    matInput
                    name="razonsocial"
                    id="razonsocial"
                    placeholder="Razón Social"
                    formControlName="razonSocial"
                    [ngClass]="{
                      'uk-form-danger-custom': requerido && isMarkedRazonSocial
                    }"
                    (keyup)="keyUp('razonSocial', $event)"
                    maxlength="50"
                    required
                    style="text-transform: uppercase"
                  />
                  <mat-error *ngIf="!formGroup.controls['razonSocial'].valid">
                    Campo obligatorio.
                  </mat-error>
                  <mat-icon matPrefix>domain</mat-icon>
                </mat-form-field>

                <mat-radio-group
                  [formControlName]="'obligated'"
                  name="obligated"
                  *ngIf="formGroup.get('tipoPersona')?.value !== 1"
                  (change)="changeObligated($event)"
                >
                  <mat-label>¿Está obligado a declarar renta? </mat-label>
                  <mat-radio-button [value]="'1'">Sí</mat-radio-button>
                  <mat-radio-button [value]="'0'">No</mat-radio-button>
                </mat-radio-group>

                <mat-form-field
                  class="uk-width-1-4@s"
                  *ngIf="formGroup.get('tipoPersona')?.value !== 2"
                  appearance="outline"
                >
                  <mat-label>Nombres</mat-label>
                  <input
                    matInput
                    [formControlName]="'nombres'"
                    [placeholder]="'Ingresa tu nombres'"
                    maxlength="50"
                    style="text-transform: uppercase"
                  />
                  <mat-error *ngIf="!formGroup.controls['nombres'].valid">
                    Campo obligatorio.
                  </mat-error>
                  <mat-icon matPrefix>account_circle</mat-icon>
                </mat-form-field>

                <mat-form-field
                  class="uk-width-1-3@s"
                  *ngIf="formGroup.get('tipoPersona')?.value !== 2"
                  appearance="outline"
                >
                  <mat-label>Apellido Paterno</mat-label>
                  <input
                    matInput
                    [formControlName]="'apellidoPaterno'"
                    [placeholder]="'Ingresa tu apellido paterno'"
                    maxlength="50"
                    required
                    style="text-transform: uppercase"
                  />
                  <mat-error
                    *ngIf="!formGroup.controls['apellidoPaterno'].valid"
                  >
                    Campo obligatorio.
                  </mat-error>
                  <mat-icon matPrefix>account_circle</mat-icon>
                </mat-form-field>
                <mat-form-field
                  class="uk-width-1-3@s"
                  *ngIf="formGroup.get('tipoPersona')?.value !== 2"
                  appearance="outline"
                >
                  <mat-label>Apellido Materno</mat-label>
                  <input
                    matInput
                    [formControlName]="'apellidoMaterno'"
                    [placeholder]="'Ingresa tu apellido materno'"
                    maxlength="50"
                    required
                    style="text-transform: uppercase"
                  />
                  <mat-error
                    *ngIf="!formGroup.controls['apellidoMaterno'].valid"
                  >
                    Campo obligatorio.
                  </mat-error>
                  <mat-error
                    *ngIf="
                      formGroup.controls['apellidoMaterno'].hasError(
                        'minLength'
                      )
                    "
                  >
                    Use 6 or more characters with a mix of letters
                  </mat-error>
                  <mat-icon matPrefix>account_circle</mat-icon>
                </mat-form-field>
              </div>
              <div>
                <mat-form-field class="uk-width-1-4@s" appearance="outline">
                  <mat-label>Celular</mat-label>
                  <input
                    matInput
                    [formControlName]="'celular'"
                    [placeholder]="'Ingresa tu celular'"
                    minlength="9"
                    maxlength="9"
                    required
                  />
                  <mat-icon matPrefix>phone_iphone</mat-icon>
                  <mat-error *ngIf="!formGroup.controls['celular'].valid">
                    Campo obligatorio
                  </mat-error>
                </mat-form-field>

                <mat-form-field class="uk-width-1-3@s" appearance="outline">
                  <mat-label>Email</mat-label>
                  <input
                    matInput
                    [formControlName]="'email'"
                    [placeholder]="'Ingresa tu email'"
                    maxlength="50"
                    required
                    (input)="openSuggestions($event)"
                    (blur)="closeSuggestions()"
                    (focusout)="closeSuggestions()"
                    (keyup)="onKeyUp($event)"
                  />
                  <mat-icon matPrefix>alternate_email</mat-icon>
                  <email-circle-outline-icon
                    matPrefix
                  ></email-circle-outline-icon>
                  <mat-error *ngIf="!formGroup.controls['email'].valid">
                    Campo obligatorio
                  </mat-error>
                  <!-- <mat-error *ngIf="formGroup.controls['email'].invalid">{{getErrorMessage()}}</mat-error> -->
                  <mat-error
                    *ngIf="
                      formGroup.controls['email'].touched &&
                      formGroup.controls['email'].errors?.email
                    "
                  >
                    Introduzca una dirección de correo electrónico válida
                  </mat-error>
                  <ul
                    class="suggestions suggestions-emergent"
                    *ngIf="showSuggestionsList"
                  >
                    <li
                      *ngFor="let suggestion of suggestions"
                      (click)="selectSuggestion(suggestion)"
                    >
                      {{ suggestion }}
                    </li>
                  </ul>
                </mat-form-field>

                <mat-form-field class="uk-width-1-3@s" appearance="outline">
                  <mat-label>Entidad Reclamada</mat-label>
                  <mat-select
                    [formControlName]="'entidadFinanciera'"
                    [placeholder]="'Entidad Reclamada'"
                    required
                  >
                    <mat-option
                      *ngFor="let entidad of entidadFinanciera"
                      [value]="entidad.id"
                    >
                      <span *ngIf="entidad.brandName !== ''">{{
                        entidad.brandName
                      }}</span>
                      <span *ngIf="entidad.brandName === ''">{{
                        entidad.name
                      }}</span>
                    </mat-option>
                  </mat-select>
                  <mat-icon matPrefix>domain</mat-icon>
                  <mat-error
                    *ngIf="!formGroup.controls['entidadFinanciera'].valid"
                  >
                    Campo obligatorio
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
          </li>
          <li>
            <div class="uk-margin step2">
              <h3>
                Descripción de los hechos y argumentos que sustentan el reclamo
              </h3>
              <p>
                (*) Detalla los hechos ocurridos, indicando fechas, importes,
                operaciones y/o consumos, entre otros.
              </p>
              <div class="uk-margin">
                <mat-form-field class="uk-width-2-3@m" appearance="outline">
                  <mat-label>Ingresa la descripción de tu reclamo</mat-label>
                  <textarea
                    matInput
                    placeholder="Ingresar la descripción del reclamo"
                    formControlName="descriptionHechos"
                    matTextareaAutosize
                    required
                  ></textarea>
                  <mat-icon matPrefix> notes</mat-icon>
                  <mat-error
                    *ngIf="!formGroup.controls['descriptionHechos'].valid"
                  >
                    Campo obligatorio, debe tener entre 10 a 5000 caracteres.
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
          </li>
          <li>
            <div class="uk-margin step3">
              <h3>Solución que espera obtener de la entidad financiera</h3>

              <p>(*) Ingrese el importe total de su reclamo</p>

              <div formArrayName="amounts">
                <div
                  *ngFor="
                    let item of amountsItems.controls;
                    let pointIndex = index
                  "
                  [formGroupName]="pointIndex"
                >
                  <mat-form-field class="uk-width-1-5@s" appearance="outline">
                    <mat-label>Moneda</mat-label>
                    <mat-select
                      [formControlName]="'currencyType'"
                      (selectionChange)="changeCurrencyType($event, pointIndex)"
                      [placeholder]="'Moneda'"
                      required
                    >
                      <mat-option
                        *ngFor="let itemCurrency of currencyType"
                        [value]="itemCurrency.id"
                      >
                        {{ itemCurrency.name }}
                      </mat-option>
                    </mat-select>

                    <mat-icon matPrefix> money</mat-icon>
                  </mat-form-field>

                  <mat-form-field class="uk-width-1-3@s" appearance="outline">
                    <mat-label>Importe del reclamo</mat-label>
                    <input
                      matInput
                      placeholder="Ingrese el importe (ej. 1000000.00)"
                      [formControlName]="'amount'"
                      [maxlength]="20"
                      required
                    />
                    <mat-icon
                      class="icon-size-5"
                      uk-icon="icon: pencil"
                      matPrefix
                    ></mat-icon>
                    <!-- <mat-error *ngIf="!formGroup.controls['amount'].valid"> Campo obligatorio.
                                        </mat-error> -->
                  </mat-form-field>

                  <button
                    type="button"
                    class="uk-button uk-button-default uk-margin-small-bottom"
                    (click)="deleteSellingPoint(pointIndex)"
                  >
                    <mat-icon class="color-white">clear</mat-icon>
                    Quitar
                  </button>
                </div>
              </div>
              <button
                type="button"
                class="uk-button uk-button-default uk-margin-small-bottom"
                (click)="addSellingPoint()"
              >
                <mat-icon class="color-white">add</mat-icon>
                Agregar otro importe
              </button>

              <br />
              <br />

              <p>
                (*) Ingrese la solución que espera del reclamo. (Ej. monto a ser
                devuelto por la entidad financiera)
              </p>
              <mat-form-field class="uk-width-2-3@m" appearance="outline">
                <mat-label
                  >Ingrese la solución que espera del reclamo</mat-label
                >
                <textarea
                  matInput
                  placeholder="Ingrese aquí la solución que espera del reclamo"
                  matTextareaAutosize
                  cdkAutosizeMinRows="2"
                  cdkAutosizeMinRows="5"
                  required
                  formControlName="descriptionSolucion"
                ></textarea>
                <mat-error
                  *ngIf="!formGroup.controls['descriptionSolucion'].valid"
                >
                  Campo obligatorio, debe tener entre 10 a 5000 caracteres.
                </mat-error>
                <menu-alt-3-outline-icon matPrefix></menu-alt-3-outline-icon>
              </mat-form-field>
            </div>
          </li>
          <li>
            <div class="uk-margin step4">
              <h3>Adjuntar documentos obligatorios</h3>
              <p>
                Recuerda que debes subir un archivo por cada documento. El
                tamaño max. permitido es de 30 MB por archivo.
              </p>

              <div class="subir-archivos" id="PersonaNatural">
                <div id="files-DNI">
                  <label
                    class="uk-button uk-button-default uk-width-1-3@m uk-margin-small-bottom text-align-left"
                    (click)="fileInput1.value = null"
                  >
                    <mat-icon class="color-white"> attach_file</mat-icon>
                    <span *ngIf="formGroup.get('tipoPersona')?.value !== 2"
                      >Copia DNI,CE o Pasaporte</span
                    >
                    <span *ngIf="formGroup.get('tipoPersona')?.value !== 1"
                      >Copia DNI,CE o Pasaporte de Representante Legal</span
                    >*
                    <input
                      #fileInput1
                      type="file"
                      style="display: none"
                      (change)="chooseFiles(fileInput1.files, 'DNI')"
                      accept=".pdf,.doc,.docx,.jpg,.jpeg,.png,.tiff,.gif"
                      multiple
                    />
                  </label>

                  <div *ngIf="selectedDNI && selectedDNI.length">
                    <div
                      *ngFor="let item of selectedDNI"
                      class="files-content bg-light text-primary uk-width-1-2"
                    >
                      <div><b>Nombre:</b> {{ item.name }}</div>
                      <div><b>Tamaño:</b> {{ humanFileSize(item.size) }}</div>
                      <mat-icon
                        class="color-red"
                        (click)="removeFile(item, 'DNI')"
                        >remove_circle_outline</mat-icon
                      >
                    </div>
                    <div *ngIf="uploading" class="mt-2">
                      <ngb-progressbar
                        type="success"
                        showValue="true"
                        [value]="uploadProgress"
                      >
                      </ngb-progressbar>
                    </div>
                  </div>

                  <div
                    *ngIf="submissionResults && submissionResults.length"
                    class="text-success"
                  >
                    <p class="font-weight-bold mb-0">Archivo subido:</p>
                    <pre>{{ submissionResults | json }}</pre>
                  </div>
                </div>

                <div
                  id="files-VigenciaPoder"
                  *ngIf="formGroup.get('tipoPersona')?.value !== 1"
                >
                  <label
                    class="uk-button uk-button-default uk-width-1-3 uk-margin-small-bottom text-align-left"
                    (click)="fileInput4.value = null"
                  >
                    <mat-icon class="color-white"> attach_file</mat-icon>
                    Vigencia Poder Representante Legal*
                    <input
                      #fileInput4
                      type="file"
                      style="display: none"
                      (change)="chooseFiles(fileInput4.files, 'VigenciaPoder')"
                      accept=".pdf,.doc,.docx,.jpg,.jpeg,.png,.tiff,.gif"
                      multiple
                    />
                  </label>

                  <div
                    *ngIf="
                      selectedVigenciaPoder && selectedVigenciaPoder.length
                    "
                  >
                    <div
                      *ngFor="let item of selectedVigenciaPoder"
                      class="files-content bg-light text-primary uk-width-1-2"
                    >
                      <div><b>Nombre:</b> {{ item.name }}</div>
                      <div><b>Tamaño:</b> {{ humanFileSize(item.size) }}</div>
                      <mat-icon
                        class="color-red"
                        (click)="removeFile(item, 'VigenciaPoder')"
                      >
                        remove_circle_outline
                      </mat-icon>
                    </div>
                    <div *ngIf="uploading">
                      <ngb-progressbar
                        type="success"
                        showValue="true"
                        [value]="uploadProgress"
                      >
                      </ngb-progressbar>
                    </div>
                  </div>

                  <div
                    *ngIf="submissionResults && submissionResults.length"
                    class="text-success"
                  >
                    <p class="font-weight-bold mb-0">Archivo subido:</p>
                    <pre>{{ submissionResults | json }}</pre>
                  </div>
                </div>

                <div
                  id="files-DeclaracionAnual"
                  *ngIf="
                    formGroup.get('tipoPersona')?.value !== 1 && obligated == 1
                  "
                >
                  <label
                    class="uk-button uk-button-default uk-width-1-3 uk-margin-small-bottom text-align-left"
                    (click)="fileInput5.value = null"
                  >
                    <mat-icon class="color-white"> attach_file</mat-icon>
                    Declaracion Anual Imp. Renta*
                    <input
                      #fileInput5
                      type="file"
                      style="display: none"
                      (change)="
                        chooseFiles(fileInput5.files, 'DeclaracionAnual')
                      "
                      accept=".pdf,.doc,.docx,.jpg,.jpeg,.png,.tiff,.gif"
                      multiple
                    />
                  </label>

                  <div
                    *ngIf="
                      selectedDeclaracionAnual &&
                      selectedDeclaracionAnual.length
                    "
                  >
                    <div
                      *ngFor="let item of selectedDeclaracionAnual"
                      class="files-content bg-light text-primary uk-width-1-2"
                    >
                      <div><b>Nombre:</b> {{ item.name }}</div>
                      <div><b>Tamaño:</b> {{ humanFileSize(item.size) }}</div>
                      <mat-icon
                        class="color-red"
                        (click)="removeFile(item, 'DeclaracionAnual')"
                      >
                        remove_circle_outline
                      </mat-icon>
                    </div>
                    <div *ngIf="uploading">
                      <ngb-progressbar
                        type="success"
                        showValue="true"
                        [value]="uploadProgress"
                      >
                      </ngb-progressbar>
                    </div>
                  </div>

                  <div
                    *ngIf="submissionResults && submissionResults.length"
                    class="text-success"
                  >
                    <p class="font-weight-bold mb-0">Archivo subido:</p>
                    <pre>{{ submissionResults | json }}</pre>
                  </div>
                </div>

                <div id="files-BankClaimResponse">
                  <label
                    class="uk-button uk-button-default uk-width-1-3@m uk-margin-small-bottom text-align-left"
                    (click)="fileInput2.value = null"
                  >
                    <mat-icon class="color-white"> attach_file</mat-icon>
                    Respuesta del Banco a su Reclamo
                    <input
                      #fileInput2
                      type="file"
                      style="display: none"
                      (change)="
                        chooseFiles(fileInput2.files, 'BankClaimResponse')
                      "
                      accept=".pdf,.doc,.docx,.jpg,.jpeg,.png,.tiff,.gif"
                      multiple
                    />
                  </label>

                  <div
                    *ngIf="
                      selectedBankClaimResponse &&
                      selectedBankClaimResponse.length
                    "
                  >
                    <div
                      *ngFor="let item of selectedBankClaimResponse"
                      class="files-content bg-light text-primary uk-width-1-2"
                    >
                      <div><b>Nombre:</b> {{ item.name }}</div>
                      <div><b>Tamaño:</b> {{ humanFileSize(item.size) }}</div>
                      <mat-icon
                        class="color-red"
                        (click)="removeFile(item, 'BankClaimResponse')"
                      >
                        remove_circle_outline
                      </mat-icon>
                    </div>
                    <div *ngIf="uploading">
                      <ngb-progressbar
                        type="success"
                        showValue="true"
                        [value]="uploadProgress"
                      >
                      </ngb-progressbar>
                    </div>
                  </div>

                  <div
                    *ngIf="submissionResults && submissionResults.length"
                    class="text-success"
                  >
                    <p class="font-weight-bold mb-0">Archivo subido:</p>
                    <pre>{{ submissionResults | json }}</pre>
                  </div>
                </div>

                <div id="files-ClaimSheet">
                  <label
                    class="uk-button uk-button-default uk-width-1-3@m uk-margin-small-bottom text-align-left"
                    (click)="fileInput3.value = null"
                  >
                    <mat-icon class="color-white"> attach_file</mat-icon>
                    Constancia u Hoja de Reclamación
                    <input
                      #fileInput3
                      type="file"
                      style="display: none"
                      (change)="chooseFiles(fileInput3.files, 'ClaimSheet')"
                      accept=".pdf,.doc,.docx,.jpg,.jpeg,.png,.tiff,.gif"
                      multiple
                    />
                  </label>

                  <div *ngIf="selectedClaimSheet && selectedClaimSheet.length">
                    <div
                      *ngFor="let item of selectedClaimSheet"
                      class="files-content bg-light text-primary uk-width-1-2"
                    >
                      <div><b>Nombre:</b> {{ item.name }}</div>
                      <div><b>Tamaño:</b> {{ humanFileSize(item.size) }}</div>
                      <mat-icon
                        class="color-red"
                        (click)="removeFile(item, 'ClaimSheet')"
                        >remove_circle_outline
                      </mat-icon>
                    </div>
                    <div *ngIf="uploading">
                      <ngb-progressbar
                        type="success"
                        showValue="true"
                        [value]="uploadProgress"
                      >
                      </ngb-progressbar>
                    </div>
                  </div>

                  <div
                    *ngIf="submissionResults && submissionResults.length"
                    class="text-success"
                  >
                    <p class="font-weight-bold mb-0">Archivo subido:</p>
                    <pre>{{ submissionResults | json }}</pre>
                  </div>
                </div>
              </div>

              <mat-error *ngIf="errorMsg">
                {{ errorMsg }}
              </mat-error>
            </div>
          </li>
          <li>
            <div class="uk-margin step5">
              <h3>Adjuntar otros documentos de sustento de tu reclamo</h3>
              <p>
                Puedes añadir todos los documentos que deseas acompañar con tu
                reclamo. El tamaño max. permitido es de 30 MB por archivo.
              </p>

              <div id="files-DocumentosSustento">
                <label
                  class="uk-button uk-button-default uk-width-1-3@m uk-margin-small-bottom text-align-left"
                  (click)="fileInput6.value = null"
                >
                  <mat-icon class="color-white"> attach_file</mat-icon>
                  Documentos complementarios
                  <input
                    #fileInput6
                    type="file"
                    style="display: none"
                    (change)="
                      chooseFiles(fileInput6.files, 'SupportingDocuments')
                    "
                    accept=".pdf,.doc,.docx,.jpg,.jpeg,.png,.tiff,.gif"
                    multiple
                  />
                </label>

                <div
                  *ngIf="
                    selectedSupportingDocuments &&
                    selectedSupportingDocuments.length
                  "
                >
                  <div
                    *ngFor="let item of selectedSupportingDocuments"
                    class="files-content bg-light text-primary uk-width-1-2"
                  >
                    <div><b>Nombre:</b> {{ item.name }}</div>
                    <div><b>Tamaño:</b> {{ humanFileSize(item.size) }}</div>
                    <mat-icon
                      class="color-red"
                      (click)="removeFile(item, 'SupportingDocuments')"
                      >remove_circle_outline
                    </mat-icon>
                  </div>
                  <div *ngIf="uploading" class="mt-2">
                    <ngb-progressbar
                      type="success"
                      showValue="true"
                      [value]="uploadProgress"
                    >
                    </ngb-progressbar>
                  </div>
                </div>

                <div
                  *ngIf="submissionResults && submissionResults.length"
                  class="text-success"
                >
                  <p class="font-weight-bold mb-0">Archivo subido:</p>
                  <pre>{{ submissionResults | json }}</pre>
                </div>
              </div>

              <br />
            </div>
          </li>
          <li>
            <div class="uk-margin step6">
              <h3>¿Cómo te enteraste de la Defensoría?</h3>
              <div>
                <mat-form-field class="uk-width-1-3@m" appearance="outline">
                  <mat-label>Selecciona</mat-label>
                  <mat-select
                    [formControlName]="'codigoListaDefensoria'"
                    [placeholder]="'Selecciona'"
                    required
                  >
                    <mat-option
                      *ngFor="let item of codigoListaDefensoria"
                      [value]="item.id"
                    >
                      {{ item.name }}
                    </mat-option>
                  </mat-select>

                  <mat-icon matPrefix> search</mat-icon>
                </mat-form-field>
              </div>
            </div>
          </li>
        </ol>
      </fieldset>
      <div class="uk-margin step-buttons">
        <div>
          <div class="uk-width-1-1 terms">
            <mat-checkbox
              class="uk-width-1-1"
              [color]="'primary'"
              [formControlName]="'aceptoTerminos'"
              required
            >
              <a routerLink="/terminos-y-condiciones" target="_blank"
                >Acepto términos y condiciones del uso del servicio de
                reclamos.</a
              >
            </mat-checkbox>

            <mat-error
              *ngIf="submitted && !formGroup.controls['aceptoTerminos'].valid"
            >
              Se requiere aceptar los términos y condiciones.
            </mat-error>
          </div>
          <div class="uk-width-1-1 uk-margin terms">
            <mat-checkbox
              class="uk-width-1-1"
              [color]="'primary'"
              [formControlName]="'leidoTerminos'"
              required
            >
              <a routerLink="/terminos-de-datos-personales" target="_blank"
                >He leído y acepto los términos de uso de datos personales.</a
              >
            </mat-checkbox>
            <mat-error
              *ngIf="submitted && !formGroup.controls['leidoTerminos'].valid"
            >
              Se requiere aceptar los términos de uso.
            </mat-error>
          </div>

          <button
            class="uk-button uk-button-text uk-width-1-4@m uk-margin-small-bottom uk-margin uk-margin-right-10"
            type="button"
            routerLink="/cuestionario-de-reclamos"
          >
            <mat-icon>navigate_before</mat-icon>
            <span>Volver</span>
          </button>

          <button
            id="btnSubmit"
            class="uk-button uk-button-primary uk-width-1-4@m uk-margin-small-bottom uk-margin"
            type="submit"
            color="primary"
            [disabled]="uploading"
          >
            <mat-icon class="color-white">send</mat-icon>

            <span *ngIf="!uploading"> Enviar reclamo</span>

            <span *ngIf="uploading"> Enviando <loading-bar></loading-bar></span>
          </button>
        </div>
      </div>
    </form>

    <div class="uk-alert-primary uk-margin" uk-alert>
      <a class="uk-alert-close" uk-close></a>
      <b
        >Aviso: Si tienes dificultades para completar el formulario, comunícate
        a (01) 224-1457 o 0-800-1-6777 para ayudarte.</b
      >
    </div>
  </div>
</section>
