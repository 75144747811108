<section id="claim" class="claim-area uk-claim uk-section uk-section-area">
  <div class="uk-container">
    <div class="uk-section-title section-title uk-align-center">
      <span>{{ subTitle }}</span>
      <h2>{{ title }}</h2>
    </div>
  </div>
</section>
<section
  class="services-details-area uk-services-details uk-section"
  style="padding-top: 10px"
>
  <div class="uk-container">
    <p class="mat-error uk-margin-left-15">* Campo obligatorio</p>
    <form
      novalidate
      focusInvalidInput
      class="bg-card shadow rounded overflow-hidden uk-grid-small uk-form-dcf"
      [formGroup]="formGroup"
      (ngSubmit)="onSubmit()"
      (closeModalEvent)="onClose($event)"
      #f="ngForm"
      id="form"
    >
      <fieldset class="uk-fieldset">
        <div class="uk-margin step1">
          <strong>{{ f.value.firstname }} </strong>
          <div>
            <mat-form-field class="uk-width-1-4@s" appearance="outline">
              <mat-label>¿Qué tipo de persona eres?</mat-label>
              <mat-select
                [formControlName]="'tipoPersona'"
                [placeholder]="'¿Qué tipo de persona eres?'"
                (selectionChange)="changePersonType($event, true)"
                required
              >
                <mat-option
                  *ngFor="let persona of tipoPersona"
                  [value]="persona.id"
                >
                  {{ persona.name }}</mat-option
                >
              </mat-select>
              <mat-icon matPrefix>search</mat-icon>
              <mat-error *ngIf="!formGroup.controls['tipoPersona'].valid">
                Campo obligatorio
              </mat-error>
            </mat-form-field>

            <mat-form-field class="uk-width-1-3@s" appearance="outline">
              <mat-label>¿Cuál es tu documento de indentidad?</mat-label>
              <mat-select
                [formControlName]="'tipoDocumento'"
                [placeholder]="'¿Cuál es tu documento de indentidad?'"
                (selectionChange)="changetipoDocumento($event)"
                required
              >
                <mat-option
                  *ngFor="let itemDocumentType of tipoDocumento"
                  [value]="itemDocumentType.id"
                >
                  {{ itemDocumentType.name }}
                </mat-option>
              </mat-select>
              <mat-icon matPrefix>search</mat-icon>
              <mat-error *ngIf="!formGroup.controls['tipoDocumento'].valid">
                Campo obligatorio
              </mat-error>
            </mat-form-field>

            <mat-form-field class="uk-width-1-3@s" appearance="outline">
              <mat-label>Número de documento</mat-label>
              <input
                matInput
                type="text"
                name="numeroDocumento"
                id="numeroDocumento"
                placeholder="Ingresa tu número de documento"
                formControlName="numeroDocumento"
                [minlength]="controlConfig.documentNumber.minLength"
                [maxlength]="controlConfig.documentNumber.maxLength"
                [ngClass]="{
                  'uk-form-danger-custom': requerido && isMarkedNumeroDocumento
                }"
                appInputPattern
                [customPatter]="controlConfig.documentNumber.pattern"
                (keyup)="keyUp('numeroDocumento', $event)"
                required
              />

              <mat-error
                *ngIf="formGroup.controls['numeroDocumento'].errors?.required"
              >
                Campo obligatorio.
              </mat-error>

              <mat-error
                *ngIf="
                  formGroup.controls['numeroDocumento'].invalid &&
                  (formGroup.controls['numeroDocumento'].dirty ||
                    formGroup.controls['numeroDocumento'].touched)
                "
              >
                <label
                  class="uk-text-danger"
                  *ngIf="
                    formGroup.controls['numeroDocumento'].errors['minlength']
                  "
                >
                  {{
                    "Debería tener " +
                      controlConfig.documentNumber.minLength +
                      " caracteres como máximo."
                  }}
                </label>
              </mat-error>

              <mat-icon matPrefix>create</mat-icon>
            </mat-form-field>
          </div>
          <div>
            <mat-form-field
              class="uk-width-1-4@s"
              *ngIf="formGroup.get('tipoPersona')?.value == 2"
              appearance="outline"
            >
              <input
                matInput
                name="razonsocial"
                id="razonsocial"
                placeholder="Razón Social"
                formControlName="razonSocial"
                [ngClass]="{
                  'uk-form-danger-custom': requerido && isMarkedRazonSocial
                }"
                (keyup)="keyUp('razonsocial', $event)"
                maxlength="50"
                required
                style="text-transform: uppercase"
              />
              <mat-error *ngIf="!formGroup.controls['razonSocial'].valid">
                Campo obligatorio.
              </mat-error>
              <mat-icon matPrefix>domain</mat-icon>
            </mat-form-field>

            <mat-form-field
              class="uk-width-1-3@s"
              *ngIf="formGroup.get('tipoPersona')?.value == 2"
              appearance="outline"
            >
              <input
                matInput
                name="nombreRepresentanteLegal"
                id="nombreRepresentanteLegal"
                placeholder="Representante Legal"
                formControlName="nombreRepresentanteLegal"
                (keyup)="keyUp('nombreRepresentanteLegal', $event)"
                maxlength="50"
              />
              <mat-icon matPrefix>create</mat-icon>
            </mat-form-field>

            <mat-form-field
              class="uk-width-1-3@s"
              *ngIf="formGroup.get('tipoPersona')?.value == 2"
              appearance="outline"
            >
              <input
                matInput
                name="dniRepresentanteLegal"
                id="dniRepresentanteLegal"
                placeholder="DNI del representante legal"
                formControlName="dniRepresentanteLegal"
                (keyup)="keyUp('dniRepresentanteLegal', $event)"
                maxlength="8"
              />
              <mat-icon matPrefix>create</mat-icon>
            </mat-form-field>

            <mat-form-field
              class="uk-width-1-4@s"
              *ngIf="formGroup.get('tipoPersona')?.value !== 2"
              appearance="outline"
            >
              <mat-label>Nombres</mat-label>
              <input
                matInput
                [formControlName]="'nombres'"
                [placeholder]="'Ingresa tu nombres'"
                maxlength="50"
                required
                style="text-transform: uppercase"
              />

              <mat-error *ngIf="formGroup.controls['nombres'].errors?.required">
                Campo obligatorio.
              </mat-error>

              <mat-icon matPrefix>account_circle</mat-icon>
            </mat-form-field>

            <mat-form-field
              class="uk-width-1-3@s"
              *ngIf="formGroup.get('tipoPersona')?.value !== 2"
              appearance="outline"
            >
              <mat-label>Apellido Paterno</mat-label>
              <input
                matInput
                [formControlName]="'apellidoPaterno'"
                [placeholder]="'Ingresa tu apellido paterno'"
                maxlength="50"
                required
                style="text-transform: uppercase"
              />
              <mat-error
                *ngIf="formGroup.controls['apellidoPaterno'].errors?.required"
              >
                Campo obligatorio.
              </mat-error>
              <mat-icon matPrefix>account_circle</mat-icon>
            </mat-form-field>

            <mat-form-field
              class="uk-width-1-3@s"
              *ngIf="formGroup.get('tipoPersona')?.value !== 2"
              appearance="outline"
            >
              <mat-label>Apellido Materno</mat-label>
              <input
                matInput
                [formControlName]="'apellidoMaterno'"
                [placeholder]="'Ingresa tu apellido materno'"
                maxlength="50"
                required
                style="text-transform: uppercase"
              />
              <mat-error
                *ngIf="formGroup.controls['apellidoMaterno'].errors?.required"
              >
                Campo obligatorio.
              </mat-error>

              <mat-error
                *ngIf="
                  formGroup.controls['apellidoMaterno'].hasError('minLength')
                "
              >
                Use 6 o más caracteres con una combinación de letras
              </mat-error>
              <mat-icon matPrefix>account_circle</mat-icon>
            </mat-form-field>
          </div>
          <div>
            <mat-form-field class="uk-width-1-4@s" appearance="outline">
              <mat-label>Celular</mat-label>
              <input
                matInput
                [formControlName]="'celular'"
                [placeholder]="'Ingresa tu celular'"
                minlength="9"
                maxlength="9"
                required
                [ngClass]="{
                  'uk-form-danger-custom': requerido && isMarkedNumeroDocumento
                }"
                appInputPattern
                [customPatter]="controlConfig.documentNumber.pattern"
                (keyup)="keyUp('celular', $event)"
              />
              <mat-icon matPrefix>phone_iphone</mat-icon>
              <mat-error *ngIf="formGroup.controls['celular'].errors?.required">
                Campo obligatorio.
              </mat-error>
            </mat-form-field>

            <mat-form-field class="uk-width-1-3@s" appearance="outline">
              <mat-label>Email</mat-label>
              <input
                matInput
                [formControlName]="'email'"
                [placeholder]="'Ingresa tu email'"
                maxlength="50"
                required
                (input)="openSuggestions($event)"
                (blur)="closeSuggestions()"
                (focusout)="closeSuggestions()"
                (keyup)="onKeyUp($event)"
              />
              <mat-icon matPrefix>alternate_email</mat-icon>
              <email-circle-outline-icon matPrefix></email-circle-outline-icon>

              <mat-error *ngIf="formGroup.controls['email'].errors?.required">
                Campo obligatorio.
              </mat-error>

              <mat-error
                *ngIf="
                  formGroup.controls['email'].touched &&
                  formGroup.controls['email'].errors?.email
                "
              >
                Introduzca una dirección de correo electrónico válida
              </mat-error>
              <ul
                class="suggestions suggestions-emergent"
                *ngIf="showSuggestionsList"
              >
                <li
                  *ngFor="let suggestion of suggestions"
                  (click)="selectSuggestion(suggestion)"
                >
                  {{ suggestion }}
                </li>
              </ul>
            </mat-form-field>

            <mat-form-field class="uk-width-1-3@s" appearance="outline">
              <mat-label>Tipo de Consulta</mat-label>
              <mat-select
                [formControlName]="'tipoConsulta'"
                [placeholder]="'Tipo de Consulta'"
              >
                <mat-option
                  *ngFor="let consulta of tipoConsulta"
                  [value]="consulta.id"
                >
                  {{ consulta.name }}
                </mat-option>
              </mat-select>
              <mat-icon matPrefix>search</mat-icon>
              <mat-error *ngIf="!formGroup.controls['tipoConsulta'].valid">
                Campo obligatorio
              </mat-error>
            </mat-form-field>
          </div>
          <div class="uk-margin">
            <mat-form-field class="uk-width-4-6-1" appearance="outline">
              <mat-label>Ingresa tu consulta</mat-label>
              <textarea
                matInput
                placeholder="Ingrese su consulta"
                formControlName="consulta"
                matTextareaAutosize
              ></textarea>
              <mat-icon matPrefix> notes</mat-icon>
              <mat-error *ngIf="!formGroup.controls['consulta'].valid">
                Campo obligatorio, debe tener entre 10 a 5000 caracteres.
              </mat-error>
            </mat-form-field>

            <div class="uk-margin terms">
              <mat-checkbox
                [color]="'primary'"
                [formControlName]="'aceptoTerminos'"
                required
              >
                <a
                  routerLink="/terminos-de-datos-personales"
                  class="terms"
                  target="_blank"
                  >He leído y acepto los términos de uso de datos personales.</a
                >
              </mat-checkbox>
              <mat-error
                *ngIf="submitted && !formGroup.controls['aceptoTerminos'].valid"
              >
                Se requiere aceptar los términos de uso.
              </mat-error>
            </div>
          </div>

          <button
            id="btnSubmit"
            class="uk-button uk-button-primary uk-width-1-1@s uk-width-1-4@m uk-width-1-4@l uk-width-1-4@xl uk-margin-small-bottom uk-margin"
            type="submit"
            color="primary"
            [disabled]="uploading"
          >
            <mat-icon class="color-white">send</mat-icon>

            <span *ngIf="!uploading"> Enviar consulta</span>

            <span *ngIf="uploading"> Enviando <loading-bar></loading-bar></span>
          </button>
        </div>
      </fieldset>
    </form>
  </div>
</section>
