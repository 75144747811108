<section id="claim"
         class="claim-area uk-claim uk-section uk-section-area">
    <div class="uk-container">
        <div class="uk-section-title section-title uk-align-center">
            <span>{{subTitle}}</span>
            <h2>{{title}}</h2>
        </div>
    </div>
</section>

<section id="claim"
         class="uk-services-details uk-section2">
    <div class="uk-container">

        <h4>{{sectionTitle}}</h4>
        <p>{{sectionSubTitle}}</p>
        <div [innerHTML]="sectionDescription">
            {{sectionDescription}}
        </div>
        <br>

        <div class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-1@m uk-child-width-1-1@s">
            <div class="item">
                <form id="claimForm">
                    <div class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-3@m uk-child-width-1-1@s">
                    </div>
                    <div class="item">
                        <div class="inner uk-width-expand">
                            <div class="services-details-desc">

                                <div class="our-work-benefits">

                                    <ul class="accordion"
                                        uk-accordion>

                                        <li class="accordion-item {{item.show}}"
                                            *ngFor="let item of data; let index = index">
                                            <a class="accordion-title uk-accordion-title"
                                               href="javascript:void(0)">
                                                <i class="flaticon-plus"></i>
                                                {{item.name}}
                                            </a>

                                            <div class="accordion-content uk-accordion-content show"
                                                 [innerHTML]="item.description">
                                                {{item.description}}
                                            </div>
                                        </li>
                                    </ul>

                                </div>
                            </div>
                        </div>
                    </div>

                </form>
            </div>
        </div>
    </div>
</section>
