import { Component, OnInit, NgModule } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { ConfigurationInfoService } from '../../../services/common/configuration.service';

declare let UIkit: any;

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
    providers: [
        Location, {
            provide: LocationStrategy,
            useClass: PathLocationStrategy
        }
    ]
})
export class HeaderComponent implements OnInit {
    location: any;
    logo: any;
    navClass: any;
    menus: any;

    public title: string = "";

    constructor(
        private router: Router,
        private configurationInfoService: ConfigurationInfoService,
        location: Location
    ) { }

    ngOnInit() {

        this.getData();

        this.router.events
            .subscribe((event) => {
                if (event instanceof NavigationEnd) {
                    this.location = this.router.url;
                    this.logo = 'logo.png';
                    this.navClass = 'bg-white';
                }
            });
    }

    getData() {

        this.configurationInfoService.GetMenus().subscribe((response: any) => {
            if (response.ok) {
                this.menus = response.data;
            }
        });

    }

    filterPage(type: number, array: any[]) {
        return array.filter((arr) => arr.id == type);
    }

    closeMenu(e) {
        UIkit.offcanvas('#offcanvas-flip').hide();
    }

}