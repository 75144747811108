<section id="claim"
         class="claim-area uk-claim uk-section uk-section-area">
    <div class="uk-container">
        <div class="uk-section-title section-title uk-align-center">
            <span>{{subTitle}}</span>
            <h2>{{title}}</h2>
        </div>
    </div>
</section>
<section class="uk-about about-area uk-section">
    <div class="uk-container">

        <div class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-1@m uk-child-width-1-1@s">
            <div class="item"
                 *ngFor="let item of data; let index = index">
                <div class="about-content">
                    <div class="uk-section-title section-title">
                        <span>{{item.subTitle}}</span>
                        <h2>{{item.title}}</h2>
                        <div class="bar"></div>
                        <div class="about-text"
                             [innerHTML]="item.description">
                            {{item.description}}
                        </div>

                        <div class="uk-child-width-1-3@m uk-grid-small uk-grid-match"
                             ng-if="item.items"
                             uk-grid>
                            <div class="uk-card uk-card-gris-light uk-card-body"
                                 *ngFor="let itemX of item.items">
                                <h3 class="uk-card-title">{{itemX.name}}</h3>
                                <div [innerHTML]="itemX.description">{{itemX.description}}</div>
                            </div>
                            <!-- <div>
                                <div class="uk-card uk-card-gray uk-card-body">
                                    <h3 class="uk-card-title">Visión</h3>
                                    <p>
                                        Ser una instancia justa e imparcial al servicio de los clientes de las entidades
                                        financieras, colaborando para que la relación entre ellos se desarrolle en un
                                        marco
                                        de buena fe, equidad y confianza recíproca.
                                    </p>

                                </div>
                            </div>
                            <div>
                                <div class="uk-card uk-card-gris-light uk-card-body">
                                    <h3 class="uk-card-title">Nuestros Valores</h3>
                                    <ul class="about-text-list">
                                        <li>
                                            <i class="flaticon-tick"></i>
                                            Tener vocación de servicio
                                        </li>
                                        <li>
                                            <i class="flaticon-tick"></i>
                                            Actuar con transparencia
                                        </li>
                                        <li>
                                            <i class="flaticon-tick"></i>
                                            Ser justos
                                        </li>
                                        <li>
                                            <i class="flaticon-tick"></i>
                                            Propiciar la conciliación
                                        </li>
                                    </ul>
                                </div>
                            </div> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <!-- <div class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-2@m uk-child-width-1-1@s">
            <div class="item">
                <div class="about-img"
                     style="left:inherit;right: 0 !important;">
                    <img src="assets/img/about/bandera.jpg"
                         class="about-img1"
                         alt="bandera">
                </div>
            </div>
        </div> -->

        <!-- <div class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-1@m uk-child-width-1-1@s">
            <div class="item">
                <div class="about-content">
                    <div class="uk-child-width-1-3@m uk-grid-small uk-grid-match"
                         uk-grid>
                        <div>
                            <div class="uk-card uk-card-gris-light uk-card-body">
                                <h3 class="uk-card-title">Misión</h3>
                                <p>
                                    Defender de manera objetiva los derechos del cliente financiero al prevenir, mediar
                                    o resolver conflictos entre entidades financieras y sus clientes de manera ágil,
                                    técnica y gratuita.
                                </p>
                            </div>
                        </div>
                        <div>
                            <div class="uk-card uk-card-gray uk-card-body">
                                <h3 class="uk-card-title">Visión</h3>
                                <p>
                                    Ser una instancia justa e imparcial al servicio de los clientes de las entidades
                                    financieras, colaborando para que la relación entre ellos se desarrolle en un marco
                                    de buena fe, equidad y confianza recíproca.
                                </p>

                            </div>
                        </div>
                        <div>
                            <div class="uk-card uk-card-gris-light uk-card-body">
                                <h3 class="uk-card-title">Nuestros Valores</h3>
                                <ul class="about-text-list">
                                    <li>
                                        <i class="flaticon-tick"></i>
                                        Tener vocación de servicio
                                    </li>
                                    <li>
                                        <i class="flaticon-tick"></i>
                                        Actuar con transparencia
                                    </li>
                                    <li>
                                        <i class="flaticon-tick"></i>
                                        Ser justos
                                    </li>
                                    <li>
                                        <i class="flaticon-tick"></i>
                                        Propiciar la conciliación
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <br><br>

                </div>
            </div>
        </div> -->
    </div>
</section>
