import { Directive, HostListener, ElementRef, Inject, Input, Output } from '@angular/core';
import { NgForm } from "@angular/forms";
import { DOCUMENT } from '@angular/common';

@Directive({
  selector: '[focusInvalidInput]'
})
export class FocusInvalidInputDirective {

  selectorToFocus: String = 'textArea,mat-select,select,input,checkbox';

  constructor(private el: ElementRef, @Inject(DOCUMENT) private document: Document) { }

  @Input() formGroup: NgForm;

  @HostListener('submit', ['$event'])
  onFormSubmit(event): void {

    if ('INVALID' === this.formGroup.status) {

      event.preventDefault();

      const invalidElements = this.el.nativeElement.querySelectorAll('.ng-invalid,:not(.mat-badge-hidden).mat-badge');

      if (invalidElements.length > 0) {

        let elementToOffset = this.getElementToOffset(invalidElements[0]);

        this.document.documentElement.scrollTop = elementToOffset.offsetTop - 180;

        this.setFocusOnError(elementToOffset);
      }
    }
  }

  getElementToOffset(element: any) {

    let defaultElement = element;

    while (!(element.parentElement instanceof HTMLFormElement)) {
      if (element.parentElement) {
        element = element.parentElement;
      } else {
        return defaultElement;
      }
    }
    return element;
  }

  setFocusOnError(elementToOffset: any) {
    let listaElementos = elementToOffset.querySelectorAll('.ng-invalid,:not(.mat-badge-hidden).mat-badge');

    if (listaElementos.length > 0) {
      console.log(listaElementos);
      listaElementos[0].focus();
    }
  }
}