<div
  class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-1@m uk-child-width-1-2@s"
>
  <div class="item">
    <div class="tips-content">
      <div class="uk-section-title">
        <div class="tips-text">
          <div
            class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-2@m uk-child-width-1-1@s"
          >
            <a
              class="card"
              *ngFor="let card of dataTips | filter : searchText"
              [href]="origin + '/consejos-utiles/' + card.slug"
            >
              <div class="box-image">
                <img
                  src="{{
                  card.pathImage
                    ? card.pathImage
                    : '../../../../assets/img/tips/img_template_banner.jpg'
                }}"
                  alt=""
                />
              </div>
              <div class="category">
                <div>{{ card.textProduct }}</div>
                <div>{{ card.textProblem }}</div>
              </div>
              <h3 class="uk-card-title mt-0">{{ card.title }}</h3>
              <p>{{ card.summary }}</p>
              <div class="info">
                <div>{{ card.date }}</div>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
