import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { StyleService } from "../../../services/style.service";
import { CacheService } from "../../../services/cache.service";
import { ConfigurationInfoService } from "../../../services/common/configuration.service";

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: "app-stats",
  templateUrl: "./stats.component.html",
  styleUrls: ["./stats.component.scss"],
})
export class StatsComponent implements OnInit {
  formGroup: UntypedFormGroup;

  public title: string = "";
  public subtitle: string = "";
  public data: any;
  public filter: any;
  public categories: any;
  public sectionDescription: any;
  public sectionTitle: any;
  public sectionSubTitle: any;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private styleService: StyleService,
    private cacheService: CacheService,
    private configurationInfoService: ConfigurationInfoService
  ) {}

  ngOnDestroy() {
    //this.styleService.removeStyle('stats-theme');
  }

  ngOnInit(): void {
    this.createForm();

    this.getData();

    //this.styleService.addStyle('stats-theme', require('./stats.component.scss'));
  }

  createForm() {
    this.formGroup = this.formBuilder.group({
      filters: [null, Validators.required],
    });
  }

  changeCategory(event: any) {
    this.data = this.filterByCategory(Number(event.value), this.filter);
  }

  filterByCategory(type: number, array: any[]) {
    return array.filter((arr) => arr.category == type);
  }

  filterPage(type: number, array: any[]) {
    return array.filter((arr) => arr.id == type);
  }

  getData() {
    this.configurationInfoService
      .GetPagesContent()
      .subscribe((response: any) => {
        if (response.ok) {
          const data = this.filterPage(Number(2), response.data.pages);

          this.title = data[0].title;
          this.subtitle = data[0].subTitle;
          this.categories = data[0].sections[0].filters.sort(
            (a, b) => b.category - a.category
          );

          this.sectionTitle = data[0].sections[0].title;
          this.sectionSubTitle = data[0].sections[0].subTitle;
          this.sectionDescription = data[0].sections[0].sectionDescription;

          this.filter = data[0].sections[0].items;

          this.data = this.filterByCategory(
            Number(this.categories[0].category),
            this.filter
          );

          this.formGroup.controls["filters"].setValue(
            this.categories[0].category
          );
        }
      });
  }
}
