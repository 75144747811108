import { Component, OnInit, ViewEncapsulation } from "@angular/core";

declare const require: any;

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: "app-wsp-fixed",
  templateUrl: "./wsp-fixed.component.html",
  styleUrls: ["./wsp-fixed.component.scss"],
})
export class WspFixedComponent implements OnInit {
  constructor() {}

  ngOnInit() {}

  ngOnDestroy() {}

  getData() {}
}
