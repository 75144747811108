import { Component, OnInit } from '@angular/core';
import { StyleService } from '../../../../services/style.service';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { ConfigurationInfoService } from '../../../../services/common/configuration.service';

declare const require: any;

@Component({
  selector: 'aside-left',
  templateUrl: './aside-left.component.html'
})
export class AsideLeftComponent implements OnInit {

  public query: string = "";
  public active: string = "";
  public menulist: any;
  public data: any;

  constructor(private styleService: StyleService,
    private router: Router,
    private route: ActivatedRoute,
    private configurationInfoService: ConfigurationInfoService,
    private readonly httpClient: HttpClient
  ) {
    route.queryParams.subscribe(
      data => this.query = data['categoria']);
  }

  ngOnInit() {

    this.getData();
  }

  ngOnDestroy() { }


  filterById(id: string, data: any) {
    return data.filter(object => {
      return object['urlFriendly'] == id;
    });
  }

  filterPage(type: number, array: any[]) {
    return array.filter((arr) => arr.id == type);
  }

  getData() {

    this.configurationInfoService.GetPagesContent().subscribe((response: any) => {

      if (response.ok) {

        const data = this.filterPage(
          Number(8),
          response.data.pages
        );

        data[0].sections.forEach(obj => {
          if (obj.urlFriendly == this.query) {
            obj.active = "active";
          }
        });

        this.menulist = data[0].sections;
      }

    });
  }
}
