<div id="home" class="uk-banner main-banner uk-white banner-with-form">
  <div class="d-table">
    <div class="d-table-cell">
      <div class="uk-container">
        <div
          class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-2@m uk-child-width-1-1@s"
        >
          <div class="item">
            <div class="main-banner-content">
              <h2>{{ sectionHomeTitle }}</h2>
              <h2 style="width: 70%">
                <u>{{ sectionHomeSubTitle }}</u>
              </h2>
              <br />
              <a
                routerLink="/cuestionario-de-reclamos"
                class="uk-button uk-button-default uk-width-1-2 uk-margin-small-bottom"
                >Ingresa tu reclamo</a
              >
            </div>
          </div>

          <div class="item">
            <div class="home-image-slides owl-theme">
              <owl-carousel-o
                [options]="bannerHomeOptions"
                (translated)="getPassedData($event)"
                (change)="getChangeData($event)"
                (changed)="getChangedData($event)"
                #owlCar
              >
                <ng-container *ngFor="let item of dataTips">
                  <ng-template carouselSlide *ngIf="item.title">
                    <div class="slider">
                      <div class="uk-card">
                        <div class="uk-card-image h-banner-slider">
                          <a
                            href="{{ 'consejos-utiles/' + item.slug }}"
                            class="img-card"
                          >
                            <img
                              src="{{
                                item.pathImage
                                  ? item.pathImage
                                  : '../../../../assets/img/tips/img_template_banner.jpg'
                              }}"
                              class=""
                              alt="img-card"
                            />
                          </a>
                        </div>
                        <div class="uk-card-text p-20">
                          <img
                            src="assets/img/bg_img_banner.png"
                            class="bg-home-card"
                            alt="bg-home-card"
                          />
                          <h4 class="m-0">
                            <strong>{{ item.title }}</strong>
                          </h4>
                          <h4
                            class="resolution-text m-0 font-weight-300 resolution-text-w400"
                          >
                            {{ item.resolutionText }}
                          </h4>
                        </div>
                      </div>
                    </div>
                  </ng-template>
                </ng-container>
              </owl-carousel-o>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<section class="uk-help uk-white help-area uk-section">
  <div class="uk-container">
    <div class="uk-grid uk-grid-match uk-child-width-1-4@s">
      <div class="item uk-width-1-2@m" style="width: 655px">
        <div class="help-box uk-card uk-card-default uk-card-body">
          <div class="help-text" [innerHTML]="sectionTopClaimDescription">
            {{ sectionTopClaimDescription }}
          </div>
        </div>
      </div>

      <div class="item">
        <div
          class="help-box uk-card uk-card-default uk-card-body bg-color-grey"
        >
          <div class="help-content">
            <div class="icon">
              <img
                src="assets/img/section-2/icon-1.png"
                class="about-img1"
                alt="about-img"
              />
            </div>
            <p>{{ TopDescription1 }}</p>
            <a
              href="/reclamos-frecuentes?categoria=no-reconozco-algunos-consumos-en-mi-tarjeta-de-credito"
              class="details-btn"
              ><i uk-icon="arrow-right"></i
            ></a>
          </div>
        </div>
      </div>

      <div class="item">
        <div class="help-box uk-card uk-card-default uk-card-body">
          <div class="help-content">
            <div class="icon">
              <img
                src="assets/img/section-2/icon-2.png"
                class="about-img1"
                alt="about-img"
              />
            </div>
            <p>{{ TopDescription2 }}</p>
            <a
              href="/reclamos-frecuentes?categoria=no-reconozco-uno-o-mas-retiros-en-mi-cuenta"
              class="details-btn"
              ><i uk-icon="arrow-right"></i
            ></a>
          </div>
        </div>
      </div>

      <div class="item" style="width: 364px">
        <div class="help-box uk-card uk-card-default uk-card-body"></div>
      </div>

      <div class="item">
        <div
          class="help-box uk-card uk-card-default uk-card-body bg-color-grey"
        >
          <div class="help-content">
            <div class="icon">
              <img
                src="assets/img/section-2/icon-3.png"
                class="about-img1"
                alt="about-img"
              />
            </div>
            <p>{{ TopDescription3 }}</p>
            <a
              href="/reclamos-frecuentes?categoria=la-entidad-financiera-me-ha-cobrado-de-manera-indebida-la-comision-de-membresía"
              class="details-btn"
              ><i uk-icon="arrow-right"></i
            ></a>
          </div>
        </div>
      </div>

      <div class="item">
        <div class="help-box uk-card uk-card-default uk-card-body">
          <div class="help-content">
            <div class="icon">
              <img
                src="assets/img/section-2/icon-4.png"
                class="about-img1"
                alt="about-img"
              />
            </div>

            <p>{{ TopDescription4 }}</p>
            <a
              href="/reclamos-frecuentes?categoria=no-estoy-de-acuerdo-con-el-monto-de-intereses-que-me-estan-cobrando"
              class="details-btn"
              ><i uk-icon="arrow-right"></i
            ></a>
          </div>
        </div>
      </div>

      <div class="item">
        <div
          class="help-box uk-card uk-card-default uk-card-body bg-color-grey"
        >
          <div class="help-content">
            <div class="icon">
              <img
                src="assets/img/section-2/icon-5.png"
                class="about-img1"
                alt="about-img"
              />
            </div>
            <p>{{ TopDescription5 }}</p>
          </div>
          <a
            href="/reclamos-frecuentes?categoria=me-cobran-indebidamente-comisiones-como-interplaza-retención-judicial-entre-otros"
            class="details-btn"
            ><i uk-icon="arrow-right"></i
          ></a>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="page-title-area uk-page-title">
  <div class="uk-container">
    <h2>{{ sectionConsultaTitle }}</h2>
    <h2>{{ sectionConsultaSubTitle }}</h2>

    <ul>
      <a routerLink="/consultas" class="uk-button uk-button-default"
        >Realiza tu consulta</a
      >
    </ul>
  </div>
</section>

<section class="uk-project uk-white aconseja-area uk-section">
  <div class="uk-container">
    <div class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-4@m">
      <div class="uk-item flex items-center">
        <div>
          <h2>La DCF <br /><strong>te aconseja</strong></h2>
          <div class="flex items-center my-20">
            <p class="mb-0">Ver todos los consejos</p>
            <a href="/consejos-utiles" class="arrow-btn">
              <img
                src="assets/img/arrow_skyblue_right.svg"
                class="arrow-right"
                alt="arrow-right"
              />
            </a>
          </div>
        </div>
      </div>
      <div class="uk-item mb-20 desktop" *ngFor="let item of dataTips">
        <ng-container *ngIf="!isMobile">
          <div class="uk-card">
            <div class="uk-card-image">
              <a href="{{ 'consejos-utiles/' + item.slug }}" class="img-card">
                <img
                  src="{{
                    item.pathImage
                      ? item.pathImage
                      : '../../../../assets/img/tips/img_template_banner.jpg'
                  }}"
                  class=""
                  alt="img-card"
                />
              </a>
            </div>
            <div class="uk-card-text">
              <img
                src="assets/img/bg_card_aconseja.png"
                class="bg-card"
                alt="bg-card"
              />
              <h4 class="resolution-title m-0">
                <strong>{{ item.title }}</strong>
              </h4>
              <h4 class="resolution-text m-0 font-weight-300">
                {{ item.resolutionText }}
              </h4>
              <a href="{{ 'consejos-utiles/' + item.slug }}" class="img-more">
                <img
                  src="assets/img/btn_more_white.svg"
                  class=""
                  alt="img-more"
                />
              </a>
            </div>
          </div>
        </ng-container>
      </div>

      <div *ngIf="isMobile">
        <owl-carousel-o
          [options]="tipsCardsSlider"
          (translated)="getPassedData($event)"
          (change)="getChangeData($event)"
          (changed)="getChangedData($event)"
          class="partner-area slider-aconseja"
        >
          <ng-container *ngFor="let item of dataTips" class="mobile">
            <ng-template carouselSlide [id]="item.id" [width]="item.width">
              <div class="slider">
                <div class="uk-card">
                  <div class="uk-card-image">
                    <a
                      href="{{ 'consejos-utiles/' + item.slug }}"
                      class="img-card"
                    >
                      <img
                        src="{{
                          item.pathImage
                            ? item.pathImage
                            : '../../../../assets/img/tips/img_template_banner.jpg'
                        }}"
                        class=""
                        alt="img-card"
                      />
                    </a>
                  </div>
                  <div class="uk-card-text">
                    <img
                      src="assets/img/bg_card_aconseja.png"
                      class="bg-card"
                      alt="bg-card"
                    />
                    <h4 class="m-0">
                      <strong>{{ item.title }}</strong>
                    </h4>
                    <h4 class="resolution-text m-0 font-weight-300">
                      {{ item.resolutionText }}
                    </h4>
                    <a
                      href="{{ 'consejos-utiles/' + item.slug }}"
                      class="img-more"
                    >
                      <img
                        src="assets/img/btn_more_white.svg"
                        class=""
                        alt="img-more"
                      />
                    </a>
                  </div>
                </div>
              </div>
            </ng-template>
          </ng-container>
        </owl-carousel-o>
      </div>
    </div>
  </div>
</section>

<section class="uk-project uk-white project-area uk-section">
  <div class="uk-container">
    <div class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-3@m">
      <div class="uk-item" *ngFor="let item of itemsAbout">
        <div class="single-project-box">
          <div class="icon">
            <img
              src="assets/img/about-{{ item.order }}.png"
              class="about-img1"
              alt="{{ item.name }}"
            />
          </div>
          <br />
          <p>{{ item.name }}</p>
        </div>
      </div>
    </div>
  </div>
</section>

<section id="about" class="uk-about about-area uk-section" style="margin: 0">
  <div class="uk-container">
    <div
      class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-2@m uk-child-width-1-1@s"
    >
      <div class="item">
        <div>
          <img src="assets/img/about1.jpg" class="about-img1" alt="about-img" />
        </div>
      </div>
      <div class="item">
        <div class="about-content">
          <div class="about-text">
            <div [innerHTML]="sectionStatsDescription">
              {{ sectionStatsDescription }}
            </div>

            <p uk-margin>
              <a
                routerLink="/estadisticas-del-sector"
                class="uk-button uk-button-primary uk-button-small uk-margin-left"
                >Estadísticas</a
              >
              <button
                (click)="downloadFile('reglamento')"
                class="uk-button uk-button-primary uk-button-small uk-margin-left"
              >
                Reglamento
              </button>
              <button
                (click)="downloadFile('guia-proceso')"
                class="uk-button uk-button-primary uk-button-small uk-margin-left"
              >
                Guía de Proceso
              </button>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<div class="separate">
  <div class="br-line"></div>
</div>

<div class="partner-area uk-section">
  <div class="uk-container">
    <div class="uk-section-title section-title uk-text-center margin-bottom-30">
      <h3>{{ sectionEntitiesTitle }}</h3>
      <div [innerHTML]="sectionEntitiesDescription">
        {{ sectionEntitiesDescription }}
      </div>
    </div>

    <div class="navbar-footer pa-navbar-right">
      <nav class="pa-navbar-container">
        <ul
          class="pa-navbar-nav"
          uk-switcher="animation: uk-animation-fade; toggle: > *"
        >
          <li *ngFor="let item of sectionEntitiesFilters">
            <a class="uk-active" (click)="changeCategory(item.category)">{{
              item.category
            }}</a>
          </li>
        </ul>

        <ul class="uk-switcher uk-margin margin-top-25">
          <li style="display: block">
            <div>
              <owl-carousel-o
                [options]="customOptions"
                (translated)="getPassedData($event)"
                (change)="getChangeData($event)"
                (changed)="getChangedData($event)"
                class="partner-area"
                #owlCar
              >
                <ng-container *ngFor="let item of carouselData">
                  <ng-template
                    carouselSlide
                    [id]="item.id"
                    [width]="item.width"
                  >
                    <div class="slider">
                      <a href="{{ item.button.file.url }}" target="_bank">
                        <img
                          src="{{ item.button.url }}"
                          alt="{{ item.name }}"
                          [ngStyle]="{
                            margin: item.name == 'Cencosud' ? 'auto' : 'none'
                          }"
                          style="width: 170px"
                        />
                      </a>
                    </div>
                  </ng-template>
                </ng-container>
              </owl-carousel-o>
            </div>
          </li>
        </ul>
      </nav>
    </div>
  </div>
</div>
