import { NgModule } from "@angular/core";
import { Routes, RouterModule, ExtraOptions } from "@angular/router";
import { HomeComponent } from "./components/pages/home/home.component";
import { AboutComponent } from "./components/pages/about/about.component";
import { HelpComponent } from "./components/pages/help/help.component";
import { TopClaimTypesComponent } from "./components/pages/top-claim-types/top-claim-types.component";
import { FaqComponent } from "./components/pages/faq/faq.component";
import { ContactComponent } from "./components/pages/contact/contact.component";
import { ClaimStatusComponent } from "./components/pages/claim-status/claim-status.component";
import { QuerysComponent } from "./components/pages/querys/querys.component";
import { PartnersComponent } from "./components/pages/partners/partners.component";
import { ClaimQuestionComponent } from "./components/pages/claim-question/claim-question.component";
import { ClaimsComponent } from "./components/pages/claims/claims.component";
import { TermsConditionsComponent } from "./components/pages/terms-conditions/terms-conditions.component";
import { PrivacyPoliciesComponent } from "./components/pages/privacy-policies/privacy-policies.component";
import { StatsComponent } from "./components/pages/stats/stats.component";
import { TipsComponent } from "./components/pages/tips/tips.component";
import { TemplateFirstTipComponent } from "./components/pages/templates/tips/tmp-01.component";
import { TemplateSecondTipComponent } from "./components/pages/templates/tips/tmp-02.component";
import { SurveyComponent } from "./components/pages/survey/survey.component";

const routerOptions: ExtraOptions = {
  scrollPositionRestoration: "enabled",
  scrollOffset: [0, 64],
  useHash: false,
};

const routes: Routes = [
  { path: "", component: HomeComponent },
  { path: "estadisticas-del-sector", component: StatsComponent },
  { path: "nosotros", component: AboutComponent },
  { path: "consejos-utiles", component: TipsComponent },
  { path: "consejos-utiles/:slug", component: TemplateFirstTipComponent },
  { path: "tmp-consejos-01", component: TemplateFirstTipComponent },
  { path: "tmp-consejos-02", component: TemplateSecondTipComponent },
  { path: "encuesta-cliente", component: SurveyComponent },
  { path: "ayuda", component: HelpComponent },
  { path: "reclamos-frecuentes", component: TopClaimTypesComponent },
  { path: "preguntas-frecuentes", component: FaqComponent },
  { path: "formulario-de-reclamos", component: ClaimsComponent },
  { path: "contacto", component: ContactComponent },
  { path: "estado-de-tu-reclamo", component: ClaimStatusComponent },
  { path: "consultas", component: QuerysComponent },
  { path: "entidades-afiliadas", component: PartnersComponent },
  { path: "cuestionario-de-reclamos", component: ClaimQuestionComponent },
  { path: "terminos-y-condiciones", component: TermsConditionsComponent },
  { path: "terminos-de-datos-personales", component: PrivacyPoliciesComponent },
  { path: "**", pathMatch: "full", component: HomeComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, routerOptions)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
