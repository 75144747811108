import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { ConfigurationInfoService } from "src/app/services/common/configuration.service";
import { TipsService } from "src/app/services/tips/tips.services";

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: "app-interest-you",
  templateUrl: "./interest-you.html",
  styleUrls: ["./tmp-consejos.component.scss"],
})
export class InterestYouComponent implements OnInit {
  public title: string = "";
  public subTitle: string = "";
  public data: any;
  public filter: any;
  dataTips: any[];
  origin: string;
  showListOptions: boolean = false;
  cards = [
    {
      id: 1,
      image: "assets/img/tips/img_consejos_card.jpg",
      category: ["Category1", "category2"],
      title: "Lorem1 ipsum",
      content:
        "No es de sorprendernos, estos nos transportan a mundos mágicos, producen emociones como la alegría, simpatía y compasión, por nombrar solo algunas",
      autor: "Jhon Smith",
      date: "01 marzo 2024",
    },
    {
      id: 2,
      image: "assets/img/tips/img_consejos_card.jpg",
      category: ["Category3", "category4"],
      title: "Lorem2 ipsum",
      content:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
      autor: "Jhon Smith",
      date: "02 marzo 2024",
    },
  ];
  searchText: any;

  constructor(
    private tipsService: TipsService,
    private configurationInfoService: ConfigurationInfoService
  ) {}

  ngOnInit() {
    this.origin = window.location.origin;
    this.getData();
  }

  ngOnDestroy() {}

  filterPage(type: number, array: any[]) {
    return array.filter((arr) => arr.id == type);
  }

  toggleListOptions() {
    this.showListOptions = !this.showListOptions;
  }

  getData() {
    this.tipsService.GetTips().subscribe((response: any) => {
      this.dataTips = response.data.map((tip: any) => ({
        ...tip,
        date: new Date(tip.creationDate).toLocaleDateString("es-ES", {
          year: "numeric",
          month: "long",
          day: "numeric",
        }),
      }));
      this.dataTips = this.dataTips.slice(-2);
    });
  }
}
