import { Component, OnInit, NgModule, ViewEncapsulation } from "@angular/core";
import { ConfigurationInfoService } from "../../../../services/common/configuration.service";
import { ActivatedRoute, Router } from "@angular/router";
import { TipsService } from "src/app/services/tips/tips.services";

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: "app-tmp-tips-01",
  templateUrl: "./tmp-01.component.html",
  styleUrls: ["./tmp-consejos.component.scss"],
})
export class TemplateFirstTipComponent implements OnInit {
  public title: string = "";
  public subTitle: string = "";
  public data: any;
  public filter: any;
  postSlug: string = "";
  dataTips: any[];
  tipFiltered: any = {};
  showListOptions: boolean = false;
  cards = [
    {
      id: 1,
      image: "assets/img/tips/img_consejos_card.jpg",
      category: ["Category1", "category2"],
      title: "Lorem1 ipsum",
      content:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
      autor: "Jhon Smith",
      date: "01 marzo 2024",
    },
    {
      id: 2,
      image: "assets/img/tips/img_consejos_card.jpg",
      category: ["Category3", "category4"],
      title: "Lorem2 ipsum",
      content:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
      autor: "Jhon Smith",
      date: "02 marzo 2024",
    },
    {
      id: 3,
      image: "assets/img/tips/img_consejos_card.jpg",
      category: ["Category5", "category6"],
      title: "Lorem3 ipsum",
      content:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
      autor: "Jhon Smith",
      date: "03 marzo 2024",
    },
    {
      id: 4,
      image: "assets/img/tips/img_consejos_card.jpg",
      category: ["Category7", "category8"],
      title: "Lorem4 ipsum",
      content:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
      autor: "Jhon Smith",
      date: "04 marzo 2024",
    },
    {
      id: 5,
      image: "assets/img/tips/img_consejos_card.jpg",
      category: ["Category9", "category10"],
      title: "Lorem5 ipsum",
      content:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
      autor: "Jhon Smith",
      date: "05 marzo 2024",
    },
    {
      id: 6,
      image: "assets/img/tips/img_consejos_card.jpg",
      category: ["Category11", "category12"],
      title: "Lorem6 ipsum",
      content:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
      autor: "Jhon Smith",
      date: "06 marzo 2024",
    },
  ];
  searchText: any;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private configurationInfoService: ConfigurationInfoService,
    private tipsService: TipsService
  ) {}

  ngOnInit() {
    this.route.params.subscribe((params) => {
      this.postSlug = params["slug"];
    });
    this.getData();
  }

  ngOnDestroy() {}

  filterPage(type: number, array: any[]) {
    return array.filter((arr) => arr.id == type);
  }

  toggleListOptions() {
    this.showListOptions = !this.showListOptions;
  }

  getData() {
    this.tipsService.GetTips().subscribe((response: any) => {
      const currentSlug = this.route.snapshot.paramMap.get("slug");

      this.dataTips = response.data
        .map((tip: any) => ({
          ...tip,
          date: new Date(tip.creationDate).toLocaleDateString("es-ES", {
            year: "numeric",
            month: "long",
            day: "numeric",
          }),
        }))
        .filter((tip) => tip.slug === currentSlug);

      if (this.dataTips.length === 0) {
        this.router.navigate(["/consejos-utiles"]);
        return;
      }

      this.tipFiltered = this.dataTips[0];
    });
  }
}
