import { Component, OnInit, NgModule, ViewEncapsulation } from "@angular/core";
import { ConfigurationInfoService } from "../../../../services/common/configuration.service";

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: "app-tmp-tips-02",
  templateUrl: "./tmp-02.component.html",
  styleUrls: ["./tmp-consejos.component.scss"],
})
export class TemplateSecondTipComponent implements OnInit {
  public title: string = "";
  public subTitle: string = "";
  public data: any;
  public filter: any;
  showListOptions: boolean = false;
  searchText: any;

  constructor(private configurationInfoService: ConfigurationInfoService) {}

  ngOnInit() {
    this.getData();
  }

  ngOnDestroy() {}

  filterPage(type: number, array: any[]) {
    return array.filter((arr) => arr.id == type);
  }

  toggleListOptions() {
    this.showListOptions = !this.showListOptions;
  }

  getData() {
    this.configurationInfoService
      .GetPagesContent()
      .subscribe((response: any) => {
        if (response.ok) {
          const data = this.filterPage(Number(4), response.data.pages);

          this.title = data[0].title;

          this.subTitle = data[0].subTitle;

          this.data = data[0].sections;
        }
      });
  }
}
