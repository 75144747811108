<section id="claim"
         class="claim-area uk-claim uk-section uk-section-area">
    <div class="uk-container">
        <div class="uk-section-title section-title uk-align-center">
            <span>{{subTitle}}</span>
            <h2>{{title}}</h2>
        </div>
    </div>
</section>

<section class="contact-details-area uk-contact-details uk-section">

    <div class="uk-container">
        <article class="uk-article contact-details">
            <div class="uk-grid uk-flex">

                <div class="uk-sidebar uk-width-1-5@m uk-width-1-3@l uk-grid-margin uk-first-column">
                    <div class="widget service_list"
                         [innerHTML]="sectionDescription">
                        {{sectionDescription}}

                    </div>
                </div>

                <div class="inner uk-width-3-5@m uk-width-2-3@l uk-grid-margin">
                    <div class="mapouter">
                        <div class="gmap_canvas"><iframe width="100%"
                                    height="500"
                                    id="gmap_canvas"
                                    src="https://maps.google.com/maps?q=Av.%20San%20Borja%20Nte.%20523,%20San%20Borja,%20Lima%2015036,%20Per%C3%BA&t=&z=13&ie=UTF8&iwloc=&output=embed"
                                    frameborder="0"
                                    scrolling="no"
                                    marginheight="0"
                                    marginwidth="0"></iframe><a href="https://2piratebay.org"></a><br>
                            <style>
                                .mapouter {
                                    position: relative;
                                    text-align: right;
                                    height: 500px;
                                    width: 100%;
                                }

                            </style><a href="https://www.embedgooglemap.net">embedgooglemap.net</a>
                            <style>
                                .gmap_canvas {
                                    overflow: hidden;
                                    background: none !important;
                                    height: 500px;
                                    width: 100%;
                                }

                            </style>
                        </div>
                    </div>
                </div>

            </div>
        </article>
    </div>
</section>
