import {
  Component,
  OnInit,
  OnDestroy,
  ViewEncapsulation,
  HostListener,
} from "@angular/core";
import { StyleService } from "../../../services/style.service";
import { environment } from "../../../../environments/environment";
import { ConfigurationInfoService } from "../../../services/common/configuration.service";
import { HttpClient } from "@angular/common/http";
import { SlidesOutputData, OwlOptions } from "ngx-owl-carousel-o";
import { TipsService } from "src/app/services/tips/tips.services";

declare const require: any;

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: "app-Home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"],
})
export class HomeComponent implements OnInit {
  isMobile: boolean = false;
  carouselData: any;
  itemsAbout: any;
  helpfulData: any;
  dataTips: any[];

  bannerHomeOptions: any = {
    loop: true,
    nav: true,
    dots: false,
    items: 1,
    smartSpeed: 2000,
    autoplayHoverPause: true,
    autoplay: true,
    navText: [
      "<img src='../../assets/img/nav-up.png'>",
      "<img src='../../assets/img/nav-down.png'>",
    ],
    animateOut: "slideOutUp",
    animateIn: "slideInUp",
    responsiveClass: true,
    autoplayTimeout: 5000,
    autoplaySpeed: 4000,
    dotsData: true,
    mouseDrag: true,
    touchDrag: true,
    URLhashListener: true,
    startPosition: "URLHash",
    margin: 1,
    responsive: {
      0: {
        items: 1,
      },
      576: {
        items: 1,
      },
      768: {
        items: 1,
      },
      1200: {
        items: 1,
      },
    },
  };

  tipsCardsSlider: any = {
    direction: "ltr",
    loop: true,
    nav: true,
    dots: true,
    items: 1,
    smartSpeed: 1000,
    autoplayHoverPause: true,
    autoplay: true,
    responsiveClass: true,
    autoplayTimeout: 5000,
    autoplaySpeed: 4000,
    dotsData: true,
    mouseDrag: true,
    touchDrag: true,
    URLhashListener: true,
    startPosition: "URLHash",
    margin: 1,
    responsive: {
      0: {
        items: 1,
      },
      576: {
        items: 1,
      },
      768: {
        items: 1,
      },
      1200: {
        items: 1,
      },
    },
  };

  customOptions: any = {
    // autoWidth: true,
    loop: true,
    // autoHeight: true,
    // lazyLoad: true,
    // lazyLoadEager: 1,
    //animateOut: 'slideOutDown',
    // animateIn: 'flipInX',
    items: "4",
    // margin: 10,
    // slideBy: 'page',
    // merge: true,
    autoplay: true,
    autoplayTimeout: 5000,
    autoplayHoverPause: true,
    autoplaySpeed: 4000,
    // dotsSpeed: 500,
    dots: false,
    dotsData: true,
    mouseDrag: true,
    touchDrag: true,
    // pullDrag: false,
    // smartSpeed: 400,
    // fluidSpeed: 499,
    // dragEndSpeed: 350,
    // dotsEach: 4,
    // center: true,
    URLhashListener: true,
    startPosition: "URLHash",
    // rewind: true,
    // rtl: true,
    //startPosition: 1,
    navText: [
      "<i class=fa-chevron-left><</i>",
      "<i class=fa-chevron-right>></i>",
    ],
    smartSpeed: 2000,
    margin: 1,
    nav: true,
    responsive: {
      0: {
        items: 2,
      },
      576: {
        items: 3,
      },
      768: {
        items: 4,
      },
      1200: {
        items: 4,
      },
    },
  };

  items: any;
  currentUrl: any;
  fragment: string;

  activeSlides: SlidesOutputData;

  banners: any;
  sectionHomeTitle: string;
  sectionHomeSubTitle: string;

  sectionConsultaTitle: string;
  sectionConsultaSubTitle: string;
  sectionConsultaDescription: string;

  sectionTopClaimDescription: string;
  sectionTopClaimItems: any;
  sectionHelpfulDescription: string;
  sectionHelpfulItems: any;

  TopDescription1: string;
  TopDescription2: string;
  TopDescription3: string;
  TopDescription4: string;
  TopDescription5: string;

  textBoxHelpful1: string;
  textBoxHelpful2: string;
  textBoxHelpful3: string;
  textBoxHelpful4: string;
  textBoxHelpful5: string;

  entities: any;
  sectionEntitiesTitle: string;
  sectionEntitiesDescription: string;
  sectionEntitiesFilters: any;

  sectionStatsDescription: any;
  helpfulTips: any[];

  pageHomeId = 12;
  pageHelpful = 16;

  constructor(
    private styleService: StyleService,
    private readonly httpClient: HttpClient,
    private configurationInfoService: ConfigurationInfoService,
    private tipsService: TipsService
  ) {}

  ngOnInit() {
    this.configurationInfoService
      .GetPagesContent()
      .subscribe((response: any) => {
        this.getDataHome(response);
        this.getDataTopClaims(response);
        this.getDataStats(response);
        this.getDataAbout(response);
        this.getDataQuery(response);
        this.getDataEntities(response);
      });

    this.configurationInfoService
      .GetHomeHelpfulTips()
      .subscribe((response: any) => {
        this.getDataHelpful(response);
        this.getDataTips();
      });

    this.detectSizeWindow();
    this.addListenerResize();
  }

  ngOnDestroy() {}

  filterPage(type: number, array: any[]) {
    return array.filter((arr) => arr.id == type);
  }

  detectSizeWindow() {
    this.isMobile = window.innerWidth <= 768;
  }

  addListenerResize() {
    window.addEventListener("resize", this.onResize.bind(this));
  }

  onResize(event: any) {
    this.detectSizeWindow();
  }

  getDataHome(response: any) {
    if (response.ok) {
      const data = this.filterPage(
        Number(this.pageHomeId),
        response.data.pages
      );

      this.sectionHomeTitle = data[0].sections[3].title;
      this.sectionHomeSubTitle = data[0].sections[3].subTitle;

      this.banners = this.entities = data[0].sections[3].items.sort(
        (a, b) => a.order - b.order
      );
    }
  }

  getDataTips() {
    this.tipsService.GetHomeTips().subscribe((response: any) => {
      if (response.ok && Array.isArray(response.data)) {
        this.dataTips = response.data.filter((tip: any) => tip.slug);
      } else {
        console.error("Error en los datos recibidos:", response);
      }
    });
  }

  getDataTopClaims(response: any) {
    if (response.ok) {
      const data = this.filterPage(
        Number(this.pageHomeId),
        response.data.pages
      );

      this.sectionTopClaimDescription = data[0].sections[5].description;
      this.sectionTopClaimItems = data[0].sections[5].items;

      this.TopDescription1 = this.sectionTopClaimItems[0].name;
      this.TopDescription2 = this.sectionTopClaimItems[1].name;
      this.TopDescription3 = this.sectionTopClaimItems[2].name;
      this.TopDescription4 = this.sectionTopClaimItems[3].name;
      this.TopDescription5 = this.sectionTopClaimItems[4].name;
    }
  }

  getDataStats(response: any) {
    if (response.ok) {
      const data = this.filterPage(
        Number(this.pageHomeId),
        response.data.pages
      );

      this.sectionStatsDescription = data[0].sections[4].description;
    }
  }

  getDataAbout(response: any) {
    if (response.ok) {
      const data = this.filterPage(
        Number(this.pageHomeId),
        response.data.pages
      );

      this.itemsAbout = data[0].sections[1].items;
    }
  }

  getDataQuery(response: any) {
    if (response.ok) {
      const data = this.filterPage(
        Number(this.pageHomeId),
        response.data.pages
      );

      this.sectionConsultaTitle = data[0].sections[0].title;
      this.sectionConsultaSubTitle = data[0].sections[0].subTitle;
    }
  }

  getDataEntities(response: any) {
    if (response.ok) {
      const data = this.filterPage(
        Number(this.pageHomeId),
        response.data.pages
      );

      this.sectionEntitiesTitle = data[0].sections[2].title;
      this.sectionEntitiesDescription = data[0].sections[2].description;
      this.sectionEntitiesFilters = data[0].sections[2].filters;

      this.entities = data[0].sections[2].items;
      const filterCategories = this.filterCategory(
        data[0].sections[2].filters[0].category,
        data[0].sections[2].items
      );
      this.carouselData = filterCategories;
    }
  }

  getDataHelpful(response: any) {
    if (response.ok) {
      this.helpfulTips = response.data;
    }
  }

  changeCategory(category: string) {
    const filterCategories = this.filterCategory(category, this.entities);
    this.carouselData = filterCategories;
  }

  filterCategory(category: string, array: any[]) {
    return array.filter((arr) => arr.category == category);
  }

  getPassedData(data: any) {
    this.activeSlides = data;
    // console.log('HomeComponent');
    // console.log(this.activeSlides);
  }

  getChangeData(data: any) {
    this.activeSlides = data;
    // console.log('HomeComponent -> change');
    // console.log(data);
  }

  getChangedData(data: any) {
    this.activeSlides = data;
    //console.log('HomeComponent -> changed');
    //console.log(data);
  }
  removeLastSlide() {
    this.carouselData.splice(-1, 1);
  }

  carouselChanged(evt: SlidesOutputData) {
    console.log(evt);
  }

  downloadFile(url: any) {
    const parsedUrl = new URL(window.location.href);

    const baseUrl = parsedUrl.origin;

    let path = "";

    switch (url) {
      case "reglamento":
        path = `${baseUrl}/pdf/Reglamento-de-la-Defensoria-del-Cliente-Financiero.pdf`;
        break;
      case "guia-proceso":
        path = `${baseUrl}/pdf/Guia-de-Proceso.pdf`;
        break;
      default:
        break;
    }

    window.open(path, "_blank");
  }
}
