import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, map } from "rxjs";
import { environment } from "src/environments/environment";
import { SurveyPaths } from "./survey.paths";
import { SurveyModel } from "../../models/survey/survey";

@Injectable()
export class SurveyService {
  _url: string;

  constructor(private http: HttpClient) {
    this._url = environment.urlApiDCF;
  }

  GetSurvey(): Observable<SurveyModel.Response> {
    return this.http
      .get(`${this._url}${SurveyPaths.GetSurvey}`)
      .pipe(map((response) => response)) as Observable<SurveyModel.Response>;
  }
}
