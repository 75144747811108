<section id="claim"
         class="claim-area uk-claim uk-section uk-section-area">
    <div class="uk-container">
        <div class="uk-section-title section-title uk-align-center">
            <span>{{subTitle}}</span>
            <h2>{{title}}</h2>
        </div>
    </div>
</section>

<section id="project"
         class="project-area uk-project uk-section">
    <div class="uk-container">
        <h3>Directorio de entidades</h3>
        <br>

        <ul class="pa-navbar-nav  uk-subnav uk-subnav-pill"
            uk-switcher="animation: uk-animation-fade; toggle: > *">
            <li *ngFor="let item of sectionEntitiesFilters"><a class="uk-active"
                   (click)="changeCategory(item.category)">{{item.category}}</a></li>
        </ul>

        <div uk-filter="target: .js-filter">

            <div style="padding-top: 60px;">


                <ul class="js-filter uk-child-width-1-2 uk-child-width-1-3@m uk-text-center"
                    uk-grid>
                    <li id="{{item.category}}"
                        *ngFor="let item of carouselData">
                        <div class="single-project project-content">
                            <div class="single-blog-post">
                                <div class="blog-post-content">
                                    <a href="{{item.button.file.url}}"
                                       target="_blank"><img src="{{item.button.url}}"
                                             alt="image"></a>
                                </div>
                            </div>
                        </div>
                    </li>

                    <!-- <li data-color="bancos">
                        <div class="single-project project-content">
                            <div class="single-blog-post">
                                <div class="blog-post-content">
                                    <a href="https://www.scotiabank.com.pe/"
                                       target="_blank"><img src="assets/img/partners/bancos/logo-banco-scotiabank.png"
                                             alt="image"></a>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li data-color="bancos">
                        <div class="single-project project-content">
                            <div class="single-blog-post">
                                <div class="blog-post-content">
                                    <a href="https://interbank.pe/"
                                       target="_blank"><img src="assets/img/partners/bancos/logo-banco-interbank.png"
                                             alt="image"></a>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li data-color="bancos">
                        <div class="single-project project-content">
                            <div class="single-blog-post">
                                <div class="blog-post-content">
                                    <a href="https://www.mibanco.com.pe/"
                                       target="_blank"><img src="assets/img/partners/bancos/logo-mibanco.png"
                                             alt="image"></a>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li data-color="bancos">
                        <div class="single-project project-content">
                            <div class="single-blog-post">
                                <div class="blog-post-content">
                                    <a href="https://www.pichincha.pe/"
                                       target="_blank"><img src="assets/img/partners/bancos/logo-banco-pichincha.png"
                                             alt="image"></a>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li data-color="bancos">
                        <div class="single-project project-content">
                            <div class="single-blog-post">
                                <div class="blog-post-content">
                                    <a href="https://www.bbva.pe/"
                                       target="_blank"><img src="assets/img/partners/bancos/logo-banco-bbva.png"
                                             alt="image"></a>
                                </div>
                            </div>
                        </div>
                    </li>

                    <li data-color="bancos">
                        <div class="single-project project-content">
                            <div class="single-blog-post">
                                <div class="blog-post-content">
                                    <a href="https://www.viabcp.com/"
                                       target="_blank"><img src="assets/img/partners/bancos/logo-banco-bcp.png"
                                             alt="image"></a>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li data-color="bancos">
                        <div class="single-project project-content">
                            <div class="single-blog-post">
                                <div class="blog-post-content">
                                    <a href="https://www.bancofalabella.pe/"
                                       target="_blank"><img src="assets/img/partners/bancos/logo-banco-falabella.png"
                                             alt="image"></a>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li data-color="bancos">
                        <div class="single-project project-content">
                            <div class="single-blog-post">
                                <div class="blog-post-content">
                                    <a href="https://www.bancoripley.com.pe/"
                                       target="_blank"><img src="assets/img/partners/bancos/logo-banco-ripley.png"
                                             alt="image"></a>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li data-color="bancos">
                        <div class="single-project project-content">
                            <div class="single-blog-post">
                                <div class="blog-post-content">
                                    <a href="https://www.banbif.com.pe/"
                                       target="_blank"><img src="assets/img/partners/bancos/logo-banbif.png"
                                             alt="image"></a>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li data-color="bancos">
                        <div class="single-project project-content">
                            <div class="single-blog-post">
                                <div class="blog-post-content">
                                    <a href="https://www.bancognb.com.pe/"
                                       target="_blank"><img src="assets/img/partners/bancos/logo-banco-gnb.jpg"
                                             alt="image"></a>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li data-color="bancos">
                        <div class="single-project project-content">
                            <div class="single-blog-post">
                                <div class="blog-post-content">
                                    <a href="https://www.bancomercio.com/elbanco"
                                       target="_blank"><img src="assets/img/partners/bancos/logo-banco-de-comercio.png"
                                             alt="image"></a>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li data-color="bancos">
                        <div class="single-project project-content">
                            <div class="single-blog-post">
                                <div class="blog-post-content">
                                    <a href="https://www.alfinbanco.pe/"
                                       target="_blank"><img
                                             src="assets/img/partners/bancos/logo-alfin-nuestro-banco.png"
                                             alt="image"></a>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li data-color="bancos">
                        <div class="single-project project-content">
                            <div class="single-blog-post">
                                <div class="blog-post-content">
                                    <a href="https://www.santander.com.pe/"
                                       target="_blank"><img src="assets/img/partners/bancos/logo-santander-peru.png"
                                             alt="image"></a>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li data-color="bancos">
                        <div class="single-project project-content">
                            <div class="single-blog-post">
                                <div class="blog-post-content">
                                    <a href="https://www.citibank.com/icg/sa/latam/peru/"
                                       target="_blank"><img src="assets/img/partners/bancos/logo-citibank.png"
                                             alt="image"></a>
                                </div>
                            </div>
                        </div>
                    </li>

                    <li data-color="bancos">
                        <div class="single-project project-content">
                            <div class="single-blog-post">
                                <div class="blog-post-content">
                                    <a href="https://www.icbc.com.pe/"
                                       target="_blank"><img src="assets/img/partners/bancos/logo-icbc-peru-bank.jpg"
                                             alt="image"></a>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li data-color="bancos">
                        <div class="single-project project-content">
                            <div class="single-blog-post">
                                <div class="blog-post-content">
                                    <a href="https://www.jpmorgan.com/"
                                       target="_blank"><img src="assets/img/partners/bancos/logo-jp-morgan.png"
                                             alt="image"></a>
                                </div>
                            </div>
                        </div>
                    </li>


                    <li data-color="financiera">
                        <div class="single-project project-content">
                            <div class="single-blog-post">
                                <div class="blog-post-content">
                                    <a href="https://www.qapaq.pe/"
                                       target="_blank"><img src="assets/img/partners/financieras/logo-qapaq.png"
                                             alt="image"></a>
                                </div>
                            </div>
                        </div>
                    </li>

                    <li data-color="financiera">
                        <div class="single-project project-content">
                            <div class="single-blog-post">
                                <div class="blog-post-content">
                                    <a href="https://www.crediscotia.com.pe/"
                                       target="_blank"><img
                                             src="assets/img/partners/financieras/logo-crediscotia-con-todo.png"
                                             alt="image"></a>
                                </div>
                            </div>
                        </div>
                    </li>

                    <li data-color="financiera">
                        <div class="single-project project-content">
                            <div class="single-blog-post">
                                <div class="blog-post-content">
                                    <a href="https://www.efectiva.com.pe/"
                                       target="_blank"><img
                                             src="assets/img/partners/financieras/logo-financiera-efectiva.png"
                                             alt="image"></a>
                                </div>
                            </div>
                        </div>
                    </li>

                    <li data-color="financiera">
                        <div class="single-project project-content">
                            <div class="single-blog-post">
                                <div class="blog-post-content">
                                    <a href="https://www.compartamos.com.pe/"
                                       target="_blank"><img
                                             src="assets/img/partners/financieras/logo-compartamos-financiera.png"
                                             alt="image"></a>
                                </div>
                            </div>
                        </div>
                    </li>

                    <li data-color="financiera">
                        <div class="single-project project-content">
                            <div class="single-blog-post">
                                <div class="blog-post-content">
                                    <a href="https://confianza.pe/"
                                       target="_blank"><img
                                             src="assets/img/partners/financieras/logo-financiera-confianza.png"
                                             alt="image"></a>
                                </div>
                            </div>
                        </div>
                    </li>

                    <li data-color="caja">
                        <div class="single-project project-content">
                            <div class="single-blog-post">
                                <div class="blog-post-content">
                                    <a href="https://www.tarjetacencosud.pe/"
                                       target="_blank"><img src="assets/img/partners/cajas/logo-caja-cencosud.png"
                                             alt="image"></a>
                                </div>
                            </div>
                        </div>
                    </li> -->
                </ul>
            </div>
        </div>

    </div>
</section>
