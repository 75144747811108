import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigurationInfoService } from '../../../services/common/configuration.service';

@Component({
  selector: 'app-partners',
  templateUrl: './partners.component.html',
  styleUrls: ['./partners.component.scss']
})
export class PartnersComponent implements OnInit {

  carouselData: any;
  entities: any;
  sectionEntitiesTitle: string;
  sectionEntitiesDescription: string;
  sectionEntitiesFilters: any;

  public title: string = "";
  public subTitle: string = "";
  public data: any;

  constructor(
    private readonly httpClient: HttpClient,
    private configurationInfoService: ConfigurationInfoService,
  ) { }

  ngOnInit(): void {
    this.getData();
    this.configurationInfoService.GetPagesContent().subscribe((response: any) => {
      if (response.ok) {
        const data = this.filterPage(
          Number(12),
          response.data.pages
        );

        console.log(data[0].sections[2]);

        this.sectionEntitiesTitle = data[0].sections[2].title;
        this.sectionEntitiesDescription = data[0].sections[2].description;
        this.sectionEntitiesFilters = data[0].sections[2].filters;

        this.entities = data[0].sections[2].items;
        const filterCategories = this.filterCategory(
          data[0].sections[2].filters[0].category,
          data[0].sections[2].items
        );

        this.carouselData = filterCategories;
      }
    });
  }

  getData() {

    this.configurationInfoService.GetPagesContent().subscribe((response: any) => {
      if (response.ok) {
        const data = this.filterPage(
          Number(15),
          response.data.pages
        );

        this.title = data[0].title;
        this.subTitle = data[0].subTitle;
        this.data = data[0].sections;
      }
    });

  }

  filterPage(type: number, array: any[]) {
    return array.filter((arr) => arr.id == type);
  }

  changeCategory(category: string) {

    console.log("INI Category");
    const filterCategories = this.filterCategory(
      category,
      this.entities
    );
    console.log(category);
    console.log(filterCategories);
    this.carouselData = filterCategories;
  }

  filterCategory(category: string, array: any[]) {
    return array.filter((arr) => arr.category == category);
  }

}
