import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Paths } from './configuration.paths';
import { ConfigurationInfo } from '../../models/common/configurationInfo';

@Injectable()
export class ConfigurationInfoService {
  _url: string;

  constructor(private http: HttpClient) {
    this._url = environment.urlApiDCF;
  }

  GetMenus(): Observable<ConfigurationInfo.Response> {
    return this.http
      .get(`${this._url}${Paths.GetMenus}`)
      .pipe(map(response => response)) as Observable<ConfigurationInfo.Response>;
  }

  GetPagesContent(): Observable<ConfigurationInfo.Response> {
    return this.http
      .get(`${this._url}${Paths.GetPagesContent}`)
      .pipe(map(response => response)) as Observable<ConfigurationInfo.Response>;
  }

  GetSettingsInfo(): Observable<ConfigurationInfo.Response> {
    return this.http
      .get(`${this._url}${Paths.GetSettingsInfo}`)
      .pipe(map(response => response)) as Observable<ConfigurationInfo.Response>;
  }

  GetHomeHelpfulTips(): Observable<ConfigurationInfo.Response> {
    return this.http
      .get(`${this._url}${Paths.GetHomeHelpfulTips}`)
      .pipe(map(response => response)) as Observable<ConfigurationInfo.Response>;
  }
}
