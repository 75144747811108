<div id="offcanvas-flip"
     class="mobile-navbar uk-mobile-navbar"
     uk-offcanvas="flip: true; overlay: true">
    <div class="uk-offcanvas-bar">

        <button class="uk-offcanvas-close"
                type="button"
                uk-close></button>

        <nav class="uk-navbar-container"
             data-uk-scrollspy-nav="offset: 0; closest: li; scroll: true">
            <ul class="uk-navbar-nav">
                <li>
                    <a routerLink="/"
                       routerLinkActive="uk-active">Inicio</a>
                </li>
                <li *ngFor="let item of menus; let index = index">
                    <a href="{{item.urlFriendly}}"
                       class="{{item.urlFriendly == 'estado-de-tu-reclamo' ? 'uk-button-status' : ''}}"
                       target="{{item.name == 'Intranet' ? '_blank' : '_self'}}"
                       routerLinkActive="uk-active">{{item.name}}</a>
                </li>
            </ul>
        </nav>
    </div>
</div>

<header class="header-area {{navClass}}"
        data-uk-sticky="top: 0; animation: uk-animation-slide-top;">
    <div class="uk-container">
        <div class="uk-navbar">
            <div class="logo uk-navbar-left">
                <a routerLink="/">
                    <img src="assets/img/DCF-Logo.svg"
                         alt="logo"
                         width="215">
                </a>
            </div>

            <div class="uk-navbar-toggle"
                 id="navbar-toggle"
                 uk-toggle="target: #offcanvas-flip">
                <span></span>
                <span></span>
                <span></span>
            </div>

            <div class="navbar uk-navbar-right">
                <nav class="uk-navbar-container"
                     data-uk-scrollspy-nav="offset: 0; closest: li; scroll: true">
                    <ul class="uk-navbar-nav">
                        <li *ngFor="let item of menus; let index = index">
                            <a href="{{item.urlFriendly}}"
                               class="{{item.urlFriendly == 'estado-de-tu-reclamo' ? 'uk-button-status' : ''}}"
                               target="{{item.name == 'Intranet' ? '_blank' : '_self'}}"
                               routerLinkActive="uk-active">{{item.name}}</a>
                        </li>
                    </ul>
                </nav>
            </div>
        </div>
    </div>
</header>
