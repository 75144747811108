import {
  Component,
  OnInit,
  ViewEncapsulation,
  HostListener,
} from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { SurveyService } from "../../../services/survey/survey.service";
import { ActivatedRoute, Router } from "@angular/router";
import {
  HttpHeaders,
  HttpRequest,
  HttpEventType,
  HttpResponse,
  HttpClient,
} from "@angular/common/http";
import { finalize } from "rxjs";
import { environment } from "src/environments/environment";
import { ReCaptchaV3Service } from "ng-recaptcha";
import { MatDialog } from "@angular/material/dialog";
import { DialogInformationComponent } from "src/app/shared/dialog/dialog-information.component";

interface SurveyFormSubmissionResult {}

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: "app-survey",
  templateUrl: "./survey.component.html",
  styleUrls: ["./survey.component.scss"],
})
export class SurveyComponent implements OnInit {
  isMobile: boolean = false;
  surveyCode: string = "";
  submitted = false;
  success = false;
  error = false;
  uploadProgress = 0;
  uploading = false;
  errorMsg = "";
  resultMsg = "";
  step: any = 0;
  lastStep: any = 4;
  multistep = new FormGroup({
    userDetails: new FormGroup({
      question1: new FormControl("", Validators.required),
      question2: new FormControl("", Validators.required),
      question3: new FormControl("", Validators.required),
      question4: new FormControl("", Validators.required),
      question5: new FormControl("", Validators.required),
      question6: new FormControl("", Validators.required),
      question7: new FormControl("", Validators.required),
      question8: new FormControl("", Validators.required),
    }),
  });
  dataQuestions: any[];
  selectedNumberFirst: number | null = null;
  selectedNumberThree: number | null = null;
  firstQuestionAnswered: boolean = false;
  numbers: number[] = [];
  rowValidation: { [key: string]: boolean } = {};
  formData: any = {};
  formSubmitted = false;
  selectedValues: {
    question1: string;
    question2: string;
    question3: string;
    question4: string;
    question5: string;
    question6: string;
    question7: string;
    question8: string;
  } = {
    question1: "",
    question2: "",
    question3: "",
    question4: "",
    question5: "",
    question6: "",
    question7: "",
    question8: "",
  };
  firstQuestion = "";
  secondQuestion = "";
  thirdQuestion = "";
  thirdQuestionOne = "";
  thirdQuestionTwo = "";
  thirdQuestionThree = "";
  thirdQuestionFour = "";
  fourQuestion = "";

  validQuestion3 = false;
  validRadioButtons = false;
  validQuestion4 = false;
  currentPage: number = 1;
  totalPages: number = 0;
  allQuestions: any[] = [];

  constructor(
    private surveyService: SurveyService,
    private router: Router,
    private route: ActivatedRoute,
    private readonly httpClient: HttpClient,
    private recaptchaV3Service: ReCaptchaV3Service,
    public dialogo: MatDialog
  ) {
    for (let i = 0; i <= 10; i++) {
      this.numbers.push(i);
    }
  }

  startSurvey() {
    this.step = 1;
  }

  submit() {
    this.submitted = true;
    const userDetails = this.multistep.value.userDetails;
    this.formSubmitted = true;
    this.formData = { ...userDetails };

    const answers = [];
    const valueAnswerQuestions = [
      "question1",
      "question3",
      "question4",
      "question5",
      "question6",
      "question7",
    ];

    for (const [position, value] of Object.entries(userDetails)) {
      const id = parseInt(position.match(/\d+/)[0]);
      const isValueAnswer = valueAnswerQuestions.includes(position);
      const answer = {
        questionId: id,
        questionText: "",
        textAnswer: isValueAnswer ? "" : value,
        valueAnswer: isValueAnswer ? parseInt(value) : null,
      };
      answers.push(answer);
    }

    const formData = {
      claimCode: parseInt(this.surveyCode),
      answers: answers,
    };

    this.uploading = true;

    if (this.step === 4 && this.isCurrentStepValid()) {
      this.recaptchaV3Service
        .execute("importantAction")
        .subscribe((token: string) => {
          const options = {
            reportProgress: true,
            headers: new HttpHeaders({
              "g-recaptcha-response": token,
            }),
          };

          const req = new HttpRequest(
            "POST",
            `${environment.urlApiDCF}admin/createSurvey`,
            formData,
            options
          );

          this.httpClient
            .request<SurveyFormSubmissionResult[]>(req)
            .pipe(
              finalize(() => {
                this.uploading = false;
                this.submitted = false;
              })
            )
            .subscribe(
              (response) => {
                if (response.type === HttpEventType.UploadProgress) {
                  this.uploadProgress = Math.round(
                    (100 * response.loaded) / response.total
                  );
                } else if (response instanceof HttpResponse) {
                  let res = JSON.parse(JSON.stringify(response, null, 2));

                  if (res.ok) {
                    this.showMessage(
                      "Mensaje de confirmación",
                      `Su encuesta se ha enviado correctamente.`
                    );
                  } else {
                    this.showMessage(
                      "Mensaje de error",
                      "Error al enviar la encuesta. Por favor inténtelo de nuevo."
                    );
                  }
                }
              },
              (errorData) => {
                this.success = false;
                this.error = true;
                this.showMessage(
                  "Mensaje de error",
                  "Error al enviar el reclamo. Por favor inténtelo de nuevo."
                );
              }
            );
        });
    }
  }

  onInput(controlName: string, event: Event) {
    const value = (event.target as HTMLInputElement).value;
    this.multistep.get("userDetails." + controlName).setValue(value);
    this.selectedValues[controlName] = value;
  }

  validateRow(rowNumber: number): void {
    const rowKey = `row${rowNumber}`;
    const radios = document.querySelectorAll(
      `.row${rowNumber} input[type="radio"]:checked`
    );

    let radiosChecked = 0;

    radios.forEach((radio: HTMLInputElement) => {
      const name = radio.name;
      const value = radio.value;
      this.formData[name] = value;
      radiosChecked++;
    });

    this.multistep.patchValue({
      userDetails: this.formData,
    });

    this.rowValidation[rowKey] = radiosChecked > 0;
  }

  isQuestionInRange(min: number, max: number): boolean {
    const question2Value = +this.multistep.get("userDetails.question1").value;
    return question2Value >= min && question2Value <= max;
  }

  next() {
    console.log(this.step);
    this.getQuestionsForCurrentStep();
    // if (this.step === 1) {
    //   if (this.isCurrentStepValid()) {
    //   }
    //   this.step++;
    //   this.currentPage++;
    // } else if (this.step === 2) {
    //   this.formSubmitted = true;
    //   if (this.multistep.get("userDetails.question3").valid) {
    //     if (this.isCurrentStepValid()) {
    //     }
    //     this.step++;
    //     this.currentPage++;
    //   } else {
    //     this.validQuestion3 = true;
    //   }
    // } else if (this.step === 3) {
    //   this.formSubmitted = true;
    //   if (
    //     this.multistep.get("userDetails.question4").valid &&
    //     this.multistep.get("userDetails.question5").valid &&
    //     this.multistep.get("userDetails.question6").valid &&
    //     this.multistep.get("userDetails.question7").valid
    //   ) {
    //     if (this.isCurrentStepValid()) {
    //     }
    //     this.step++;
    //     this.currentPage++;
    //   } else {
    //     this.validRadioButtons = true;
    //   }
    // } else {
    //   if (this.isCurrentStepValid()) {
    //   }
    //   this.step++;
    //   this.currentPage++;
    // }
    this.step++;
    this.currentPage++;
    this.updateQuestionsByPage();
  }

  closeSurvey() {
    if (this.step === this.lastStep) {
      this.formSubmitted = true;
      if (this.multistep.get("userDetails.question8").valid) {
        if (this.isCurrentStepValid()) {
          this.submit();
          this.step = this.lastStep + 1;
        }
      } else {
        this.validQuestion4 = true;
      }
    }
  }

  isCurrentStepValid(): boolean {
    const currentStep = this.step;

    switch (currentStep) {
      case 1:
        return (
          this.multistep.get("userDetails.question1").valid &&
          this.multistep.get("userDetails.question2").valid
        );
      case 2:
        return this.multistep.get("userDetails.question3").valid;
      case 3:
        return (
          this.multistep.get("userDetails.question4").valid &&
          this.multistep.get("userDetails.question5").valid &&
          this.multistep.get("userDetails.question6").valid &&
          this.multistep.get("userDetails.question7").valid
        );
      case 4:
        return this.multistep.get("userDetails.question8").valid;
      default:
        return false;
    }
  }

  previous() {
    if (this.step > 1) {
      this.step = this.step - 1;
      this.currentPage = this.currentPage - 1;
    }
    const userDetails = this.multistep.value.userDetails;
    const defaultUserDetails = {
      question1: "",
      question2: "",
      question3: "",
      question4: "",
      question5: "",
      question6: "",
      question7: "",
      question8: "",
    };

    const mergedUserDetails = { ...defaultUserDetails, ...userDetails };
    this.selectedValues = { ...mergedUserDetails };
    this.multistep.get("userDetails").patchValue(mergedUserDetails);

    this.multistep.patchValue({
      userDetails: this.formData,
    });
    this.updateQuestionsByPage();
    this.getQuestionsForCurrentStep();
  }

  getValues(id: string, value: number) {
    this.selectedValues[id] = value.toString();
    this.multistep.get("userDetails").patchValue(this.selectedValues);
    this.firstQuestionAnswered = true;
    switch (id) {
      case "question3":
        this.validQuestion3 = false;
        break;
    }
  }

  ngOnInit(): void {
    this.onResize(null);
    this.getData();
    this.route.queryParams.subscribe((params) => {
      this.surveyCode = params["surveyCode"];
    });
  }

  @HostListener("window:resize", ["$event"])
  onResize(event: any) {
    this.isMobile = window.innerWidth <= 768;
  }

  ngOnDestroy() {}

  getData() {
    this.surveyService.GetSurvey().subscribe((response: any) => {
      this.allQuestions = response.data
        .filter((item: any) => item.isActive !== 0)
        .sort((a, b) => {
          if (a.page === b.page) {
            return a.order - b.order;
          }
          return a.page - b.page;
        });

      this.totalPages = Math.max(...this.allQuestions.map((item) => item.page));
      this.lastStep = this.totalPages;
      this.updateQuestionsByPage();
    });
  }

  updateQuestionsByPage() {
    this.dataQuestions = this.allQuestions.filter(
      (question) => question.page === this.currentPage
    );

    console.log(
      `Mostrando data para el step ${this.currentPage}:`,
      this.dataQuestions
    );
  }

  getQuestionsForCurrentStep() {
    console.log(this.step);
    return this.dataQuestions?.filter((q) => q.page === this.step);
  }

  isValidQuestionType(types: string[]): boolean {
    return this.dataQuestions?.some((q) => types.includes(q.type));
  }

  showMessage(title: string, message: string): void {
    this.dialogo
      .open(DialogInformationComponent, {
        data: { title: title, message: message },
      })
      .afterClosed()
      .subscribe((confirmado: Boolean) => {
        console.log(confirmado);
      });
  }

  redirectToHome() {
    this.router.navigate(["/"]);
  }
}
