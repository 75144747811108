<section id="claim"
         class="claim-area uk-claim uk-section uk-section-area">
  <div class="uk-container">
    <div class="uk-section-title section-title uk-align-center">
      <span>{{subtitle}}</span>
      <h2>{{title}}</h2>
    </div>
  </div>
</section>

<section id="project"
         class="project-area uk-project uk-section">
  <div class="uk-container">

    <h4>{{sectionTitle}}</h4>
    <p>{{sectionSubTitle}}</p>
    <div [innerHTML]="sectionDescription">
      {{sectionDescription}}
    </div>
    <br>

    <form novalidate
          focusInvalidInput
          class="bg-card shadow rounded overflow-hidden uk-grid-small uk-form-dcf"
          [formGroup]="formGroup"
          #f="ngForm"
          id="form">
      <div class="select-year uk-width-1-6@m uk-width-1-6@s">
        <mat-form-field class="uk-width-1-1@s"
                        appearance="outline">
          <mat-select [formControlName]="'filters'"
                      [placeholder]="'Seleccionar año'"
                      (selectionChange)="changeCategory($event)"
                      required>
            <mat-option *ngFor="let item of categories"
                        [value]="item.category"> {{ item.category }}</mat-option>

          </mat-select>
        </mat-form-field>

      </div>
    </form>

    <br>

    <div class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-2@m uk-child-width-1-2@s clear">
      <div class="single-project"
           *ngFor="let item of data; let index = index">
        <div class="project-content"
             style="border-radius: 4px;">
          <h3>{{item.name}}</h3>
          <br>
          <p [innerHTML]="item.description">{{item.description}}</p>
          <a class="uk-button uk-button-default"
             href="{{item.button.url}}"
             target="_blank"
             type="button">
            {{item.button.name}}
            <mat-icon class="color-white">download</mat-icon>
          </a>
        </div>
      </div>
    </div>
  </div>
</section>
