<div class="uk-preloader">
  <div class="spinner">
    <div class="double-bounce1"></div>
    <div class="double-bounce2"></div>
  </div>
</div>

<app-header></app-header>

<router-outlet></router-outlet>
<app-wsp-fixed></app-wsp-fixed>

<app-footer></app-footer>
