import { Component, OnInit, NgModule, ViewEncapsulation } from '@angular/core';
import { ConfigurationInfoService } from '../../../services/common/configuration.service';

declare const require: any;

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']

})
export class FaqComponent implements OnInit {

  public title: string = "";
  public subTitle: string = "";
  public data: any;
  public filter: any;

  public sectionDescription: any;
  public sectionTitle: any;
  public sectionSubTitle: any;


  constructor(
    private configurationInfoService: ConfigurationInfoService,
  ) { }

  ngOnInit() {

    this.getData();
  }

  ngOnDestroy() { }


  filterPage(type: number, array: any[]) {
    return array.filter((arr) => arr.id == type);
  }

  getData() {

    this.configurationInfoService.GetPagesContent().subscribe((response: any) => {

      if (response.ok) {
        const data = this.filterPage(
          Number(1),
          response.data.pages
        );

        this.title = data[0].title;
        this.subTitle = data[0].subTitle;

        this.sectionTitle = data[0].sections[0].title;
        this.sectionSubTitle = data[0].sections[0].subTitle;
        this.sectionDescription = data[0].sections[0].sectionDescription;

        this.data = data[0].sections[0].items;
      }

    });
  }

}
