<h3 mat-dialog-title>{{data.title}}</h3>
<div mat-dialog-content>
  <p>{{data.message}}</p>
</div>
<div mat-dialog-actions
     class="mat-dialog-actions">
  <button mat-button
          class="uk-button uk-button-primary uk-width-1-4 uk-margin-small-bottom uk-margin"
          (click)="close()">OK</button>
</div>
