<section id="claim" class="claim-area uk-claim uk-section uk-section-area">
  <div class="uk-container">
    <div class="uk-section-title section-title uk-align-center">
      <span>Jhon Smith | 03 Marzo , 2024</span>
      <h2>Lorem Ipsum</h2>
    </div>
  </div>
</section>
<section class="uk-tips tips-area uk-section">
  <div class="uk-container">
    <div
      class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-1@m uk-child-width-1-1@s"
    >
      <div class="item">
        <div class="tips-content">
          <div class="tips-text">
            <div class="title">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod.
            </div>
            <p>
              Lorem ipsum dolor sit amet consectetur. Maecenas id ac diam
              molestie vitae malesuada pharetra est. Etiam blandit molestie quis
              aliquam nibh libero volutpat. Sit sit lorem id massa felis magna
              auctor enim. Bibendum quisque adipiscing viverra nunc aliquet non
              risus accumsan. Pharetra mauris nunc commodo adipiscing. Commodo
              aliquet amet massa facilisis cras proin nulla a scelerisque. Felis
              enim netus dignissim in. Lorem ipsum dolor sit amet consectetur.
              Maecenas id ac diam molestie vitae malesuada pharetra est. Etiam
              blandit molestie quis aliquam nibh libero volutpat. Sit sit lorem
              id massa felis magna auctor enim. Bibendum quisque adipiscing
              viverra nunc aliquet non risus accumsan. Pharetra mauris nunc
              commodo adipiscing. Commodo aliquet amet massa facilisis cras
              proin nulla a scelerisque. Felis enim netus dignissim in. Lorem
              ipsum dolor sit amet consectetur. Maecenas id ac diam molestie
              vitae malesuada pharetra est. Etiam blandit molestie quis aliquam
              nibh libero volutpat. Sit sit lorem id massa felis magna auctor
              enim. Bibendum quisque adipiscing viverra nunc aliquet non risus
              accumsan. Pharetra mauris nunc commodo adipiscing. Commodo aliquet
              amet massa facilisis cras proin nulla a scelerisque. Felis enim
              netus dignissim in.
            </p>
          </div>
          <div class="tips-text">
            <div class="title">También te puede interesar</div>
            <app-interest-you></app-interest-you>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
