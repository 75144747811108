<section id="claim" class="claim-area uk-claim uk-section uk-section-area">
  <div class="uk-container">
    <div class="uk-section-title section-title uk-align-center">
      <span>RECOMENDACIONES</span>
      <h2>Consejos Útiles</h2>
    </div>
  </div>
</section>
<section class="uk-tips tips-area uk-section">
  <div class="uk-container">
    <div class="search-box">
      <div class="box">
        <div class="input-search">
          <input
            type="text"
            name="search"
            [(ngModel)]="searchText"
            placeholder="Busca consejos"
            (keyup.enter)="performSearch()"
          />
          <button (click)="performSearch()" class="icon-search">
            <img src="assets/img/icon_search_white.svg" alt="" />
          </button>
        </div>
        <div
          class="button-search"
          [class]="showProductOptions ? 'open' : ''"
          (click)="toggleListOptions('products')"
        >
          <div>Producto</div>
          <img src="assets/img/arrow_bottom_white.svg" alt="" />
          <div class="list-options" [class.block]="showProductOptions">
            <div
              class="option"
              *ngFor="let option of allTextsProducts"
              (click)="performSearch(option)"
            >
              {{ option }}
            </div>
          </div>
        </div>
        <div
          class="button-search"
          [class]="showReasonOptions ? 'open' : ''"
          (click)="toggleListOptions('reasons')"
        >
          <div>Motivo</div>
          <img src="assets/img/arrow_bottom_white.svg" alt="" />
          <div class="list-options" [class.block]="showReasonOptions">
            <div
              class="option"
              *ngFor="let option of allTextsReasons"
              (click)="performSearch(option)"
            >
              {{ option }}
            </div>
          </div>
        </div>
      </div>
      <div class="box box-tag">
        <div class="tag" *ngFor="let tag of tags">
          {{ tag }}
          <button (click)="removeTag(tag)">
            <img src="assets/img/icon_close_skyblue.svg" alt="" />
          </button>
        </div>
      </div>
    </div>
    <div
      class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-1@m uk-child-width-1-2@s"
    >
      <div class="item">
        <div class="tips-content">
          <div class="uk-section-title section-title">
            <p class="my-20 all-consejos" *ngIf="!searchPerformed">
              Todos los consejos
            </p>
            <p class="my-20 all-consejos" *ngIf="searchPerformed">
              Resultado de búsqueda
            </p>
            <div class="tips-text">
              <div
                class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-2@m uk-child-width-1-1@s"
              >
                <div
                  class="block"
                  *ngFor="
                    let card of filteredCards
                      ? filteredCards.slice(0, visibleCards)
                      : []
                  "
                >
                  <a class="card" [href]="origin + '/' + card.slug">
                    <div class="box-image">
                      <img
                        src="{{
                          card.pathImage
                            ? card.pathImage
                            : '../../../../assets/img/tips/img_template_banner.jpg'
                        }}"
                        alt=""
                      />
                    </div>
                    <div class="category">
                      <div>{{ card.textProduct }}</div>
                      <div>{{ card.textReason }}</div>
                    </div>
                    <a [href]="origin + '/' + card.slug" class="block">
                      <h3 class="uk-card-title mt-0">{{ card.title }}</h3>
                    </a>
                    <p>{{ card.summary }}</p>
                    <div class="info">
                      <div>{{ card.autor }}</div>
                      <div>{{ card.date }}</div>
                    </div>
                  </a>
                </div>
              </div>
            </div>
            <div
              class="load-more"
              *ngIf="dataTips && dataTips.length > visibleCards"
            >
              <div class="button" (click)="loadMore()">Cargar más</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
