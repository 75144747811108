import { Component, OnInit, ViewEncapsulation } from "@angular/core";

declare const require: any;

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: "app-line-separator",
  templateUrl: "./line-separator.component.html",
  styleUrls: ["./line-separator.component.scss"],
})
export class LineSeparatorComponent implements OnInit {
  constructor() {}

  ngOnInit() {}

  ngOnDestroy() {}

  getData() {}
}
