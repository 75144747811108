<section id="claim"
         class="claim-area uk-claim uk-section uk-section-area">
    <div class="uk-container">
        <div class="uk-section-title section-title uk-align-center">
            <span>{{subTitle}}</span>
            <h2>{{title}}</h2>
        </div>
    </div>
</section>

<section class="uk-section2">
    <div class="uk-container">

        <form class="bg-card shadow rounded overflow-hidden uk-grid-small"
              [formGroup]="formGroup"
              (ngSubmit)="onSubmit(formGroup.value)">

            <fieldset class="uk-fieldset">
                <mat-horizontal-stepper [linear]="true"
                                        #horizontalStepper
                                        (selectionChange)="selectionChange($event)">

                    <mat-step [formGroupName]="'step1'"
                              [stepControl]="formGroup.get('step1')"
                              #horizontalStepperStep1>

                        <h3>{{sectionTitle}}</h3>
                        <h5>{{sectionSubTitle}}</h5>
                        <div [innerHTML]="sectionDescription">
                            {{sectionDescription}}
                        </div>
                        <br>

                        <ul class="questions-list questions uk-margin">
                            <li>
                                <p> a) ¿Has presentado este mismo reclamo previamente al banco y no estás conforme con
                                    la respuesta?</p>
                                <ul>
                                    <li>
                                        <mat-radio-group [formControlName]="'questionA'"
                                                         name="questionA"
                                                         (change)="changeQuestion($event)">
                                            <mat-radio-button class=""
                                                              [value]="'yes'">Sí</mat-radio-button>
                                            <mat-radio-button class=""
                                                              [value]="'no'">No</mat-radio-button>
                                        </mat-radio-group>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <p>b) ¿Ha pasado menos de 2 años del hecho que estás reclamando?</p>
                                <ul>
                                    <li>
                                        <mat-radio-group [formControlName]="'questionB'"
                                                         name="questionB"
                                                         (change)="changeQuestion($event)">
                                            <mat-radio-button class=""
                                                              [value]="'yes'">Sí</mat-radio-button>
                                            <mat-radio-button class=""
                                                              [value]="'no'">No</mat-radio-button>
                                        </mat-radio-group>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <p>c) ¿El monto total reclamado es menor a S/ 70,000.00? Si tu reclamo no tiene que ver
                                    con un monto marca sí.
                                </p>
                                <ul>
                                    <li>
                                        <mat-radio-group [formControlName]="'questionC'"
                                                         name="questionC"
                                                         (change)="changeQuestion($event)">
                                            <mat-radio-button class=""
                                                              [value]="'yes'">Sí</mat-radio-button>
                                            <mat-radio-button class=""
                                                              [value]="'no'">No</mat-radio-button>
                                        </mat-radio-group>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <p>d) ¿Tienes una denuncia en trámite o resuelta sobre lo mismo en Indecopi o Poder
                                    Judicial?</p>
                                <ul>
                                    <li>
                                        <mat-radio-group [formControlName]="'questionD'"
                                                         name="questionD"
                                                         (change)="changeQuestion($event)">
                                            <mat-radio-button class=""
                                                              [value]="'yes'">Sí</mat-radio-button>
                                            <mat-radio-button class=""
                                                              [value]="'no'">No</mat-radio-button>
                                        </mat-radio-group>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                        <div class="uk-margin">
                            <button class="uk-button uk-button-default uk-width-1-1@s uk-width-1-4@m uk-margin-small-bottom"
                                    [disabled]="horizontalStepperStep1.stepControl.pristine || horizontalStepperStep1.stepControl.invalid"
                                    routerLink="/formulario-de-reclamos"
                                    type="button"
                                    matStepperNext
                                    (click)="onNextStepQuestions()">
                                Siguiente
                            </button>
                        </div>
                    </mat-step>

                </mat-horizontal-stepper>
            </fieldset>
        </form>

    </div>
</section>
