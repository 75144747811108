<section id="claim-1" class="claim-area uk-claim uk-section uk-section-area">
  <div class="uk-container">
    <div class="uk-section-title section-title uk-align-center">
      <span>{{ subTitle }}</span>
      <h2>{{ title }}</h2>
    </div>
  </div>
</section>

<section id="claim-2" class="claim-area uk-claim uk-section2">
  <div class="uk-container">
    <h4>{{ sectionTitle }}</h4>
    <div [innerHTML]="sectionDescription">
      {{ sectionDescription }}
    </div>
    <br />
    <p class="mat-error uk-margin-left-15">* Campo obligatorio</p>
    <form
      novalidate
      focusInvalidInput
      class="bg-card shadow rounded overflow-hidden uk-grid-small"
      [formGroup]="formGroup"
      (ngSubmit)="onSubmit()"
      (closeModalEvent)="onClose($event)"
      #f="ngForm"
      id="form"
    >
      <fieldset class="uk-fieldset">
        <div class="uk-grid uk-grid-match uk-grid-medium uk-margin">
          <div class="uk-width-1-3@s">
            <mat-form-field class="uk-width-1-1@s" appearance="outline">
              <mat-label>Nº de DNI, CE, Pasaporte o RUC </mat-label>
              <input
                matInput
                type="text"
                name="nroDocumento"
                id="nroDocumento"
                placeholder="Ingresa aquí tu Nro. de documento"
                formControlName="nroDocumento"
                [minlength]="controlConfig.documentNumber.minLength"
                [maxlength]="controlConfig.documentNumber.maxLength"
                appInputPattern
                [customPatter]="controlConfig.documentNumber.pattern"
                required
              />
              <mat-error
                *ngIf="
                  formGroup.controls['nroDocumento'].invalid &&
                  (formGroup.controls['nroDocumento'].dirty ||
                    formGroup.controls['nroDocumento'].touched)
                "
              >
                <label
                  class="uk-text-small uk-text-danger"
                  *ngIf="formGroup.controls['nroDocumento'].errors['minlength']"
                >
                  {{
                    "Debería tener " +
                      controlConfig.documentNumber.minLength +
                      " como mínimo."
                  }}
                </label>
              </mat-error>

              <mat-icon matPrefix>create</mat-icon>
            </mat-form-field>
          </div>

          <div class="uk-width-1-3@s">
            <mat-form-field appearance="outline">
              <mat-label>Cod. de Reclamo o Nº Expediente</mat-label>
              <input
                matInput
                type="text"
                name="codigoReclamo"
                id="codigoReclamo"
                placeholder="Ingresa aquí tu Cod. Reclamo o Nº Expdte."
                [minlength]="controlConfig.codigoReclamo.minLength"
                formControlName="codigoReclamo"
                appInputPattern
                required
              />

              <mat-error
                *ngIf="
                  formGroup.controls['codigoReclamo'].invalid &&
                  (formGroup.controls['codigoReclamo'].dirty ||
                    formGroup.controls['codigoReclamo'].touched)
                "
              >
                <label
                  class="uk-text-small uk-text-danger"
                  *ngIf="
                    formGroup.controls['codigoReclamo'].errors['minlength']
                  "
                >
                  {{
                    "Debería tener " +
                      controlConfig.codigoReclamo.minLength +
                      " caracteres como
                                    mínimo."
                  }}
                </label>
              </mat-error>

              <mat-icon matPrefix>create</mat-icon>
            </mat-form-field>
          </div>

          <div class="uk-width-1-5@s">
            <button
              id="btnSubmit"
              class="uk-button uk-button-primary uk-margin-small-bottom uk-margin"
              style="height: 63px"
              type="submit"
              color="primary"
              [disabled]="consulting"
            >
              <mat-icon class="color-white">search</mat-icon>

              <span *ngIf="!consulting"> Consultar </span>

              <span *ngIf="consulting">
                Consultando <loading-bar></loading-bar
              ></span>
            </button>
          </div>
        </div>
      </fieldset>
    </form>

    <h4 *ngIf="success">Seguimiento de tu reclamo</h4>
    <br />

    <div class="tracking-wrapper" *ngIf="success">
      <table
        mat-table
        class="uk-table-status"
        [dataSource]="dataSource"
        *ngIf="success"
      >
        <ng-container matColumnDef="codigoSolicitudReclamo">
          <th mat-header-cell *matHeaderCellDef>Código de Reclamo</th>
          <td mat-cell *matCellDef="let element">
            {{ element.codigoSolicitudReclamo }}
          </td>
        </ng-container>

        <ng-container matColumnDef="codigoReclamo">
          <th mat-header-cell *matHeaderCellDef>Nrº de expediente</th>
          <td mat-cell *matCellDef="let element">
            {{ element.codigoReclamo != null ? element.codigoReclamo : "-" }}
          </td>
        </ng-container>

        <ng-container matColumnDef="tipoDocumento">
          <th mat-header-cell *matHeaderCellDef>Tipo Documento</th>
          <td mat-cell *matCellDef="let element">
            {{ element.tipoDocumento }}
          </td>
        </ng-container>

        <ng-container matColumnDef="nroDocumento">
          <th mat-header-cell *matHeaderCellDef>Nro. Documento</th>
          <td mat-cell *matCellDef="let element">{{ element.nroDocumento }}</td>
        </ng-container>

        <ng-container matColumnDef="fechaInicio">
          <th mat-header-cell *matHeaderCellDef>Fecha Inicio</th>
          <td mat-cell *matCellDef="let element">
            {{
              element.codigoTarea > 2
                ? (fechaInicioReclamo | date : "dd/MM/yyyy")
                : "-"
            }}
          </td>
        </ng-container>

        <ng-container matColumnDef="fechaFin">
          <th mat-header-cell *matHeaderCellDef>Fecha Fin</th>
          <td mat-cell *matCellDef="let element">
            {{ (element.fechaLimite | date : "dd/MM/yyyy") || "-" }}
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
    </div>

    <div class="tracking-wrapper" *ngIf="success">
      <div class="timeline uk-margin">
        <clr-timeline [clrLayout]="orientation" class="uk-timeline">
          <clr-timeline-step
            clrState="{{ item.state }}"
            *ngFor="let item of items"
          >
            <clr-timeline-step-header>
              {{ item.header }}
            </clr-timeline-step-header>
            <clr-timeline-step-title>{{ item.title }}</clr-timeline-step-title>
            <clr-timeline-step-description>{{
              item.description
            }}</clr-timeline-step-description>
          </clr-timeline-step>
        </clr-timeline>
      </div>
    </div>

    <section
      id="claim-3"
      class="claim-area uk-claim uk-section-status"
      *ngIf="codigoEstado == 'Conciliación con acta pendiente'"
    >
      <form
        novalidate
        focusInvalidInput
        class="bg-card shadow rounded overflow-hidden uk-grid-small"
        [formGroup]="formGroupConciliacion"
        (ngSubmit)="onSubmitConciliacion()"
        (closeModalEvent)="onClose($event)"
        #f="ngForm"
        id="form"
      >
        <div class="uk-container">
          <div class="uk-section-title section-title uk-align-center">
            <h3>Estado de tu Reclamo</h3>
          </div>
          <h4>Conciliación Propuesta - Acta de Conciliación</h4>
          <div>
            Tu entidad financiera por políticas de atención al cliente, ha
            realizado una propuesta conciliatoria. Si se encuentra de acuerdo
            con esta debe adjuntar el acta firmada (el tamaño max. permitido es
            de 30 MB por archivo en formato Word, PDF o imagen)
          </div>
          <div class="uk-margin">
            <div>
              <a
                href="{{ fileActaConciliacion }}"
                class="btn_conciliacion text-align-left"
                target="_blank"
              >
                Acta de conciliación
                <img src="assets/img/icon_donwload_skyblue.svg" alt="" />
              </a>
            </div>

            <br />
          </div>
          <ul class="questions-list questions uk-margin">
            <li>
              <p>¿Esta usted de Acuerdo con lo propuesto?</p>
              <ul>
                <li>
                  <mat-radio-group
                    formControlName="questionA"
                    name="questionA"
                    (change)="changeQuestion($event)"
                  >
                    <mat-radio-button class="" [value]="'yes'"
                      >Sí</mat-radio-button
                    >
                    <mat-radio-button class="" [value]="'no'"
                      >No</mat-radio-button
                    >
                  </mat-radio-group>
                </li>
              </ul>
            </li>
          </ul>

          <div
            class="uk-margin upload-acta"
            [ngClass]="{ hidden: questionA !== 'yes' }"
          >
            <p>Adjuntar el acta firmada</p>
            <div id="files-ActaConciliacion">
              <label
                class="btn_conciliacion text-align-left"
                (click)="fileInput6.value = null"
              >
                Cargar acta firmada
                <img src="assets/img/icon_attachment_skyblue.svg" alt="" />
                <input
                  #fileInput6
                  type="file"
                  style="display: none"
                  (change)="
                    chooseFiles(fileInput6.files, 'ConciliacionDocuments')
                  "
                  accept=".pdf,.doc,.docx,.jpg,.jpeg,.png,.tiff,.gif"
                  multiple
                />
              </label>

              <div
                *ngIf="
                  selectedConciliacionDocuments &&
                  selectedConciliacionDocuments.length
                "
              >
                <div
                  *ngFor="let item of selectedConciliacionDocuments"
                  class="files-content bg-light text-primary uk-width-1-2"
                >
                  <div><b>Nombre:</b> {{ item.name }}</div>
                  <div><b>Tamaño:</b> {{ humanFileSize(item.size) }}</div>
                  <mat-icon
                    class="color-red"
                    (click)="removeFile(item, 'ConciliacionDocuments')"
                    >remove_circle_outline
                  </mat-icon>
                </div>
                <div *ngIf="uploadingConciliacion" class="mt-2">
                  <ngb-progressbar
                    type="success"
                    showValue="true"
                    [value]="uploadProgress"
                  >
                  </ngb-progressbar>
                </div>
              </div>

              <div
                *ngIf="submissionResults && submissionResults.length"
                class="text-success"
              >
                <p class="font-weight-bold mb-0">Archivo subido:</p>
                <pre>{{ submissionResults | json }}</pre>
              </div>
            </div>

            <br />
          </div>

          <div class="uk-margin" [ngClass]="{ hidden: questionA !== 'no' }">
            <p>¿Por qué no se encuentra de acuerdo?</p>
            <mat-form-field class="uk-width-4-6-1" appearance="outline">
              <mat-label>Ingresa tu consulta</mat-label>
              <textarea
                matInput
                placeholder="Ingrese su consulta"
                formControlName="consulta"
                maxlength="1000"
                matTextareaAutosize
              ></textarea>
              <mat-icon matPrefix> notes</mat-icon>
              <mat-error
                *ngIf="!formGroupConciliacion.controls['consulta'].valid"
              >
                Campo obligatorio, debe tener entre 10 a 1000 caracteres.
              </mat-error>
            </mat-form-field>
          </div>

          <div class="uk-margin">
            <button
              id="btnSubmitConciliacion"
              class="uk-button uk-button-primary uk-width-1-1@s uk-width-1-6@m uk-width-1-6@l uk-width-1-6@xl uk-margin-small-bottom uk-margin"
              type="submit"
              color="primary"
              [disabled]="uploadingConciliacion"
            >
              <mat-icon class="color-white">send</mat-icon>
              <span *ngIf="!uploadingConciliacion"> ENVIAR</span>
              <span *ngIf="uploadingConciliacion">
                Enviando <loading-bar></loading-bar
              ></span>
            </button>
          </div>
        </div>
      </form>
    </section>

    <section>
      <form
        novalidate
        focusInvalidInput
        class="bg-card shadow rounded overflow-hidden uk-grid-small"
        [formGroup]="formGroup"
        (ngSubmit)="onSubmitFile($event)"
        (closeModalEvent)="onClose($event)"
        #f="ngForm"
        id="form"
      >
        <div id="files-DocumentosSustento" *ngIf="UploadFiles">
          <div id="files" *ngIf="UploadFiles">
            <h5>Subsanación</h5>
            <p>
              Por favor, para continuar con tu reclamo debes adjuntar los
              documentos siguientes (el tamaño max. permitido es de 30 MB por
              archivo en formato Word, PDF o imagen):
            </p>
            <ul>
              <li *ngFor="let item of RemedialMessages">{{ item }}</li>
            </ul>

            <div id="files">
              <label
                class="uk-button uk-button-default uk-width-1-3@m uk-margin-small-bottom text-align-left"
                (click)="fileInput6.value = null"
              >
                <mat-icon class="color-white"> attach_file</mat-icon>
                Adjúntalos aquí
                <input
                  #fileInput6
                  type="file"
                  style="display: none"
                  (change)="
                    chooseFiles(fileInput6.files, 'SupportingDocuments')
                  "
                  accept=".pdf,.doc,.docx,.jpg,.jpeg,.png,.tiff,.gif"
                  multiple
                />
              </label>

              <div
                *ngIf="
                  selectedSupportingDocuments &&
                  selectedSupportingDocuments.length
                "
              >
                <div
                  *ngFor="let item of selectedSupportingDocuments"
                  class="files-content bg-light text-primary uk-width-1-2"
                >
                  <div><b>Nombre:</b> {{ item.name }}</div>
                  <div><b>Tamaño:</b> {{ humanFileSize(item.size) }}</div>
                  <mat-icon
                    class="color-red"
                    (click)="removeFile(item, 'SupportingDocuments')"
                    >remove_circle_outline
                  </mat-icon>
                </div>
                <div *ngIf="uploading" class="mt-2">
                  <ngb-progressbar
                    type="success"
                    showValue="true"
                    [value]="uploadProgress"
                  >
                  </ngb-progressbar>
                </div>
              </div>

              <div
                *ngIf="submissionResults && submissionResults.length"
                class="text-success"
              >
                <p class="font-weight-bold mb-0">Archivo subido:</p>
                <pre>{{ submissionResults | json }}</pre>
              </div>
            </div>

            <br />

            <button
              id="btnSubmit"
              class="uk-button uk-button-primary uk-width-1-5@m uk-margin-small-bottom uk-margin"
              type="submit"
              color="primary"
              [disabled]="uploading"
            >
              <mat-icon class="color-white">send</mat-icon>

              <span *ngIf="!uploading"> Enviar adjuntos</span>

              <span *ngIf="uploading">
                Enviando <loading-bar></loading-bar
              ></span>
            </button>
          </div>
        </div>
      </form>
    </section>

    <!-- Conciliación Informada al Cliente -->
    <section
      id="claim-3"
      class="claim-area uk-claim uk-section-status"
      *ngIf="codigoEstado == 'Conciliación informada al cliente'"
    >
      <form
        novalidate
        focusInvalidInput
        class="bg-card shadow rounded overflow-hidden"
        [formGroup]="formGroupConciliacion"
        (ngSubmit)="onSubmitConciliacion()"
        (closeModalEvent)="onClose($event)"
        #f="ngForm"
        id="form"
      >
        <div class="uk-container">
          <div class="uk-section-title section-title uk-align-center">
            <h3>Estado de tu Reclamo</h3>
          </div>
          <h4>Conciliación Propuesta</h4>
          <div>
            Tu entidad financiera por políticas de atención al cliente, ha
            realizado una propuesta conciliatoria.
          </div>
          <div class="uk-margin blue-box">
            <div>
              Estimado (a): {{ nombreCompletoSolicitante }}<br />
              Hemos recibido respuesta el Banco interamericano, en la cual
              señalan lo siguiente:<br />
              {{ conciliacionActual.valorNuevo }}<br />
              Así mismo se adjunta la evidencia de la regularización<br />
              En ese sentido, se le consulta si aceptaría la propuesta
              conciliatoria formulada por la entidad financiera.<br />
              A la espera de su respuesta,<br />
              Saludos,
            </div>
            <div *ngFor="let archivo of conciliacionActual.listaArchivos">
              <a
                [href]="
                  replacePublicUrl(archivo.ubicacion) +
                  '/' +
                  archivo.nombreArchivo
                "
                class="btn_conciliacion text-align-left"
                target="_blank"
              >
                {{ archivo.nombreArchivo }}
                <img src="assets/img/icon_donwload_skyblue.svg" alt="" />
              </a>
            </div>
          </div>
          <ul class="questions-list questions uk-margin">
            <li>
              <p>¿Esta usted de Acuerdo con lo propuesto?</p>
              <ul>
                <li>
                  <mat-radio-group
                    formControlName="questionA"
                    name="questionA"
                    (change)="changeQuestion($event)"
                  >
                    <mat-radio-button class="" [value]="'yes'"
                      >Sí</mat-radio-button
                    >
                    <mat-radio-button class="" [value]="'no'"
                      >No</mat-radio-button
                    >
                  </mat-radio-group>
                </li>
              </ul>
            </li>
          </ul>

          <div class="uk-margin" [ngClass]="{ hidden: questionA !== 'no' }">
            <p>¿Por qué no se encuentra de acuerdo?</p>
            <mat-form-field class="uk-width-4-6-1" appearance="outline">
              <mat-label>Ingresa tu consulta</mat-label>
              <textarea
                matInput
                placeholder="Ingrese su consulta"
                formControlName="consulta"
                maxlength="1000"
                matTextareaAutosize
              ></textarea>
              <mat-icon matPrefix> notes</mat-icon>
              <mat-error
                *ngIf="!formGroupConciliacion.controls['consulta'].valid"
              >
                Campo obligatorio, debe tener entre 10 a 1000 caracteres.
              </mat-error>
            </mat-form-field>
          </div>

          <div class="uk-margin">
            <button
              id="btnSubmitConciliacion"
              class="uk-button uk-button-primary uk-width-1-1@s uk-width-1-6@m uk-width-1-6@l uk-width-1-6@xl uk-margin-small-bottom uk-margin"
              type="submit"
              color="primary"
              [disabled]="uploadingConciliacion"
            >
              <mat-icon class="color-white">send</mat-icon>
              <span *ngIf="!uploadingConciliacion"> ENVIAR</span>
              <span *ngIf="uploadingConciliacion">
                Enviando <loading-bar></loading-bar
              ></span>
            </button>
          </div>
        </div>
      </form>
    </section>

    <!-- Conciliaciones Historicas -->
    <section id="claim-3" class="claim-area uk-claim uk-section-status">
      <div
        *ngIf="conciliacionesHistoricas && conciliacionesHistoricas.length > 0"
      >
        <app-line-separator></app-line-separator>
        <div class="uk-container">
          <div class="uk-section-title mb-30 uk-align-center">
            <h3>Propuestas conciliatorias anteriores</h3>
          </div>
        </div>
      </div>
      <mat-accordion>
        <mat-expansion-panel
          *ngFor="let conciliacionAnterior of conciliacionesHistoricas"
        >
          <mat-expansion-panel-header>
            <mat-panel-title>
              Conciliación propuesta - {{ conciliacionAnterior.fechaInicio }}
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="uk-container">
            <div>
              Tu entidad financiera por políticas de atención al cliente, ha
              realizado una propuesta conciliatoria, en la cual señalan lo
              siguiente:
            </div>
            <div class="uk-margin blue-box">
              <div>
                Estimado (a): {{ nombreCompletoSolicitante }}<br />
                Hemos recibido respuesta el Banco interamericano, en la cual
                señalan lo siguiente:<br />
                {{ conciliacionAnterior.valorNuevo }}<br />
                Así mismo se adjunta la evidencia de la regularización<br />
                En ese sentido, se le consulta si aceptaría la propuesta
                conciliatoria formulada por la entidad financiera.<br />
                A la espera de su respuesta,<br />
                Saludos,
              </div>
              <div
                *ngFor="let archivo of conciliacionAnterior.listaArchivos"
                class="mt-10"
              >
                <a
                  [href]="
                    replacePublicUrl(archivo.ubicacion) +
                    '/' +
                    archivo.nombreArchivo
                  "
                  class="btn_conciliacion text-align-left"
                  target="_blank"
                >
                  {{ archivo.nombreArchivo }}
                  <img src="assets/img/icon_donwload_skyblue.svg" alt="" />
                </a>
              </div>
            </div>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </section>
  </div>
</section>
