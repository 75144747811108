import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { ConfigurationInfoService } from '../../../../services/common/configuration.service';
import { DomSanitizer } from '@angular/platform-browser';

declare const require: any;

@Component({
  selector: 'section-content',
  templateUrl: './section-content.component.html'
})
export class SectionContentComponent implements OnInit {

  public query: string = "";
  public active: string = "";
  public response: any;
  public content: string = "";
  public items: any;
  public data: any;
  public sectionTitle: any;
  public sectionDescription: any;

  constructor(
    private router: Router,
    private configurationInfoService: ConfigurationInfoService,
    private route: ActivatedRoute,
    private domSanitizer: DomSanitizer,
    private readonly httpClient: HttpClient) {
    route.queryParams.subscribe(
      data => this.query = data['categoria']);
  }

  ngOnInit() {

    this.getData();
  }

  ngOnDestroy() { }

  filterById(id: string, data: any) {
    return data.filter(object => {
      return object['urlFriendly'] == id;
    });
  }

  filterPage(type: number, array: any[]) {
    return array.filter((arr) => arr.id == type);
  }

  getData() {

    this.configurationInfoService.GetPagesContent().subscribe((response: any) => {

      if (response.ok) {
        const data = this.filterPage(
          Number(8),
          response.data.pages
        );

        this.response = data[0].sections;

        let datainfo = this.filterById(this.query, this.response);

        this.sectionTitle = datainfo[0].title;

        this.sectionDescription = datainfo[0].description;

        datainfo[0].items.forEach((element) => {
          element.description = this.domSanitizer.bypassSecurityTrustHtml(element.description);
        });

        this.items = datainfo[0].items;
      }

    });
  }
}
