import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
  ViewEncapsulation,
} from "@angular/core";
import { MatRadioButton, MatRadioChange } from "@angular/material/radio";
import { CustomvalidationService } from "../../../services/custom-validation.service";
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  UntypedFormArray,
  UntypedFormControl,
  Validators,
} from "@angular/forms";
import { Observable, tap } from "rxjs";
import {
  RxwebValidators,
  NumericValueType,
} from "@rxweb/reactive-form-validators";
import {
  HttpClient,
  HttpRequest,
  HttpEventType,
  HttpResponse,
  HttpHeaders,
} from "@angular/common/http";
import { finalize } from "rxjs/operators";
import { Router } from "@angular/router";
import { StyleService } from "../../../services/style.service";
import { CacheService } from "../../../services/cache.service";
import { environment } from "../../../../environments/environment";
import { ConfigurationInfoService } from "../../../services/common/configuration.service";
import { ReCaptchaV3Service } from "ng-recaptcha";
import { MatDialog } from "@angular/material/dialog";
import { DialogInformationComponent } from "../../../shared/dialog/dialog-information.component";

export interface IControlValidation {
  minLength: string;
  maxLength: string;
  pattern?: string;
}

declare const require: any;

interface CertificateSubmissionResult {
  fileName: string;
  fileSize: number;
}

interface StudentFormSubmissionResult {}

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: "claims",
  templateUrl: "./claims.component.html",
  styleUrls: ["./claims.components.scss"],
})
export class ClaimsComponent implements OnInit {
  @Output() closeModalEvent = new EventEmitter<boolean>();

  formGroup: UntypedFormGroup;
  submitted = false;
  success = false;
  error = false;

  verticalStepperForm: UntypedFormGroup;
  titleAlert: string = "This field is required";
  post: any = "";

  obligated: any = "";

  selectedDNI: File[];
  selectedBankClaimResponse: File[];
  selectedClaimSheet: File[];
  selectedVigenciaPoder: File[];
  selectedDeclaracionAnual: File[];
  selectedSupportingDocuments: File[];

  //Upload
  uploadProgress = 0;
  uploading = false;
  errorMsg = "";
  resultMsg = "";
  submissionResults: StudentFormSubmissionResult[] = [];
  requerido = false;

  tipoPersona: any[];
  documentTypes: any[];
  tipoProducto: any[];
  entidadFinanciera: any[];
  currencyType: any[];
  codigoListaDefensoria: any[];

  showSuggestionsList: boolean = false;
  suggestionOpened: boolean = false;
  originalSuggestions: string[] = [
    "@gmail.com",
    "@hotmail.com",
    "@yahoo.com",
    "@yahoo.es",
    "@outlook.com",
  ];

  suggestions: string[] = [...this.originalSuggestions];

  controlConfig: {
    documentNumber: IControlValidation;
  } = {
    documentNumber: {
      minLength: "8",
      maxLength: "8",
      pattern: "onlyNumbers",
    },
  };

  isMarkeddocumentTypes = true;
  isMarkedNumeroDocumento = true;
  isMarkedRazonSocial = true;
  isMarkedName = true;

  requiredValidator = Validators.required;

  public title: string = "";
  public subTitle: string = "";
  public data: any;

  constructor(
    private recaptchaV3Service: ReCaptchaV3Service,
    private formBuilder: UntypedFormBuilder,
    private readonly httpClient: HttpClient,
    private styleService: StyleService,
    private cacheService: CacheService,
    private customValidator: CustomvalidationService,
    private router: Router,
    private configurationInfoService: ConfigurationInfoService,
    public dialogo: MatDialog
  ) {}

  @ViewChild("fileInput1") fileInput1: ElementRef;

  onClose(event: any) {
    this.closeModalEvent.emit(false);
  }

  RESPUESTAS_LIST = [
    {
      id: "1",
      value:
        "Para poder presentar un reclamo ante la Defensoría, primero debe realizar un reclamo formal ante su banco y haber obtenido una respuesta negativa, o no haber obtenido respuesta en el plazo establecido o prorrogado para su atención.",
    },
    {
      id: "2",
      value:
        "La Defensoría sólo puede atender reclamos cuyos hechos no superen los 2 años de antigüedad a la fecha.",
    },
    {
      id: "3",
      value:
        "La Defensoría no es competente para tramitar reclamos cuya cuantía exceda los S/. 70 000,00 (Setenta mil y 00/100 Soles) o su equivalente en otras monedas.",
    },
    {
      id: "4",
      value:
        "La Defensoría no es competente para tramitar reclamos que se encuentren en procedimientos administrativos o procesos judiciales o arbitrales en trámite; o, que hayan sido ya resueltos por cualquier otra autoridad administrativa, judicial o arbitral.",
    },
  ];

  ngOnInit(): void {
    this.resetInputsFiles();

    this.createForm();

    setTimeout(() => {
      this.getData();
    }, 500);

    this.styleService.addStyle(
      "claim-form-wizard",
      require("./claims.component.scss")
    );
  }

  ngOnDestroy() {
    this.styleService.removeStyle("claim-form-wizard");
  }

  getData() {
    this.configurationInfoService
      .GetPagesContent()
      .subscribe((response: any) => {
        if (response.ok) {
          const data = this.filterPage(Number(6), response.data.pages);

          this.title = data[0].title;
          this.subTitle = data[0].subTitle;
          this.data = data[0].sections;
        }
      });

    let cache = this.cacheService.loadConfigurationInfo();

    if (cache != null) {
      const objCache = JSON.parse(cache);

      this.tipoPersona = objCache.data.personTypes;

      if (objCache.ok && this.cacheService.loadClaimForm() != null) {
        this.setData(objCache, false);
      } else {
        this.setData(objCache, true);
      }
    } else {
      this.configurationInfoService
        .GetSettingsInfo()
        .subscribe((response: any) => {
          this.tipoPersona = response.data.personTypes;
          this.setData(response, true);
        });
    }
  }

  setData(objCache: any, isCache: boolean) {
    if (isCache) {
      this.cacheService.saveConfigurationInfo(objCache);
      this.documentTypes = this.filterByPersona(1, objCache.data.documentTypes);
      this.formGroup.controls["documentTypes"].setValue(
        this.documentTypes[0].id
      );
      this.formGroup.controls["numeroDocumento"].enable();
    } else {
      setTimeout(() => {
        this.getCacheForm(objCache);
      }, 1000);
    }

    this.entidadFinanciera = objCache.data.financialEntities;
    this.currencyType = objCache.data.currencyType;
    this.codigoListaDefensoria = objCache.data.listDefense;
  }

  getErrorMessage() {
    return this.formGroup.controls["email"].hasError("required")
      ? "You must enter a value"
      : this.formGroup.controls["email"].hasError("email")
      ? "Not a valid email"
      : "";
  }

  createForm() {
    let emailregex: RegExp =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    let MOBILE_PATTERN = /[0-9\+\-\ ]/;

    this.formGroup = this.formBuilder.group({
      tipoPersona: [null, Validators.required],
      documentTypes: [null, Validators.required],
      numeroDocumento: [
        null,
        Validators.compose([
          Validators.required,
          Validators.minLength(
            Number(this.controlConfig.documentNumber.minLength)
          ),
          Validators.minLength(
            Number(this.controlConfig.documentNumber.maxLength)
          ),
        ]),
      ],
      obligated: [""],
      razonSocial: [""],
      nombres: ["", [Validators.required]],
      apellidoPaterno: ["", [Validators.required, Validators.minLength(3)]],
      apellidoMaterno: ["", [Validators.required, Validators.minLength(3)]],
      celular: [
        "",
        [Validators.maxLength(9), Validators.pattern(MOBILE_PATTERN)],
      ],
      email: ["", [Validators.required, Validators.email]],
      entidadFinanciera: ["", Validators.required],
      descriptionHechos: [
        null,
        [
          Validators.required,
          Validators.minLength(10),
          Validators.maxLength(5000),
        ],
      ],
      descriptionSolucion: [
        null,
        [
          Validators.required,
          Validators.minLength(10),
          Validators.maxLength(5000),
        ],
      ],
      codigoListaDefensoria: ["", Validators.required],
      aceptoTerminos: [false, [Validators.requiredTrue]],
      leidoTerminos: [false, [Validators.requiredTrue]],
      amounts: this.formBuilder.array([
        this.formBuilder.group({
          currencyType: "",
          amount: [
            null,
            [
              Validators.required,
              RxwebValidators.numeric({
                acceptValue: NumericValueType.PositiveNumber,
                allowDecimal: true,
                isFormat: true,
              }),
            ],
          ],
        }),
      ]),
    });

    this.formGroup.controls["tipoPersona"].setValue(1);
    this.formGroup.controls["numeroDocumento"].disable();

    this.formGroup.valueChanges
      .pipe(tap((formValue) => this.cacheService.saveClaimForm(formValue)))
      .subscribe();
  }

  getCacheForm(dataCache: any) {
    let formValue = JSON.parse(this.cacheService.loadClaimForm());

    if (formValue != null) {
      this.formGroup.controls["tipoPersona"].setValue(formValue.tipoPersona);
      this.documentTypes = this.filterByPersona(
        formValue.tipoPersona,
        dataCache.data.documentTypes
      );

      this.formGroup.controls["documentTypes"].setValue(
        formValue.documentTypes
      );
      this.formGroup.controls["numeroDocumento"].setValue(
        formValue.numeroDocumento
      );
      this.formGroup.controls["numeroDocumento"].enable();

      this.formGroup.controls["apellidoMaterno"].setValue(
        formValue.apellidoMaterno
      );
      this.formGroup.controls["apellidoPaterno"].setValue(
        formValue.apellidoPaterno
      );
      this.formGroup.controls["nombres"].setValue(formValue.nombres);
      this.formGroup.controls["celular"].setValue(formValue.celular);
      this.formGroup.controls["email"].setValue(formValue.email);
      this.formGroup.controls["entidadFinanciera"].setValue(
        formValue.entidadFinanciera
      );
      this.formGroup.controls["descriptionHechos"].setValue(
        formValue.descriptionHechos
      );
      this.formGroup.controls["descriptionSolucion"].setValue(
        formValue.descriptionSolucion
      );
      this.formGroup.controls["codigoListaDefensoria"].setValue(
        formValue.codigoListaDefensoria
      );
      this.formGroup.controls["codigoListaDefensoria"].setValue(
        formValue.codigoListaDefensoria
      );

      this.changeDocumentType(formValue);
    }
  }

  changeDocumentType(data: any) {
    if (data.tipoPersona == 1) {
      switch (data.documentTypes) {
        case 2:
          this.controlConfig.documentNumber.minLength = "8";
          this.controlConfig.documentNumber.maxLength = "8"; // DNI
          this.controlConfig.documentNumber.pattern = "onlyNumbers";
          break;
        case 3: //Carnet de extranjeria
        case 4: //Pasaporte
        case 5: //Cedula Diplomatica
          this.controlConfig.documentNumber.minLength = "1";
          this.controlConfig.documentNumber.maxLength = "15";
          this.controlConfig.documentNumber.pattern = "alphaNumeric";
          break;
        default:
          this.formGroup.controls["numeroDocumento"].disable();
          break;
      }
    } else {
      switch (data.documentTypes) {
        case 1:
          this.controlConfig.documentNumber.minLength = "11";
          this.controlConfig.documentNumber.maxLength = "11";
          this.controlConfig.documentNumber.pattern = "onlyNumbers";
          break;
        default:
          this.formGroup.controls["numeroDocumento"].disable();
          break;
      }
    }

    this.formGroup
      .get("numeroDocumento")
      .setValidators([
        Validators.required,
        Validators.minLength(
          Number(this.controlConfig.documentNumber.minLength)
        ),
        Validators.maxLength(
          Number(this.controlConfig.documentNumber.maxLength)
        ),
        Validators.pattern("^.+$"),
      ]);

    this.formGroup.updateValueAndValidity();
  }

  changetipoDocumento(event: any) {
    this.formGroup.valid ? (this.requerido = true) : (this.requerido = false);

    let configurationInfo = JSON.parse(
      this.cacheService.loadConfigurationInfo()
    );

    this.documentTypes = this.filterByPersona(
      Number(this.formGroup.get("tipoPersona").value),
      configurationInfo.data.documentTypes
    );

    this.changePersonType(
      { value: this.formGroup.get("tipoPersona").value },
      false
    );
  }

  showSuggestions(inputValue: string): void {
    if (inputValue.includes("@")) {
      this.showSuggestionsList = true;
    } else {
      this.showSuggestionsList = false;
    }
  }

  openSuggestions(event: any): void {
    const emailValue: string = event.target.value;
    if (emailValue.includes("@")) {
      const atIndex: number = emailValue.indexOf("@");
      const domainStartIndex: number = atIndex + 1;
      const domain: string = emailValue.slice(domainStartIndex).toLowerCase();

      const filteredSuggestions = this.originalSuggestions.filter(
        (suggestion) => suggestion.includes(domain)
      );
      this.suggestions =
        filteredSuggestions.length > 0
          ? filteredSuggestions
          : this.originalSuggestions;

      this.showSuggestionsList = this.suggestions.length > 0;
      this.suggestionOpened = true;
    }
  }

  closeSuggestions(): void {
    setTimeout(() => {
      if (this.showSuggestionsList && !this.suggestionOpened) {
        this.showSuggestionsList = false;
      }
      this.suggestionOpened = false;
    }, 200);
  }

  selectSuggestion(suggestion: string): void {
    const currentValue = this.formGroup.controls["email"].value;
    const [prefix, _] = currentValue.split("@");
    const newValue = prefix + suggestion;
    this.formGroup.controls["email"].setValue(newValue);
    this.showSuggestionsList = false;
  }

  onKeyUp(event: KeyboardEvent): void {
    if (
      (event.key === "Backspace" || event.key === "Delete") &&
      this.formGroup.controls["email"].value === ""
    ) {
      this.onEmailChange();
    }
    console.log(event.key);
  }

  onEmailChange(): void {
    const emailValue: string = this.formGroup.controls["email"].value;
    if (emailValue === "") {
      this.suggestions = [];
      this.showSuggestionsList = false;
    }
    this.suggestionOpened = false;
  }

  /////// amounts start /////////////////

  get amountsItems() {
    return this.formGroup.get("amounts") as UntypedFormArray;
  }

  get codigoListaDefensoriaItems() {
    return this.formGroup.get("codigoListaDefensoria") as UntypedFormArray;
  }

  addSellingPoint() {
    if (this.amountsItems.length < 3) {
      this.amountsItems.push(
        this.formBuilder.group({
          currencyType: "",
          amount: [
            null,
            [
              Validators.required,
              RxwebValidators.numeric({
                acceptValue: NumericValueType.PositiveNumber,
                allowDecimal: true,
                isFormat: true,
              }),
            ],
          ],
        })
      );
    }
  }

  deleteSellingPoint(index) {
    this.amountsItems.removeAt(index);
  }

  resetInputsFiles() {
    this.selectedDNI = [];
    this.selectedBankClaimResponse = [];
    this.selectedClaimSheet = [];
    this.selectedSupportingDocuments = [];
    this.selectedVigenciaPoder = [];
    this.selectedDeclaracionAnual = [];
  }

  resetForm() {
    this.amountsItems.clear();
    this.resetInputsFiles();
    this.addSellingPoint();

    this.formGroup.get("numeroDocumento").reset();
    this.formGroup.get("nombres").reset();
    this.formGroup.get("apellidoMaterno").reset();
    this.formGroup.get("apellidoPaterno").reset();
    this.formGroup.get("razonSocial").reset();
    this.formGroup.get("celular").reset();
    this.formGroup.get("email").reset();
    this.formGroup.get("celular").reset();
    this.formGroup.get("entidadFinanciera").reset();
    this.formGroup.get("descriptionHechos").reset();
    this.formGroup.get("descriptionSolucion").reset();
    this.formGroup.get("codigoListaDefensoria").reset();

    this.formGroup.controls["numeroDocumento"].enable();

    this.formGroup.controls["aceptoTerminos"].setValue(false);
    this.formGroup.controls["leidoTerminos"].setValue(false);

    this.formGroup.controls["amounts"].setErrors(null);

    this.formGroup.controls["entidadFinanciera"].setErrors(null);
    this.formGroup.controls["descriptionHechos"].setErrors(null);
    this.formGroup.controls["descriptionSolucion"].setErrors(null);
    this.formGroup.controls["numeroDocumento"].setErrors(null);
    this.formGroup.controls["numeroDocumento"].setErrors(null);
    this.formGroup.controls["nombres"].setErrors(null);
    this.formGroup.controls["apellidoMaterno"].setErrors(null);
    this.formGroup.controls["apellidoPaterno"].setErrors(null);
    this.formGroup.controls["razonSocial"].setErrors(null);
    this.formGroup.controls["celular"].setErrors(null);
    this.formGroup.controls["email"].setErrors(null);
    this.formGroup.controls["aceptoTerminos"].setErrors(null);
    this.formGroup.controls["leidoTerminos"].setErrors(null);
    this.formGroup.controls["codigoListaDefensoria"].setErrors(null);
  }
  ////////////  amounts End ////////////////////

  keyUp(input: string, event: any) {
    switch (input) {
      case "names":
        event.target.value.length > 0
          ? (this.isMarkedName = false)
          : (this.isMarkedName = true);
        break;
      case "razonSocial":
        event.target.value.length > 0
          ? (this.isMarkedRazonSocial = false)
          : (this.isMarkedRazonSocial = true);
        break;
      case "numeroDocumento":
        event.target.value.length > 0
          ? (this.isMarkedNumeroDocumento = false)
          : (this.isMarkedNumeroDocumento = true);
        break;
    }
  }

  filterPage(type: number, array: any[]) {
    return array.filter((arr) => arr.id == type);
  }

  filterByPersona(type: number, array: any[]) {
    return type == 1
      ? array.filter((arr) => arr.name !== "RUC")
      : array.filter((arr) => arr.name === "RUC");
  }

  filterByMoneyType(type: number, array: any[]) {
    return array.filter((arr) => arr.id !== type);
  }

  changeMoneyType(event: any) {
    let configurationInfo = JSON.parse(
      this.cacheService.loadConfigurationInfo()
    );

    this.documentTypes = this.filterByMoneyType(
      Number(this.formGroup.get("currencyType").value),
      configurationInfo.data.currencyType
    );
  }

  changePersonType(event: any, active: boolean) {
    let documentType: number;

    if (active) {
      let configurationInfo = JSON.parse(
        this.cacheService.loadConfigurationInfo()
      );

      this.documentTypes = this.filterByPersona(
        event.value,
        configurationInfo.data.documentTypes
      );

      this.formGroup.controls["documentTypes"].setValue(
        this.documentTypes[0].id
      );
    }

    let value = event.value;

    if (value === 0) {
      this.isMarkeddocumentTypes = true;
    } else {
      this.isMarkeddocumentTypes = false;

      this.formGroup.get("numeroDocumento").setValue("");

      this.formGroup.controls["numeroDocumento"].enable();

      if (value == 1) {
        //tipoPersona

        this.formGroup.get("nombres").reset();
        this.formGroup.get("nombres").setValidators([Validators.required]);
        this.formGroup.get("nombres").updateValueAndValidity();

        this.formGroup.get("apellidoPaterno").reset();
        this.formGroup
          .get("apellidoPaterno")
          .setValidators([Validators.required, Validators.minLength(3)]);

        this.formGroup.get("apellidoMaterno").reset();
        this.formGroup
          .get("apellidoMaterno")
          .setValidators([Validators.required, Validators.minLength(3)]);

        this.formGroup.get("razonSocial").reset();
        this.formGroup.get("razonSocial").clearValidators();
        this.formGroup.get("razonSocial").updateValueAndValidity();

        switch (this.formGroup.get("documentTypes").value) {
          case 2:
            this.controlConfig.documentNumber.minLength = "8";
            this.controlConfig.documentNumber.maxLength = "8"; // DNI
            this.controlConfig.documentNumber.pattern = "onlyNumbers";
            break;
          case 3: //Carnet de extranjeria
          case 4: //Pasaporte
          case 5: //Cedula Diplomatica
            this.controlConfig.documentNumber.minLength = "1";
            this.controlConfig.documentNumber.maxLength = "15";
            this.controlConfig.documentNumber.pattern = "alphaNumeric";
            break;
          default:
            this.formGroup.controls["numeroDocumento"].disable();
            break;
        }
      } else {
        this.formGroup.get("nombres").clearValidators();
        this.formGroup.get("nombres").updateValueAndValidity();

        this.formGroup.get("razonSocial").reset();
        this.formGroup.get("apellidoPaterno").reset();
        this.formGroup.get("apellidoMaterno").reset();

        this.formGroup.get("apellidoPaterno").clearValidators();
        this.formGroup.get("apellidoPaterno").updateValueAndValidity();

        this.formGroup.get("apellidoMaterno").clearValidators();
        this.formGroup.get("apellidoMaterno").updateValueAndValidity();

        switch (this.formGroup.get("documentTypes").value) {
          case 1:
            this.controlConfig.documentNumber.minLength = "11";
            this.controlConfig.documentNumber.maxLength = "11";
            this.controlConfig.documentNumber.pattern = "onlyNumbers";
            break;
          default:
            this.formGroup.controls["numeroDocumento"].disable();
            break;
        }
      }

      this.formGroup
        .get("numeroDocumento")
        .setValidators([
          Validators.required,
          Validators.minLength(
            Number(this.controlConfig.documentNumber.minLength)
          ),
          Validators.maxLength(
            Number(this.controlConfig.documentNumber.maxLength)
          ),
          Validators.pattern("^.+$"),
        ]);

      this.formGroup.updateValueAndValidity();
    }
  }

  changeCurrencyType(event: any, index: any) {
    if (event.value == 4) {
      (
        (this.formGroup.get("amounts") as UntypedFormArray).at(
          index
        ) as UntypedFormGroup
      )
        .get("amount")
        .disable();
    } else {
      (
        (this.formGroup.get("amounts") as UntypedFormArray).at(
          index
        ) as UntypedFormGroup
      )
        .get("amount")
        .enable();
    }
  }

  setChangeValidate() {
    this.formGroup.get("validate").valueChanges.subscribe((validate) => {
      if (validate == "1") {
        this.formGroup
          .get("name")
          .setValidators([Validators.required, Validators.minLength(3)]);
        this.titleAlert = "You need to specify at least 3 characters";
      } else {
        this.formGroup.get("name").setValidators(Validators.required);
      }
      this.formGroup.get("name").updateValueAndValidity();
    });
  }

  changeObligated(mrChange: MatRadioChange) {
    let mrButton: MatRadioButton = mrChange.source;

    switch (mrButton.name) {
      case "obligated":
        this.obligated = mrButton.value;
        break;
      default:
        break;
    }
  }

  selectionChange(event) {
    if (event.isUserInput) {
      console.log(event.source.value, event.source.selected);
    }
  }

  get registerFormControl() {
    return this.formGroup.controls;
  }

  get f() {
    return this.formGroup.controls;
  }

  public findInvalidControls() {
    const invalid = [];
    const controls = this.formGroup.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        invalid.push(name);
      }
    }
    return invalid;
  }

  onSubmit(event: any): void {
    this.submitted = true;

    event.preventDefault();

    //Persona Jurídica
    if (this.formGroup.get("tipoPersona").value === 2) {
      this.formGroup.get("nombres").clearValidators();
      this.formGroup.get("nombres").updateValueAndValidity();

      this.formGroup.get("apellidoMaterno").clearValidators();
      this.formGroup.get("apellidoMaterno").updateValueAndValidity();

      this.formGroup.get("apellidoPaterno").clearValidators();
      this.formGroup.get("apellidoPaterno").updateValueAndValidity();

      this.formGroup.get("razonSocial").setValidators([Validators.required]);

      if (!this.selectedDNI || this.selectedDNI.length === 0) {
        this.errorMsg =
          "Por favor, adjunte la copia de dni por ambos lados del representante legal.";
        return;
      }

      if (
        !this.selectedVigenciaPoder ||
        this.selectedVigenciaPoder.length === 0
      ) {
        this.errorMsg =
          "Por favor, adjunte la Vigencia de Poder del Representante Legal.";
        return;
      }

      if (
        this.obligated == 1 &&
        (!this.selectedDeclaracionAnual ||
          this.selectedDeclaracionAnual.length === 0)
      ) {
        this.errorMsg =
          "Por favor, adjunte la Declaración Anual de Imp. a la Renta.";
        return;
      }
    } else {
      // Persona natural

      this.formGroup.get("nombres").setValidators([Validators.required]);
      this.formGroup.get("nombres").updateValueAndValidity();

      this.formGroup
        .get("apellidoPaterno")
        .setValidators([Validators.required]);
      this.formGroup.get("apellidoPaterno").updateValueAndValidity();

      this.formGroup
        .get("apellidoMaterno")
        .setValidators([Validators.required]);
      this.formGroup.get("apellidoMaterno").updateValueAndValidity();

      this.formGroup.get("razonSocial").clearValidators();
      this.formGroup.get("razonSocial").updateValueAndValidity();

      if (!this.selectedDNI || this.selectedDNI.length === 0) {
        this.errorMsg = "Por favor, adjunte su copia de dni por ambos lados.";
        return;
      }
    }

    if (
      (!this.selectedBankClaimResponse ||
        this.selectedBankClaimResponse.length === 0) &&
      (!this.selectedClaimSheet || this.selectedClaimSheet.length === 0)
    ) {
      this.errorMsg =
        "Por favor, adjunte la respuesta de reclamo del banco o la hoja de reclamación.";
      return;
    }

    if (this.formGroup.valid) {
      let amounts: any[];

      let formData = new FormData();

      formData.append("tipoPersona", this.formGroup.get("tipoPersona").value);
      formData.append(
        "tipoDocumento",
        this.formGroup.get("documentTypes").value
      );
      formData.append(
        "numeroDocumento",
        this.formGroup.get("numeroDocumento").value
      );
      formData.append("nombres", this.formGroup.get("nombres").value);
      formData.append("razonSocial", this.formGroup.get("razonSocial").value);
      formData.append(
        "apellidoPaterno",
        this.formGroup.get("apellidoPaterno").value
      );
      formData.append(
        "apellidoMaterno",
        this.formGroup.get("apellidoMaterno").value
      );
      formData.append("celular", this.formGroup.get("celular").value);
      formData.append("email", this.formGroup.get("email").value);

      formData.append(
        "entidadFinanciera",
        this.formGroup.get("entidadFinanciera").value
      );
      formData.append(
        "descripcionHechos",
        this.formGroup.get("descriptionHechos").value
      );
      formData.append(
        "solucionEsperada",
        this.formGroup.get("descriptionSolucion").value
      );
      formData.append(
        "codigoListaDefensoria",
        this.formGroup.get("codigoListaDefensoria").value
      );
      formData.append(
        "aceptoTerminos",
        this.formGroup.get("aceptoTerminos").value
      );
      formData.append(
        "leidoTerminos",
        this.formGroup.get("leidoTerminos").value
      );

      amounts = this.formGroup.get("amounts").value;
      let amountCount = 0;
      for (let i = 0; i < amounts.length; i++) {
        if (amounts[i].currencyType != 4) {
          formData.append(
            "Amounts[" + amountCount + "].CurrencyType",
            amounts[i].currencyType
          );
          formData.append(
            "Amounts[" + amountCount + "].Amount",
            amounts[i].amount
          );
          amountCount++;
        }
      }

      if (this.formGroup.get("codigoListaDefensoria") == null) {
        this.formGroup.value.codigoListaDefensoria = null;
      }

      this.selectedDNI.forEach((f) => formData.append("CopiaDNI", f));

      if (this.selectedBankClaimResponse != null)
        this.selectedBankClaimResponse.forEach((f) =>
          formData.append("RespuestaReclamoBanco", f)
        );

      if (this.selectedClaimSheet != null)
        this.selectedClaimSheet.forEach((f) =>
          formData.append("HojaReclamacion", f)
        );

      if (this.selectedSupportingDocuments != null)
        this.selectedSupportingDocuments.forEach((f) =>
          formData.append("DocumentosSustento", f)
        );

      if (this.selectedVigenciaPoder != null)
        this.selectedVigenciaPoder.forEach((f) =>
          formData.append("VigenciaPoder", f)
        );

      if (this.selectedDeclaracionAnual != null)
        this.selectedDeclaracionAnual.forEach((f) =>
          formData.append("DeclaracionAnual", f)
        );

      this.uploading = true;

      this.recaptchaV3Service
        .execute("importantAction")
        .subscribe((token: string) => {
          const options = {
            reportProgress: true,
            headers: new HttpHeaders({
              "g-recaptcha-response": token,
            }),
          };

          const req = new HttpRequest(
            "POST",
            `${environment.urlApiDCF}ClaimManagement/ClaimsRegister`,
            formData,
            options
          );

          this.httpClient
            .request<StudentFormSubmissionResult[]>(req)
            .pipe(
              finalize(() => {
                this.uploading = false;
                this.submitted = false;
              })
            )
            .subscribe(
              (response) => {
                if (response.type === HttpEventType.UploadProgress) {
                  this.uploadProgress = Math.round(
                    (100 * response.loaded) / response.total
                  );
                } else if (response instanceof HttpResponse) {
                  let res = JSON.parse(JSON.stringify(response, null, 2));

                  if (res.ok) {
                    this.showMessage(
                      "Mensaje de confirmación",
                      `Su reclamo se ha enviado correctamente.`,
                      res.body.data
                    );
                    this.resetForm();
                  } else {
                    this.showMessage(
                      "Mensaje de error",
                      "Error al enviar el reclamo. Por favor inténtelo de nuevo."
                    );
                  }
                }
              },
              (errorData) => {
                this.success = false;
                this.error = true;
                this.showMessage(
                  "Mensaje de error",
                  "Error al enviar el reclamo. Por favor inténtelo de nuevo."
                );
              }
            );
        });
    } else {
      return;
    }
  }

  get name() {
    return this.formGroup.get("name") as UntypedFormControl;
  }

  checkInUseEmail(control) {
    let db = ["tony@gmail.com"];
    return new Observable((observer) => {
      setTimeout(() => {
        let result =
          db.indexOf(control.value) !== -1 ? { alreadyInUse: true } : null;
        observer.next(result);
        observer.complete();
      }, 4000);
    });
  }

  getErrorEmail() {
    return this.formGroup.get("email").hasError("required")
      ? "Field is required"
      : this.formGroup.get("email").hasError("pattern")
      ? "Not a valid emailaddress"
      : this.formGroup.get("email").hasError("alreadyInUse")
      ? "This emailaddress is already in use"
      : "";
  }

  chooseFiles(files: FileList, origin: string) {
    this.uploadProgress = 0;
    this.errorMsg = "";

    if (files.length === 0 || files.length > environment.limitedFile) {
      this.errorMsg = `El número de archivos excede el limite permitido por cada documento. Max. ${environment.limitedFile} archivos.`;
      return;
    }

    for (let i = 0; i < files.length; i++) {
      if (files[i].size > 30090627) {
        this.errorMsg = `El tamaño del archivo ${files[i].name} excede el tamaño permitido en este sitio.`;
      } else {
        switch (origin) {
          case "DNI":
            this.selectedDNI.push(files[i]);
            break;
          case "BankClaimResponse":
            this.selectedBankClaimResponse.push(files[i]);
            break;
          case "ClaimSheet":
            this.selectedClaimSheet.push(files[i]);
            break;
          case "VigenciaPoder":
            this.selectedVigenciaPoder.push(files[i]);
            break;
          case "DeclaracionAnual":
            this.selectedDeclaracionAnual.push(files[i]);
            break;
          case "SupportingDocuments":
            this.selectedSupportingDocuments.push(files[i]);
            break;
        }
      }
    }
  }

  removeFile(item: any, origin: string) {
    let index: any;

    switch (origin) {
      case "DNI":
        index = this.selectedDNI.findIndex((d) => d.name === item.name);
        this.selectedDNI.splice(index, 1);
        break;
      case "BankClaimResponse":
        index = this.selectedBankClaimResponse.findIndex(
          (d) => d.name === item.name
        );
        this.selectedBankClaimResponse.splice(index, 1);
        break;
      case "ClaimSheet":
        index = this.selectedClaimSheet.findIndex((d) => d.name === item.name);
        this.selectedClaimSheet.splice(index, 1);
        break;
      case "VigenciaPoder":
        index = this.selectedVigenciaPoder.findIndex(
          (d) => d.name === item.name
        );
        this.selectedVigenciaPoder.splice(index, 1);
        break;
      case "DeclaracionAnual":
        index = this.selectedDeclaracionAnual.findIndex(
          (d) => d.name === item.name
        );
        this.selectedDeclaracionAnual.splice(index, 1);
        break;
      case "SupportingDocuments":
        index = this.selectedSupportingDocuments.findIndex(
          (d) => d.name === item.name
        );
        this.selectedSupportingDocuments.splice(index, 1);
        break;
    }
  }

  humanFileSize(bytes: number): string {
    if (Math.abs(bytes) < 1024) {
      return bytes + " B";
    }
    const units = ["kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    let u = -1;
    do {
      bytes /= 1024;
      u++;
    } while (Math.abs(bytes) >= 1024 && u < units.length - 1);
    return bytes.toFixed(1) + " " + units[u];
  }

  showMessage(title: string, message: string, code?: string): void {
    this.dialogo
      .open(DialogInformationComponent, {
        data: { title: title, message: message },
      })
      .afterClosed()
      .subscribe((confirmado: Boolean) => {
        console.log(code);
        if (code) {
          this.router.navigate(["/encuesta-cliente?surveyCode=" + code]);
          return;
          // window.location.href =
          //   window.location.origin + "/encuesta-cliente?surveyCode=" + code;
        }
      });
  }
}
