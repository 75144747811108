import { Injectable } from '@angular/core';

@Injectable()
export class CacheService {
  private host: Node;

  constructor() {
    this.host = document.head;
  }

  saveClaimForm(data: any): void {
    localStorage.setItem('form-claim', JSON.stringify(data));
  }

  saveQueryForm(data: any): void {
    localStorage.setItem('form-query', JSON.stringify(data));
  }

  saveConfigurationInfo(data: any): void {
    localStorage.setItem('ConfigurationInfo', JSON.stringify(data));
  }

  loadClaimForm(): any {
    return localStorage.getItem('form-claim');
  }

  loadQueryForm(): any {
    return localStorage.getItem('form-query');
  }

  loadConfigurationInfo(): any {
    return localStorage.getItem('ConfigurationInfo');
  }

  clearClaimForm(): void {
    localStorage.removeItem('form-claim');
  }

  clearQueryForm(): void {
    localStorage.removeItem('form-query');
  }
}