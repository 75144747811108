<footer class="footer-area {{ location == '/' ? '' : 'uk-dark' }} uk-footer">
  <div class="uk-container">
    <div
      class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-4@m uk-child-width-1-2@s"
    >
      <div class="item">
        <div class="single-footer-widget">
          <h3><del>Tu reclamo</del></h3>
          <h2>Nuestro compromiso</h2>
        </div>
      </div>

      <div class="item">
        <div class="single-footer-widget">
          <h5>Horario de oficina</h5>
          <div class="bar"></div>

          <div class="location">
            <p>Lunes a Viernes de <br />9:00 a.m. a 6:00 p.m.</p>
            <p>Puedes ingresar tu reclamo virtual las 24 horas del día.</p>
          </div>
        </div>
      </div>

      <div class="item">
        <div class="single-footer-widget">
          <h5>Ubícanos</h5>
          <div class="bar"></div>

          <div class="location">
            <p>Av. San Borja Norte Nro. 523 <br />San Borja<br />Lima</p>
            <a routerLink="/contacto">
              <i uk-icon="icon:location; ratio: 1"></i> Ver mapa
            </a>
          </div>
        </div>
      </div>

      <div class="item">
        <div class="single-footer-widget">
          <h5>Llámanos</h5>
          <div class="bar"></div>

          <ul class="contact-info">
            <li>
              Línea gratuita: <a href="tel:080016777"><b>0800-16777</b></a>
            </li>
            <li>
              Central Telefónica:
              <a href="tel:+5112241457"><b>(01) 224-1457</b></a>
            </li>
            <li>
              Horario de atención telefónica: <br />
              Lunes a Viernes de 9:00 am a 6:00 pm <br />
              Sábados de 9:00 am a 3:00 pm
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="copyright-area">
      <div
        class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-2@m uk-child-width-1-2@s"
      >
        <div class="item"></div>

        <div class="item">
          <ul>
            <li>© {{ currentYear }} Defensoría del Cliente Financiero</li>
            <li>
              <div class="logo">
                <a routerLink="/">
                  <img src="assets/img/logo-footer.png" alt="logo" />
                </a>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>

  <div class="go-top">
    <i uk-icon="chevron-up"></i>
    <i uk-icon="chevron-up"></i>
  </div>
</footer>
