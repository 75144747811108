<section id="claim" class="claim-area uk-claim uk-section uk-section-area">
  <div class="uk-container">
    <div class="uk-section-title section-title uk-align-center main-title">
      <h2>Encuesta de Satisfacción del Cliente</h2>
    </div>
  </div>
</section>
<section class="uk-survey survey-area uk-section">
  <div class="uk-container">
    <div *ngIf="step === 0">
      <div class="form-group">
        <div class="title text-center">
          ¡Queremos seguir mejorando para ti !
        </div>
        <div class="question">
          Considerando tu experiencia en la atención de tu reciente reclamo, te
          invitamos a responder esta breve encuesta (Sólo te tomará unos
          minutos)
        </div>
      </div>
      <div class="buttons">
        <button (click)="startSurvey()">Contestar Encuesta</button>
      </div>
    </div>
    <div class="relative bg-encuesta" [ngClass]="{ hidden: step === 0 }">
      <form (ngSubmit)="submit()" [formGroup]="multistep">
        <ng-container *ngIf="isValidQuestionType(['score', 'text'])">
          <div class="relative">
            <div
              class="form-group"
              *ngFor="let question of dataQuestions; let index = index"
            >
              <ng-container *ngIf="question.type !== 'text'">
                <div class="title">
                  Considerando tu experiencia en toda la gestión realizada por
                  la Defensoría del Cliente Financiero en su reclamo.
                </div>
                <div class="question">
                  <span class="question__number">1.</span>
                  <span [innerHTML]="question.htmlText"></span>
                </div>
                <div class="scale">
                  <img src="assets/img/icon_face_red.svg" alt="" />
                  <div
                    *ngFor="let number of numbers"
                    (click)="getValues('question' + question.page, number)"
                    [class]="'question' + question.page"
                    [ngClass]="{
                      scale__numbers: true,
                      active:
                        number.toString() ===
                        selectedValues['question' + question.page]
                    }"
                  >
                    {{ number }}
                  </div>
                  <img src="assets/img/icon_face_green.svg" alt="" />
                </div>
                <span
                  class="error-message-options"
                  [ngClass]="{
                    hidden:
                      !formSubmitted ||
                      multistep.get('userDetails.question1').value !== ''
                  }"
                  *ngIf="
                    multistep.get('userDetails.question1').invalid &&
                    !multistep.get('userDetails.question1').touched
                  "
                  >Seleccionar una opción</span
                >
              </ng-container>
            </div>
            <div class="form-group mt-20" *ngIf="step === 1">
              <div class="question">
                <ng-container
                  *ngFor="let question of dataQuestions; let index = index"
                >
                  <span
                    *ngIf="
                      firstQuestionAnswered &&
                      ((question.order === 1 &&
                        question.type === 'text' &&
                        isQuestionInRange(0, 2)) ||
                        (question.order === 2 &&
                          question.type === 'text' &&
                          isQuestionInRange(3, 7)) ||
                        (question.order === 3 &&
                          question.type === 'text' &&
                          isQuestionInRange(8, 10)))
                    "
                    [innerHTML]="question.htmlText"
                  ></span>
                </ng-container>
              </div>
            </div>
          </div>
          <div class="form-group">
            <div class="answer" *ngIf="firstQuestionAnswered && step === 1">
              <input
                type="text"
                placeholder="Escribe tu respuesta aquí"
                id="question2"
                formControlName="question2"
                [value]="multistep.get('userDetails.question2').value"
                (input)="onInput('question2', $event)"
              />
              <span
                class="error-message"
                [ngClass]="{
                  hidden:
                    !formSubmitted ||
                    multistep.get('userDetails.question2').value !== ''
                }"
                *ngIf="
                  multistep.get('userDetails.question2').invalid &&
                  !multistep.get('userDetails.question2').touched
                "
                >Este campo es requerido</span
              >
            </div>
          </div>
        </ng-container>

        <ng-container
          *ngIf="isValidQuestionType(['multiscore-parent', 'multiscore-child'])"
        >
          <div class="form-group">
            <div class="title">
              Considerando tu experiencia en toda la gestión realizada por la
              Defensoría del Cliente Financiero en su reclamo.
            </div>
          </div>
          <div
            class="form-group"
            *ngFor="let question of dataQuestions; let index = index"
          >
            <div class="question" *ngIf="question.type === 'multiscore-parent'">
              <span class="question__number">{{ index + 1 }}.</span>
              <span [innerHTML]="question.htmlText"></span>
            </div>

            <div class="answer" *ngIf="question.type === 'multiscore-child'">
              <div class="radio" *ngIf="!isMobile">
                <table>
                  <thead>
                    <tr>
                      <th class="radio__title_text"></th>
                      <th
                        class="radio__title_number"
                        *ngFor="let number of [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]"
                      >
                        {{ number }}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr [class]="'row' + index">
                      <td class="radio__options_text">
                        {{ question.htmlText }}
                      </td>
                      <td
                        class="radio__options_check"
                        *ngFor="let number of [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]"
                      >
                        <input
                          type="radio"
                          [id]="'test' + (index * 10 + number)"
                          [value]="number"
                          [name]="'question' + (index + 3)"
                          [checked]="
                            multistep.get('userDetails.question' + (index + 3))
                              .value === number.toString()
                          "
                          (change)="validateRow(index)"
                        />
                        <label [for]="'test' + (index * 10 + number)"></label>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="radio" *ngIf="isMobile">
                <table>
                  <thead>
                    <tr>
                      <th colspan="10">{{ question.htmlText }}</th>
                    </tr>
                    <tr>
                      <th
                        class="radio__title_number"
                        *ngFor="let number of [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]"
                      >
                        {{ number }}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr [class]="'row' + index">
                      <td
                        class="radio__options_check"
                        *ngFor="let number of [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]"
                      >
                        <input
                          [class]="index"
                          type="radio"
                          [id]="'test' + (index * 10 + number)"
                          [value]="number"
                          [name]="'question' + (index + 4)"
                          [checked]="
                            multistep.get('userDetails.question' + (index + 4))
                              .value === number.toString()
                          "
                          (change)="validateRow(index)"
                        />
                        <label [for]="'test' + (index * 10 + number)"></label>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </ng-container>

        <ng-container *ngIf="isValidQuestionType(['text']) && this.step === 4">
          <div
            class="form-group"
            *ngFor="let question of dataQuestions; let index = index"
          >
            <div class="title">
              Considerando tu experiencia en toda la gestión realizada por la
              Defensoría del Cliente Financiero en su reclamo.
            </div>
            <div class="question">
              <span class="question__number">4.</span>
              <span [innerHTML]="question.htmlText"></span>
            </div>
            <div class="answer">
              <input
                type="text"
                placeholder="Escribe tu respuesta aquí"
                [id]="'question' + question.id"
                [formControlName]="'question' + question.id"
                [value]="multistep.get('userDetails.question2').value"
                [value]="
                  multistep.get('userDetails.' + 'question' + question.id).value
                "
                (input)="onInput('question' + question.id, $event)"
              />
              <span
                class="error-message"
                [ngClass]="{
                  hidden:
                    !formSubmitted ||
                    multistep.get('userDetails.' + 'question' + question.id)
                      .value !== ''
                }"
                *ngIf="validQuestion4"
                >Este campo es requerido</span
              >
            </div>
          </div>
        </ng-container>

        <div class="steps" *ngIf="step > 1 && step < 5">
          {{ step }}/{{ lastStep }}
        </div>
        <div class="buttons">
          <a
            [ngClass]="{ disabled: step == 1 }"
            *ngIf="step > 1 && step < 5"
            (click)="previous()"
            >Anterior</a
          >
          <button
            type="submit"
            *ngIf="step < lastStep && step >= 1 && step < 5"
            (click)="next()"
          >
            Siguiente
          </button>
          <button
            type="submit"
            *ngIf="step == lastStep"
            (click)="closeSurvey()"
          >
            Siguiente
          </button>
        </div>
      </form>
      <div *ngIf="step === lastStep + 1">
        <div class="form-group">
          <div class="title text-center">
            Gracias por tu tiempo y colaboración con esta encuesta.
          </div>
        </div>
        <div class="buttons">
          <button (click)="redirectToHome()">Cerrar</button>
        </div>
      </div>
    </div>
  </div>
</section>
