<h1 mat-dialog-title>{{data.title}}</h1>
<div mat-dialog-content>
  <p>{{data.message}}</p>
</div>
<div mat-dialog-actions
     class="mat-dialog-actions">
  <!-- <button mat-button
          class="uk-button uk-button-primary uk-width-1-4 uk-margin-small-bottom uk-margin"
          (click)="close()">OK</button> -->


  <clr-timeline [clrLayout]="orientation"
                style="width: 50%">
    <clr-timeline-step clrState="{{item.state}}"
                       *ngFor="let item of items">
      <clr-timeline-step-header>{{item.header}}</clr-timeline-step-header>
      <clr-timeline-step-title>{{item.title}}</clr-timeline-step-title>
      <clr-timeline-step-description>{{item.description}}</clr-timeline-step-description>
    </clr-timeline-step>
  </clr-timeline>

</div>
<!-- <ngx-htl-horizontal-timeline [(items)]="items"></ngx-htl-horizontal-timeline> -->
