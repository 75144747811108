import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ClaimsPaths } from './querys.paths';
import { Claims } from '../../models/claims/claims';

@Injectable()
export class QueryService {
  _url: string;

  constructor(private http: HttpClient) {
    this._url = environment.urlApiDCF;
  }

  QuerysRegister(): Observable<Claims.Response> {
    return this.http
      .get(`${this._url}${ClaimsPaths.GetSettingsInfo}`)
      .pipe(map(response => response)) as Observable<Claims.Response>;
  }
}
