import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { CacheService } from "../../../services/cache.service";
import { ConfigurationInfoService } from '../../../services/common/configuration.service';

declare const require: any;

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss'],
})

export class HelpComponent implements OnInit {

  public title: string = "";
  public subTitle: string = "";
  public description: string = "";
  public data: any;
  public filter: any;
  public menulist: any;

  constructor(
    private configurationInfoService: ConfigurationInfoService,

    private cacheService: CacheService
  ) { }

  ngOnInit() {

    this.getData();

    //this.styleService.addStyle('help-theme', require('./help.component.scss'));
  }

  ngOnDestroy() {
    //this.styleService.removeStyle('help-theme');
  }


  filterPage(type: number, array: any[]) {
    return array.filter((arr) => arr.id == type);
  }

  getData() {

    this.configurationInfoService.GetPagesContent().subscribe((response: any) => {

      if (response.ok) {
        const data = this.filterPage(
          Number(3),
          response.data.pages
        );

        this.menulist = data[0].data;

        this.title = data[0].title;

        this.subTitle = data[0].subTitle;

        this.description = data[0].sections[0].title;

        this.data = data[0].sections[0].items;
      }

    });

  }

}
